import classNames from 'classnames'
import { Portal } from 'components/Portal/Portal'
import React, { useRef } from 'react'
import { useClickAway, useLockBodyScroll } from 'react-use'
import styles from './Modal.module.scss'
import { ModalButtonClose } from './ModalButtonClose'

interface ModalProps<T = string> {
  close?: boolean
  isOpen: T
  onClose?(): void
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const Modal = <T extends unknown = boolean>({ close = true, isOpen, onClose, children }: React.PropsWithChildren<ModalProps<T>>) => {
  const modalRef = useRef<HTMLDivElement>(null)

  useLockBodyScroll(!!isOpen)
  useClickAway(modalRef, (ev) => {
    if (!modalRef.current?.contains(ev.target as HTMLElement)) {
      onClose?.()
    }
  })

  return (
    <Portal selector="#modal">
      <div className={classNames(styles.root, { [styles[`root--open`]]: isOpen })}>
        <div className={styles.modal} ref={modalRef}>
          {close && onClose && <ModalButtonClose onClose={onClose} />}
          {children}
        </div>
        <div className={styles.overlay} />
      </div>
    </Portal>
  )
}
