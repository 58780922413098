import { PaymentTypeDeprecated } from '../../model/PaymentType'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { UpdatePaymentReceipt } from '../domain/UpdatePaymentReceipt'

export class UpdatePaymentReceiptHttp implements UpdatePaymentReceipt {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  execute(purchaseSlug: string, file: File, paymentType: PaymentTypeDeprecated): Promise<void> {
    if (paymentType !== PaymentTypeDeprecated.RESERVATION && paymentType !== PaymentTypeDeprecated.INITIAL_DOWNPAYMENT) {
      throw Error('Only reservation and initial downpayment are implemented')
    }
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/purchases/${purchaseSlug}/${
      paymentType === PaymentTypeDeprecated.RESERVATION ? 'booking-payment-receipt' : 'entry-payment-receipt'
    }`
    return this.httpClient.postFiles(url, [{ name: 'file', value: file }])
  }
}
