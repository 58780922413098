import { PurchaseBuilder } from '../../builders/PurchaseBuilder'
import { Purchase } from '../../model/Purchase'
import { GetPurchase } from '../domain/GetPurchase'

export class GetPurchaseFake implements GetPurchase {
  private readonly purchase: Purchase

  constructor(purchase = new PurchaseBuilder().build()) {
    this.purchase = purchase
  }

  execute(): Promise<Purchase> {
    return Promise.resolve(this.purchase)
  }
}
