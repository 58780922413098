import { User, AuthRoleType } from '../model/User'

const USER_ACCESS_TOKEN =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJMaWJlZW4iLCJzdWIiOiJmY2ZhYWVlNC1hOGYzLTQ2NTYtODMwYi05NjU3MDIxYzE4YTgiLCJyb2xlIjoxLCJqdGkiOiI4YTEyZmU3Yi0zMDMwLTQ5Y2YtODUyNS0wYzZmMzYzYzFmZjgiLCJpYXQiOjE2NTUyMzE1MzAsImV4cCI6MTY1NzgyMzUzMH0.NEfYNbxG0AIErCxzLiEz8zUdCt9nzWUbymhi9SomlFMN3E38O7EvL4Z_u3A-m0qXO4-ro0wYSmxi7cFwnRU0yMVEAnbH7UkQlhHkwzQSjMfCmV30AxVFtER7Mxa07Hp8aFZuYKa6Y9I_S0tiY5dKGTLdd8iT6KI3P6WqSCvhAWpSEiv0CH_VeZlKhBqT727GcHyVcUqbfbByjkjz-QTQlA0QBm6HBUrV8iaiqvVFSawTr8wWD3yIYHhVqsMdzcKrsQ60YXJ90RbUo5jc_n6fypxUm10ld0vdvvnQj61SJESngrkVfVl-xDs_8414fnLCtv3BvyAa9ZyEMgIpOCtNHS52lfFDGQpaUMfhXkokOQlxJGz-uXbJnxTbjh9AX4JZWJ4TqrkQGPyczFNcBrDDot7cD4XYoAwaPy7DYAi68cNKn5za1nyHopAUSuoVHS4G8TczaetqK6Cct6yzz2LmXN8W-ynSWr_AtgQOaI16956EgEe5dET1h1jtRalsXQxQOI-kryiGCXIo6gDoLnk4ippM9ILk1-mica8MmjfmOCEjFsgU3QQ57a2eNHUS9vzwUAHF_sYv0eTtpGT28wv3D7xAVqpmAMNN5DDXWBq3D4o-gN4SjsJMkv8LrID6N0OjOYuV5shGOoJfxw8An05ugr5aCnifD6_kxf0ur3Y1jbY'

export class UserBuilder {
  private userAttributes: {
    favorites: any[]
    phone: string
    surname: string
    name: string
    slug: string
    email: string
    purchaseSlug: string | null
  }

  constructor() {
    this.userAttributes = {
      slug: 'user-slug',
      name: 'daniel',
      surname: 'sanchez',
      email: 'daniel@mail.com',
      phone: '9545455454',
      favorites: [],
      purchaseSlug: null,
    }
  }

  build(): User {
    return {
      email: this.userAttributes.email,
      favorites: this.userAttributes.favorites,
      role: AuthRoleType.USER,
      name: this.userAttributes.name,
      phone: this.userAttributes.phone,
      slug: this.userAttributes.slug,
      surname: this.userAttributes.surname,
      purchaseSlug: this.userAttributes.purchaseSlug,
    }
  }

  withPurchase(slug: string | null) {
    this.userAttributes.purchaseSlug = slug
    return this
  }

  withName(name: string) {
    this.userAttributes.name = name
    return this
  }

  static generateUserAccessToken(): string {
    return USER_ACCESS_TOKEN
  }
}
