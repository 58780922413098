export enum Vendor {
  LIBEEN = '593e7a5e-8ec4-11ea-bc55-0242ac130003',
  IDEALISTA = 'a0235d01-a79e-44fc-8eac-ab898a8a64f3',
  FOTOCASA = '649f212f-8f13-41a5-8448-204c24cc006b',
}

export const vendorUuidToVendorName: { [key in Vendor]: string } = {
  [Vendor.LIBEEN]: 'Libeen',
  [Vendor.IDEALISTA]: 'Idealista',
  [Vendor.FOTOCASA]: 'Fotocasa',
}
