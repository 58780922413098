import { DealflowStatus, DealflowStatusToUrl } from 'model/DealflowStatus'
import { useMutation, UseMutationOptions } from 'react-query'
import { ApiClient } from 'services/ApiClient'
import { FetchOptions } from 'utils/createApiClient'

type SetDealflowStatusParams = {
  dealflowId: string
  nextStatus: DealflowStatus
}

const getNextDealflowStatusUrl = (nextStatus: DealflowStatus) => {
  return DealflowStatusToUrl[nextStatus]
}

export const setDealflowStatusHttp = (dealflowId: string, nextStatus: DealflowStatus, options?: FetchOptions): Promise<void> => {
  return ApiClient.post<void>(`/admin/dealflows/${dealflowId}/${getNextDealflowStatusUrl(nextStatus)}`, {
    ...options,
  })
}

export const useSetDealflowStatus = (options?: Omit<UseMutationOptions<void, unknown, SetDealflowStatusParams, unknown>, 'mutationFn'>) => {
  return useMutation(
    async (params: SetDealflowStatusParams) => {
      return setDealflowStatusHttp(params.dealflowId, params.nextStatus)
    },
    {
      ...options,
    }
  )
}
