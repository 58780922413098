import { FC, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

interface PortalProps {
  selector: string
  children: React.ReactNode
}

export const Portal: FC<PortalProps> = ({ children, selector }) => {
  const [elem, setElem] = useState<Element | null>(null)

  useEffect(() => {
    const elem = document.querySelector(selector)
    if (!elem) {
      return
    }
    setElem(elem)
  }, [selector])

  if (!elem) {
    return null
  }
  return createPortal(children, elem)
}
