import { HttpClient } from '../../utils/http-client/HttpClient'
import { GetAdminProperty } from '../domain/GetAdminProperty'
import { BackofficePropertyDetail } from '../../model/Property'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { AdminPropertyDetailResponseDto } from './swagger/schema'
import { mapBackofficePropertyDetailResponseToModel } from './maps/backofficePropertyDetailResponseMap'

export class GetAdminPropertyHttp implements GetAdminProperty {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(slug: string): Promise<BackofficePropertyDetail> {
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/admin/properties/${slug}`
    const options = { headers: {}, params: {} }

    const response = await this.httpClient.get<AdminPropertyDetailResponseDto>(url, options)

    return mapBackofficePropertyDetailResponseToModel(response)
  }
}
