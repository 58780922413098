import { ApiRoutes } from 'services/router/ApiRoutes'
import { User, AuthRoleType } from '../../model/User'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { GetUser } from '../domain/GetUser'
import { UserResponseDto } from './swagger/schema'

export class GetUserHttp implements GetUser {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  mapResponseToModel(response: UserResponseDto): User {
    // TODO: Sort by created at when it's available from the API
    return {
      email: response.email,
      favorites: [], // TODO: favorite response when api is implemented
      name: response.name,
      phone: response.phone ?? null,
      role: AuthRoleType.USER,
      slug: response.id,
      surname: response.surname ?? null,
      purchaseSlug: null,
    }
  }

  async execute(): Promise<User> {
    const response = await this.httpClient.get<UserResponseDto>(`${getPublicRuntimeConfig().API_ENDPOINT}${ApiRoutes.me()}`)

    return this.mapResponseToModel(response)
  }
}
