import React, { FC, useState } from 'react'
import { AdminPropertyDetailResponseDto, DealflowAdminShowWithPropertyResponseDto, DealflowUpdateDto } from 'repositories/http/swagger/schema'
import * as Yup from 'yup'
import { Text } from 'components/Text/Text'

import { Checkbox } from 'components/Inputs/Checkbox/Checkbox'
import { DealflowStatus, DealflowStatusEnum, isDealflowStatusCompleted } from 'model/DealflowStatus'
import { useSetDealflowStatus } from 'api/mutations/setDealflowStatus'
import { FileArea } from 'components/Inputs/FileInput/FileArea/FileArea'
import { FileType, StatusFileArea } from 'components/Inputs/FileInput/components/FileDropzone/FileDropzone'
import { useFormik } from 'formik'
import { useUploadDealflowDocument } from 'api/mutations/uploadDealflowDocument'
import { InputCurrency } from 'components/InputCurrency/InputCurrency'
import { formatterCurrencyNoDecimals } from 'utils/number'
import { Slider } from 'components/DataDisplay/Slider/Slider'
import { Banner } from 'components/DataDisplay/Banner/Banner'
import { Icon, IconColor, IconName, IconType } from 'components/DataDisplay/Icon/Icon'
import { PropertyStatus } from 'model/PropertyStatus'
import { getDocumentUrl } from 'utils/dealflowDetailUtils'
import { useUpdateDealflow } from 'api/mutations/useUpdateDealflow'
import { Link } from 'components/Link/Link'
import styles from './DealflowOfferStep.module.scss'
import { FileContainer } from '../FileContainer/FileContainer'
import { useToast } from '../../../../context/ToastContext'

type DealflowOfferStepProps = {
  purchase: DealflowAdminShowWithPropertyResponseDto
  property: AdminPropertyDetailResponseDto
  onRefetch: () => void
}

export const DealflowOfferStep: FC<DealflowOfferStepProps> = ({ purchase, property, onRefetch }) => {
  const { setToast } = useToast()

  const [documentStatus, setDocumentStatus] = useState<StatusFileArea>(StatusFileArea.EMPTY)
  const lastDocumentOfType = purchase.documents.filter((document) => document.type === 'financialCheck').pop()
  const [isFileAreaVisible, setIsFileAreaVisible] = useState<boolean>(!lastDocumentOfType)

  const [currentSlide, setCurrentSlide] = useState(1)
  const totalPhotos = property.gallery.length
  const SLIDER_OPTIONS = {
    className: styles.mainImageContainer,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    dots: false,
    variableWidth: false,
    adaptiveHeight: true,
    afterChange: (current: number) => setCurrentSlide(current + 1),
  }

  const { mutate: uploadFinancialCheckDocument } = useUploadDealflowDocument()
  const { mutate: setDealflowStatus, isLoading: isLoadingSet } = useSetDealflowStatus()
  const { mutate: updateDealflow, isLoading: isLoadingUpdate } = useUpdateDealflow()

  const onDealflowUpdate = async (data: DealflowUpdateDto) => {
    updateDealflow(
      { dealflowId: purchase.id, data },
      {
        onError: () => {
          setToast({
            message: 'There was an error while updating the Dealflow.',
            tone: 'error',
            duration: 3000,
          })
        },
      }
    )
  }

  const onDocumentUpdate = async (data: { document: File | null }) => {
    if (!data?.document) return
    await uploadFinancialCheckDocument(
      { type: 'financialCheck', dealflowId: purchase.id, files: data.document },
      {
        onSuccess: () => {
          setToast({
            message: 'File uploaded successfully.',
            tone: 'success',
            duration: 3000,
          })
          onRefetch()
        },
        onError: () => {
          setToast({
            message: 'There was an error while uploadibg the file.',
            tone: 'error',
            duration: 3000,
          })
        },
      }
    )
  }

  const handleCheckboxClick = (nextStatus: DealflowStatus) => {
    setDealflowStatus(
      { dealflowId: purchase.id, nextStatus },
      {
        onSuccess: () => {
          setToast({
            message: 'Dealflow status updated successfully.',
            tone: 'success',
            duration: 3000,
          })
          onRefetch()
        },
        onError: () => {
          setToast({
            message: 'There was an error while updating the Dealflow',
            tone: 'error',
            duration: 3000,
          })
        },
      }
    )
  }

  const financialCheckFormSchema = () => {
    Yup.object().shape({
      document: Yup.mixed()
        .required('File is required')
        .test('fileType', 'Invalid file type', (value) => (value && value.type === FileType.PDF) || value.type === FileType.IMAGE),
    })
  }

  const contractTermsFormSchema = () => {
    Yup.object().shape({
      finalPurchaseAmount: Yup.number().required().moreThan(0),
      finalSmarthouserAmount: Yup.number().required().moreThan(0),
      totalEntryPayment: Yup.number().required().moreThan(0),
      monthlyRent: Yup.number().required().moreThan(0),
      monthlySavings: Yup.number().required().moreThan(0),
      returnRate: Yup.number().required().moreThan(0),
    })
  }

  const financialCheckForm = useFormik({
    initialValues: {
      document: null as unknown as File | null,
    },
    onSubmit: async (values) => {
      await onDocumentUpdate(values)
    },
    validationSchema: financialCheckFormSchema(),
  })

  const contractsTermsForm = useFormik({
    initialValues: { ...purchase.contractTerms } as DealflowUpdateDto,
    onSubmit: async (data) => {
      await onDealflowUpdate(data)
    },
    validationSchema: contractTermsFormSchema(),
  })

  const isFinancialCheckCompleted = isDealflowStatusCompleted(purchase.status, DealflowStatusEnum.FINANCIAL_CHECK)
  const isFinancialCheckboxDisabled = purchase.status !== DealflowStatusEnum.FINANCIAL_CHECK || !lastDocumentOfType || isLoadingSet
  const [dataConfirmation, setDataConfirmation] = useState<boolean>(isFinancialCheckCompleted)

  const isMakingOfferCompleted = isDealflowStatusCompleted(purchase.status, DealflowStatusEnum.MAKING_OFFER_TO_OWNER)
  const isOfferCheckboxDisabled = purchase.status !== DealflowStatusEnum.MAKING_OFFER_TO_OWNER || !contractsTermsForm.isValid || isLoadingSet
  const [dataConfirmation2, setDataConfirmation2] = useState<boolean>(isMakingOfferCompleted)

  const [isFinalOfferCheckboxChecked, setIsFinalOfferCheckboxChecked] = useState<boolean>(false)
  const isFinalOfferCheckboxDisabled =
    !contractsTermsForm.values.finalPurchaseAmount || dataConfirmation2 || isLoadingUpdate || isFinalOfferCheckboxChecked

  const handleFinalOfferCheckboxClick = () => {
    contractsTermsForm.submitForm()
    setIsFinalOfferCheckboxChecked(true)
  }

  return (
    <div className={styles.container}>
      <Text weight="bold" type="headline">
        Financial Check
      </Text>
      <div className={styles.financialCheckInput}>
        <Text type="body">Approved Financial Check</Text>

        {isFileAreaVisible && (
          <FileArea
            fileTypes={[FileType.PDF, FileType.IMAGE]}
            status={documentStatus}
            name="document"
            onChange={(files) => {
              setIsFileAreaVisible(false)
              setDocumentStatus(StatusFileArea.ACCEPTED)
              financialCheckForm.setFieldValue('document', files[0])
              financialCheckForm.submitForm()
            }}
            errorMessage={(financialCheckForm.touched.document && (financialCheckForm.errors.document as string)) || ''}
          />
        )}

        {!isFileAreaVisible && (
          <FileContainer
            onReplaceFile={() => {
              setIsFileAreaVisible(true)
              financialCheckForm.setFieldValue('document', undefined)
              setDocumentStatus(StatusFileArea.EMPTY)
            }}
            fileUrl={getDocumentUrl(purchase.id, lastDocumentOfType?.document.id || '') || ''}
          />
        )}

        <Checkbox
          onChange={async () => {
            if (dataConfirmation) return
            handleCheckboxClick(DealflowStatusEnum.MAKING_OFFER_TO_OWNER)
            setDataConfirmation(true)
          }}
          className={styles.financialCheckCheckbox}
          checked={dataConfirmation}
          disabled={isFinancialCheckboxDisabled}
          label="The client's financial check is adequate."
          value="dataConfirmation"
          name="dataConfirmation"
          weight="bold"
          size="small"
        />
      </div>
      <hr className={styles.divider} />
      <Text type="headline" weight="bold" className={styles.ownerDataTitle}>
        Property data
      </Text>
      <div className={styles.propertyDataContainer}>
        <div className={styles.card}>
          <div className={styles.cardClickable}>
            <div>
              <Banner type={PropertyStatus.RESERVED} className={styles.banner} specialOffer={property.specialOffer} />
              <div className={styles.sliderContainer}>
                <div className={styles.sliderContainerPaginator}>
                  <Icon name={IconName.CAMERA_2} type={IconType.LINE} color={IconColor.WHITE} size={22} />
                  {`${currentSlide}/${totalPhotos}`}
                </div>
                <Slider
                  options={SLIDER_OPTIONS}
                  sliderSlickSlide100
                  hideFirstAndLastSlidesArrows
                  arrowLeftClassName={styles.sliderButtonPrev}
                  arrowRightClassName={styles.sliderButtonNext}
                >
                  {property.gallery.map((image) => (
                    <img key={image} src={!image ? '/images/image-placeholder.webp' : image} alt={property.title} className={styles.mainImage} />
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.propertyData}>
          <Text color="blue-300">{`${property.city} - ${property.neighborhoodName}`}</Text>
          <Text weight="bold">{property.title}</Text>
          <hr className={styles.divider} />
          <div className={styles.currencyContainer}>
            <Text weight="bold">{formatterCurrencyNoDecimals(property.priceOfCost)}</Text>
            <Text type="small">/purchase amount</Text>
          </div>
          <div className={styles.currencyContainer}>
            <Text weight="bold">{formatterCurrencyNoDecimals(property.pricePerMonth)}</Text>
            <Text type="small">/month</Text>
            <Text weight="bold" color="red-300">
              &nbsp;+&nbsp;
            </Text>
            <Text weight="bold" type="small" color="grey-400">
              Down payment {formatterCurrencyNoDecimals(property.entryPayment)}
            </Text>
          </div>
          <hr className={styles.divider} />
          <Link className={styles.propertyLink} route={`/admin/properties/${purchase.propertySlug}`}>
            View property detail
          </Link>
        </div>
      </div>
      <hr className={styles.divider} />
      <Text type="headline" weight="bold" className={styles.ownerDataTitle}>
        Owner contact information
      </Text>
      <div className={styles.ownerInfomation}>
        <div>
          <Text weight="bold">Owner type</Text>
          <Text>{property.vendorType}</Text>
        </div>

        <div>
          <Text weight="bold">Contact name</Text>
          <Text>{property.ownerName || '-'}</Text>
        </div>

        <div>
          <Text weight="bold">Phone</Text>
          <Text>{property.ownerPhone || '-'}</Text>
        </div>

        <div>
          <Text weight="bold">Email</Text>
          <Text>{property.ownerEmail || '-'}</Text>
        </div>
      </div>
      <hr className={styles.divider} />
      <Text type="headline" weight="bold" className={styles.ownerDataTitle}>
        Offer made to owner
      </Text>
      <div className={styles.ownerOffer}>
        <InputCurrency
          value={contractsTermsForm.values.finalPurchaseAmount}
          onBlur={() => undefined}
          onFocus={() => undefined}
          onChange={(e) => {
            contractsTermsForm.setFieldValue('finalPurchaseAmount', parseInt(e.target.value))
          }}
          min={0}
          max={99999999999}
          type="number"
          name="finalPurchaseAmount"
          label="Final offer purchase amount"
          placeholder="Write final purchase amount"
          disabled={!dataConfirmation || isFinalOfferCheckboxChecked}
        />

        <Checkbox
          onChange={handleFinalOfferCheckboxClick}
          className={styles.financialCheckCheckbox}
          checked={isFinalOfferCheckboxChecked}
          disabled={isFinalOfferCheckboxDisabled}
          label="Offer has been sent to the owner."
          value="offerSent"
          name="offerSent"
          weight="bold"
          size="small"
        />
      </div>
      <hr className={styles.divider} />
      <Text type="headline" weight="bold" className={styles.ownerDataTitle}>
        Approved contract terms
      </Text>

      <div className={styles.contractTerms}>
        <InputCurrency
          value={contractsTermsForm.values.finalPurchaseAmount}
          onBlur={() => contractsTermsForm.submitForm()}
          onFocus={() => undefined}
          onChange={(e) => {
            contractsTermsForm.setFieldValue('finalPurchaseAmount', parseInt(e.target.value))
          }}
          min={0}
          max={99999999999}
          type="number"
          name="finalPurchaseAmount"
          label="Final price of the property"
          placeholder="Write the final price of the property"
          disabled={isFinalOfferCheckboxChecked}
        />

        <InputCurrency
          value={contractsTermsForm.values.finalSmarthouserAmount}
          onBlur={() => contractsTermsForm.submitForm()}
          onFocus={() => undefined}
          onChange={(e) => {
            contractsTermsForm.setFieldValue('finalSmarthouserAmount', parseInt(e.target.value))
          }}
          min={0}
          max={99999999999}
          type="number"
          name="finalSmarthouserAmount"
          label="Final property price for Smarthouser"
          placeholder="Write the final property price for Smarthouser"
          disabled={isOfferCheckboxDisabled}
        />

        <InputCurrency
          value={contractsTermsForm.values.totalEntryPayment}
          onBlur={() => contractsTermsForm.submitForm()}
          onFocus={() => undefined}
          onChange={(e) => {
            contractsTermsForm.setFieldValue('totalEntryPayment', parseInt(e.target.value))
          }}
          min={0}
          max={99999999999}
          type="number"
          name="totalEntryPayment"
          label="Total down payment"
          placeholder="Write the total down payment"
          disabled={isOfferCheckboxDisabled}
        />

        <InputCurrency
          value={contractsTermsForm.values.monthlyRent}
          onBlur={() => contractsTermsForm.submitForm()}
          onFocus={() => undefined}
          onChange={(e) => {
            contractsTermsForm.setFieldValue('monthlyRent', parseInt(e.target.value))
          }}
          min={0}
          max={99999999999}
          type="number"
          name="monthlyRent"
          label="Monthly rent"
          placeholder="Write the monthly rent"
          disabled={isOfferCheckboxDisabled}
        />

        <InputCurrency
          value={contractsTermsForm.values.monthlySavings}
          onBlur={() => contractsTermsForm.submitForm()}
          onFocus={() => undefined}
          onChange={(e) => {
            contractsTermsForm.setFieldValue('monthlySavings', parseInt(e.target.value))
          }}
          min={0}
          max={99999999999}
          type="number"
          name="monthlySavings"
          label="Smarthouser monthly savings"
          placeholder="Write the smarthouser monthly savings"
          disabled={isOfferCheckboxDisabled}
        />

        <InputCurrency
          value={contractsTermsForm.values.returnRate}
          onBlur={() => contractsTermsForm.submitForm()}
          onFocus={() => undefined}
          onChange={(e) => {
            contractsTermsForm.setFieldValue('returnRate', parseInt(e.target.value))
          }}
          min={0}
          max={99999999999}
          type="number"
          name="returnRate"
          label="Rentability (IRR)"
          placeholder="Write the rentability amount"
          disabled={isOfferCheckboxDisabled}
        />
      </div>
      <Checkbox
        onChange={() => {
          if (dataConfirmation2) return
          handleCheckboxClick(DealflowStatusEnum.SIGN_AND_PAY)
          setDataConfirmation2(true)
        }}
        className={styles.financialCheckCheckbox}
        checked={dataConfirmation2}
        disabled={isOfferCheckboxDisabled}
        label="Contract terms has been approved."
        value="dataConfirmation2"
        name="dataConfirmation2"
        weight="bold"
        size="small"
      />
    </div>
  )
}
