import { LocaleKeys } from '../i18n/keys'

export enum PropertyPosition {
  INTERIOR = 'INTERIOR',
  EXTERIOR = 'EXTERIOR',
}

export function translatePropertyPosition(t: LocaleKeys, propertyPosition: PropertyPosition): string {
  const propertyPositionTranslation: { [key in PropertyPosition]: string } = {
    [PropertyPosition.INTERIOR]: t.common.propertyPosition.interior,
    [PropertyPosition.EXTERIOR]: t.common.propertyPosition.exterior,
  }

  return propertyPositionTranslation[propertyPosition]
}
