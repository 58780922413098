import { GetNeighborhoods } from '../domain/GetNeighborhoods'
import { NeighborhoodResponseDto } from './swagger/schema'
import { Neighborhood } from '../../model/Neighborhood'
import { NeighborhoodTag } from '../../model/NeighborhoodTag'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { City } from '../../model/City'

export class GetNeighborhoodsHttp implements GetNeighborhoods {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(city: City): Promise<Neighborhood[]> {
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/neighborhoods`
    const options = { headers: {}, params: { city: city.name } }

    const responseDtos = await this.httpClient.get<NeighborhoodResponseDto[]>(url, options)

    return responseDtos.map((dto) => this.dtoToModel(dto))
  }

  private dtoToModel(dto: NeighborhoodResponseDto): Neighborhood {
    return {
      uuid: dto.uuid,
      description: dto.description,
      image: dto.image,
      name: dto.name,
      tags: dto.tags as NeighborhoodTag[],
    }
  }
}
