import classNames from 'classnames'
import NextLink from 'next/link'
import React, { FC } from 'react'

import styles from './Link.module.scss'

type LinkProps = {
  route: string
  locale?: string
  disabled?: boolean
  useSSRRouting?: boolean
  className?: string
  ariaLabel?: string
  onClick?: () => void
  newTarget?: boolean
}

export const Link: FC<LinkProps> = ({ children, className, route, locale, ariaLabel, disabled, useSSRRouting = true, onClick, newTarget }) => {
  const combinedClassNames = classNames(className, styles.container)

  if (disabled) {
    return (
      <a className={combinedClassNames} aria-label={ariaLabel}>
        {children}
      </a>
    )
  }

  if (!useSSRRouting) {
    return (
      <a
        className={combinedClassNames}
        aria-label={ariaLabel}
        href={route}
        onClick={onClick}
        target={newTarget ? '_blank' : undefined}
        rel="noreferrer"
      >
        {children}
      </a>
    )
  }

  return (
    <NextLink href={route} locale={locale} passHref>
      {/* eslint-disable-next-line */}
      <a className={combinedClassNames} aria-label={ariaLabel} onClick={onClick} target={newTarget ? '_blank' : undefined}>
        {children}
      </a>
    </NextLink>
  )
}
