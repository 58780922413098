import { BackofficePropertyDetail } from '../model/Property'
import { PropertyEnergeticCertification } from '../model/PropertyEnergeticCertification'
import { PropertyFeature } from '../model/PropertyFeature'
import { PropertyOrientation } from '../model/PropertyOrientation'
import { PropertyPosition } from '../model/PropertyPosition'
import { PropertyStatus } from '../model/PropertyStatus'
import { PropertyTag } from '../model/PropertyTag'
import { PropertyType } from '../model/PropertyType'
import { Region } from '../model/Region'
import { Vendor } from '../model/Vendor'
import { VendorType } from '../model/VendorType'

export class BackofficePropertyDetailBuilder {
  private readonly propertyDetail: BackofficePropertyDetail

  static VERTICAL_IMAGE = 'https://i.imgur.com/c4xYvbL.jpg'

  constructor(slug = Math.random().toString()) {
    this.propertyDetail = {
      id: '',
      slug,
      gallery: [BackofficePropertyDetailBuilder.VERTICAL_IMAGE],
      status: PropertyStatus.AVAILABLE,
      title: 'Calle Vallehermoso 48',
      pricePerMonth: 1300,
      downPayment: 15927,
      surface: 59,
      bedroomsAmount: 2,
      bathroomsAmount: 2,
      vendor: Vendor.LIBEEN,
      propertyType: PropertyType.DUPLEX,
      builtSurface: 31,
      utilSurface: 12,
      orientation: PropertyOrientation.EAST,
      propertyPosition: PropertyPosition.INTERIOR,
      energeticCertification: PropertyEnergeticCertification.A,
      description: '',
      propertyTags: [
        PropertyTag.SUNNY,
        PropertyTag.PETS_ALLOWED,
        PropertyTag.SHOPPING_AREA,
        PropertyTag.RESTAURANTS_NEAR,
        PropertyTag.PUBLIC_TRANSPORT,
      ],
      featureTags: [PropertyFeature.AC, PropertyFeature.AREAWAY, PropertyFeature.CENTRAL_HEATING],
      region: Region.MADRID,
      city: 'Madrid',
      neighborhoodUuid: '553e677d-62fa-44f2-811a-9d945d7c71db',
      subNeighborhood: 'Antíon Martín',
      addressText: 'C/ Some address',
      postalCode: 12345,
      latitude: 12.412,
      longitude: 1.323,
      vendorType: VendorType.LIBEEN,
      ownerName: 'Olly',
      ownerPhone: '928 54 35 88',
      ownerEmail: 'email@email.com',
      referenceId: '',
      priceOfCost: 120000,
      priceToPay: 122000,
      entryPayment: 12000,
      communityMonthlyCost: 12,
      IBIYearlyCost: 130,
      adminName: 'Rudolf Admin',
      adminEmail: 'email@email.com',
      adminPhone: '928 54 35 88',
      presidentName: 'George President',
      presidentEmail: 'email@email.com',
      presidentPhone: '928 54 35 88',
      savings: 1000,
      monthlySavings: 600,
      floor: 'Ático 3',
      referenceLink: 'https://link.reference',
      tryBeforeYouBuyIt: true,
      wholeRentalForBuying: true,
      yearPlan: 5,
      specialOffer: '100% a la compra',
    }
  }

  withSlug(slug: string) {
    this.propertyDetail.slug = slug
    return this
  }

  withPropertyTags(tags: PropertyTag[]) {
    this.propertyDetail.propertyTags = tags
    return this
  }

  withDownPayment(amount: number) {
    this.propertyDetail.downPayment = amount
    return this
  }

  withPricePerMonth(amount: number) {
    this.propertyDetail.pricePerMonth = amount
    return this
  }

  build(): BackofficePropertyDetail {
    return this.propertyDetail
  }
}
