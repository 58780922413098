import { HttpClient } from '../../utils/http-client/HttpClient'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { ContinuePurchase } from '../domain/ContinuePurchase'

export class ContinuePurchaseHttp implements ContinuePurchase {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(slug: string): Promise<void> {
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/purchases/${slug}/continue`
    const options = { headers: {}, params: {} }
    await this.httpClient.post(url, {}, options)
  }
}
