import { BackofficeProperty } from '../model/Property'
import { PropertyStatus } from '../model/PropertyStatus'
import { PropertyTag } from '../model/PropertyTag'
import { Vendor } from '../model/Vendor'

export class BackofficePropertyBuilder {
  private readonly property: BackofficeProperty

  static VERTICAL_IMAGE = 'https://i.imgur.com/c4xYvbL.jpg'

  constructor(slug = Math.random().toString()) {
    this.property = {
      id: '',
      slug,
      status: PropertyStatus.AVAILABLE,
      address: {
        city: 'Madrid',
        neighborhood: 'Chamberi',
      },
      title: 'Calle Vallehermoso 48',
      pricePerMonth: 1300,
      downPayment: 15927,
      entryPayment: 15927,
      surface: 59,
      bedroomsAmount: 2,
      bathroomsAmount: 2,
      tags: [PropertyTag.SUNNY, PropertyTag.PETS_ALLOWED, PropertyTag.SHOPPING_AREA, PropertyTag.RESTAURANTS_NEAR, PropertyTag.PUBLIC_TRANSPORT],
      vendor: Vendor.LIBEEN,
      createdAt: '2021-09-08T10:59:44.945Z',
      updatedAt: '2021-09-08T10:59:44.945Z',
      latitude: 40.4167,
      longitude: -3.7033,
    }
  }

  withSlug(slug: string) {
    this.property.slug = slug
    return this
  }

  withTitle(title: string) {
    this.property.title = title
    return this
  }

  withNeighboorhood(neighborhood: string) {
    this.property.address.neighborhood = neighborhood
    return this
  }

  withPricePerMonth(pricePerMonth: number) {
    this.property.pricePerMonth = pricePerMonth
    return this
  }

  withStatus(status: PropertyStatus) {
    this.property.status = status
    return this
  }

  withTags(tags: PropertyTag[]) {
    this.property.tags = tags
    return this
  }

  build(): BackofficeProperty {
    return this.property
  }
}
