export enum PropertyFeature {
  PARKING = 'PARKING',
  AC = 'AC',
  POOL = 'POOL',
  GYM = 'GYM',
  ELEVATOR = 'ELEVATOR',
  JANITOR = 'JANITOR',
  AREAWAY = 'AREAWAY',
  CENTRAL_HEATING = 'CENTRAL_HEATING',
}
