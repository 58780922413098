import { BackofficeProperties, BackofficeProperty } from '../../model/Property'
import { PropertyStatus } from '../../model/PropertyStatus'
import { PropertyTag } from '../../model/PropertyTag'
import { Vendor } from '../../model/Vendor'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { formatToDateAndTime } from '../../utils/formatDate'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { GetAdminProperties } from '../domain/GetAdminProperties'
import { AdminPropertyIndexResponseDto, AdminPropertyResultsDto } from './swagger/schema'

type AdminPropertyResponse = AdminPropertyIndexResponseDto

export class GetAdminPropertiesHttp implements GetAdminProperties {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  private mapPropertyApiToModel(property: AdminPropertyResultsDto): BackofficeProperty {
    return {
      id: property.id,
      slug: property.slug,
      status: property.status as PropertyStatus, // TODO: Check that backend/frontend enums matches,
      address: {
        city: property.address.city || '',
        neighborhood: property.address.neighborhood || '',
      },
      title: property.title || '',
      pricePerMonth: property.pricePerMonth || 0,
      downPayment: property.entryPayment || 0,
      entryPayment: property.entryPayment || 0,
      surface: property.surface || 0,
      bedroomsAmount: property.bedroomsAmount || 0,
      bathroomsAmount: property.bathroomsAmount || 0,
      tags: (property.tags as PropertyTag[]) || ([] as PropertyTag[]), // TODO: Check that backend/frontend enums matches
      vendor: property.vendor as Vendor,
      createdAt: property.createdAt ? formatToDateAndTime(new Date(property.createdAt)) : undefined,
      updatedAt: property.updatedAt ? formatToDateAndTime(new Date(property.updatedAt)) : undefined,
      latitude: 0,
      longitude: 0,
    }
  }

  private mapResponseToModel(response: AdminPropertyResponse): BackofficeProperties {
    const properties = response.properties.map((property) => this.mapPropertyApiToModel(property))
    return { properties, totalCount: response.totalCount }
  }

  async execute(search?: string, paginate?: { offset: number; limit: number }, statusFilter?: PropertyStatus): Promise<BackofficeProperties> {
    const paramsSearch = search ? { search } : {}
    const paramsPaginate = paginate || {}
    const paramsStatus = statusFilter ? { status: statusFilter } : {}

    const response = await this.httpClient.get<AdminPropertyResponse>(`${getPublicRuntimeConfig().API_ENDPOINT}/admin/properties`, {
      headers: {},
      params: {
        ...paramsSearch,
        ...paramsPaginate,
        ...paramsStatus,
      },
    })

    return this.mapResponseToModel(response)
  }
}
