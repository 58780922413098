import React, { FC, useState } from 'react'
import { DealflowAdminShowWithPropertyResponseDto, DealflowUpdateDto } from 'repositories/http/swagger/schema'

import { Text } from 'components/Text/Text'

import { getDocumentUrl } from 'utils/dealflowDetailUtils'

import { DealflowStatusEnum } from 'model/DealflowStatus'
import { useSetDealflowStatus } from 'api/mutations/setDealflowStatus'
import { FileArea } from 'components/Inputs/FileInput/FileArea/FileArea'
import { FileType, StatusFileArea } from 'components/Inputs/FileInput/components/FileDropzone/FileDropzone'
import { FormikProvider, useFormik } from 'formik'
import { useUploadDealflowDocument } from 'api/mutations/uploadDealflowDocument'
import * as Yup from 'yup'
import { useUpdateDealflow } from 'api/mutations/useUpdateDealflow'
import { DateInput } from 'components/Inputs/DateInput/DateInput'
import { Button } from 'components/Button/Button'
import { Select } from 'components/Select/Select'
import { KeyDeliveryPoint } from 'model/KeyDeliveryPoint'
import { Modal } from 'components/Modal/Modal'
import { useToast } from 'context/ToastContext'
import { Icon, IconName, IconType } from 'components/DataDisplay/Icon/Icon'
import { useRouter } from 'next/router'
import { timePickerSelectOptions } from 'utils/time'
import { formatISODateToDateOnly } from 'utils/formatDate'
import { FileContainer } from '../FileContainer/FileContainer'
import styles from './DealflowKeyDeliveryStep.module.scss'

type DealflowKeyDeliveryStepProps = {
  purchase: DealflowAdminShowWithPropertyResponseDto
  onRefetch: () => void
}

const options: { title: string; value: KeyDeliveryPoint }[] = [
  { title: "Libeen's Madrid office", value: 'LIBEEN_OFFICE' as KeyDeliveryPoint },
  { title: 'In the own home', value: 'HOME' as KeyDeliveryPoint },
]

export const DealflowKeyDeliveryStep: FC<DealflowKeyDeliveryStepProps> = ({ purchase, onRefetch }) => {
  const [keyReceiptStatus, setKeyReceiptStatus] = useState<StatusFileArea>(StatusFileArea.EMPTY)
  const lastKeyReceipt = purchase.documents.filter((document) => document.type === 'keyReceipt').pop()
  const [isKeyReceiptFileAreaVisible, setIsKeyReceiptFileAreaVisible] = useState<boolean>(!lastKeyReceipt)
  const { setToast } = useToast()
  const router = useRouter()

  const isKeyDeliveryButtonDisabled =
    purchase.status !== DealflowStatusEnum.KEY_DELIVERY &&
    !lastKeyReceipt &&
    !!purchase.keyDeliveryDate &&
    !!purchase.keyDeliveryPoint &&
    !!purchase.smartHousingEndDate &&
    !!purchase.smartHousingStartDate

  const [isFinishModalOpen, setIsFinishModalOpen] = useState<boolean>(false)

  const { mutate: uploadContractDocument } = useUploadDealflowDocument()
  const { mutate: updateDealflow } = useUpdateDealflow()
  const { mutate: setDealflowStatus } = useSetDealflowStatus()

  const onDealflowUpdate = async (data: DealflowUpdateDto) => {
    updateDealflow(
      { dealflowId: purchase.id, data },
      {
        onSuccess: () => {
          setToast({
            message: 'Dealflow updated successfully.',
            tone: 'success',
            duration: 3000,
          })
          onRefetch()
        },
        onError: () => {
          setToast({
            message: 'There was an error while updating the Dealflow.',
            tone: 'error',
            duration: 3000,
          })
        },
      }
    )
  }

  const onDocumentUpdate = async (data: { document: File | null }) => {
    if (!data?.document) return
    uploadContractDocument(
      { type: 'keyReceipt', dealflowId: purchase.id, files: data.document },
      {
        onSuccess: () => {
          setToast({
            message: 'File uploaded successfully.',
            tone: 'success',
            duration: 3000,
          })
          onRefetch()
        },
        onError: () => {
          setToast({
            message: 'There was an error while uploadibg the file.',
            tone: 'error',
            duration: 3000,
          })
        },
      }
    )
  }

  const handleCheckboxClick = () => {
    setDealflowStatus(
      { dealflowId: purchase.id, nextStatus: DealflowStatusEnum.NEW_SMARTHOUSER },
      {
        onSuccess: () => {
          setToast({
            message: 'Dealflow status updated successfully.',
            tone: 'success',
            duration: 3000,
          })
          onRefetch()
        },
        onError: () => {
          setToast({
            message: 'There was an error while updating the Dealflow',
            tone: 'error',
            duration: 3000,
          })
        },
      }
    )
  }

  const documentSchema = () => {
    Yup.object().shape({
      document: Yup.mixed()
        .required('File is required')
        .test('fileType', 'Invalid file type', (value) => (value && value.type === FileType.PDF) || value.type === FileType.IMAGE),
    })
  }

  const smarthousingDateFormSchema = () => {
    Yup.object().shape({
      smartHousingStartDate: Yup.string().required(),
      smartHousingEndDate: Yup.string().required(),
    })
  }

  const keyDeliveryDateFormSchema = () => {
    Yup.object().shape({
      date: Yup.date().required(),
      time: Yup.string().required(),
    })
  }

  const keyDeliveryPointFormSchema = () => {
    Yup.object().shape({
      keyDeliveryPoint: Yup.string().required(),
    })
  }

  const keyReceiptDocumentForm = useFormik({
    initialValues: {
      document: null as unknown as File | null,
    },
    onSubmit: async (values) => {
      await onDocumentUpdate(values)
    },
    validationSchema: documentSchema(),
  })

  const smartHousingDateForm = useFormik({
    initialValues: {
      smartHousingStartDate: purchase.smartHousingStartDate,
      smartHousingEndDate: purchase.smartHousingEndDate,
    },
    onSubmit: async (values) => {
      if (!values.smartHousingStartDate || !values.smartHousingEndDate) return
      const timezoneOffset = new Date().getTimezoneOffset() * 60000
      const smartHousingStartDate = new Date(new Date(values.smartHousingStartDate).getTime() - timezoneOffset).toISOString()
      const smartHousingEndDate = new Date(new Date(values.smartHousingEndDate).getTime() - timezoneOffset).toISOString()

      await onDealflowUpdate({ smartHousingStartDate, smartHousingEndDate })
    },
    validationSchema: smarthousingDateFormSchema(),
  })

  const keyDeliveryDateForm = useFormik({
    initialValues: {
      date: purchase.keyDeliveryDate ? purchase.keyDeliveryDate.split('T')[0] : formatISODateToDateOnly(new Date()),
      time: purchase.keyDeliveryDate?.split('T')[1].substring(0, 5) || '',
    },
    onSubmit: async ({ date, time }) => {
      if (!date || !time) return
      const fullDate = new Date(`${date}T${time}`)
      const timezoneOffset = fullDate.getTimezoneOffset() * 60000
      const keyDeliveryDate = new Date(fullDate.getTime() - timezoneOffset).toISOString()
      await onDealflowUpdate({ keyDeliveryDate })
    },
    validationSchema: keyDeliveryDateFormSchema(),
  })

  const keyDeliveryPointForm = useFormik({
    initialValues: {
      keyDeliveryPoint: purchase.keyDeliveryPoint,
    },
    onSubmit: async ({ keyDeliveryPoint }) => {
      if (!keyDeliveryPoint) return
      await onDealflowUpdate({ keyDeliveryPoint })
    },
    validationSchema: keyDeliveryPointFormSchema(),
  })

  return (
    <div className={styles.container}>
      <Text weight="bold" type="headline">
        Start and end of rental period
      </Text>
      <div className={styles.smartHousingDateContainer}>
        <FormikProvider value={smartHousingDateForm}>
          <DateInput
            {...smartHousingDateForm.getFieldProps('smartHousingStartDate')}
            className={styles.datesInput}
            value={smartHousingDateForm.values.smartHousingStartDate ? new Date(smartHousingDateForm.values.smartHousingStartDate) : undefined}
            label="Start of Smarthousing"
            onChange={(date) => smartHousingDateForm.setFieldValue('smartHousingStartDate', formatISODateToDateOnly(date))}
            onBlur={() => smartHousingDateForm.submitForm()}
          />
          <DateInput
            {...smartHousingDateForm.getFieldProps('smartHousingEndDate')}
            className={styles.datesInput}
            value={smartHousingDateForm.values.smartHousingEndDate ? new Date(smartHousingDateForm.values.smartHousingEndDate) : undefined}
            label="End of Smarthousing"
            onChange={(date) => smartHousingDateForm.setFieldValue('smartHousingEndDate', formatISODateToDateOnly(date))}
            onBlur={() => smartHousingDateForm.submitForm()}
          />
        </FormikProvider>
      </div>
      <hr className={styles.divider} />
      <Text type="headline" weight="bold" className={styles.ownerDataTitle}>
        Date and time
      </Text>
      <div className={styles.deedsSignDateContainer}>
        <FormikProvider value={keyDeliveryDateForm}>
          <DateInput
            {...keyDeliveryDateForm.getFieldProps('date')}
            value={keyDeliveryDateForm.values.date ? new Date(keyDeliveryDateForm.values.date) : undefined}
            label="Date"
            className={styles.dateInput}
            onChange={(date) => keyDeliveryDateForm.setFieldValue('date', formatISODateToDateOnly(date))}
            onBlur={() => keyDeliveryDateForm.submitForm()}
          />
          <input style={{ display: 'none' }} type="text" name="time" value={keyDeliveryDateForm.values.time} />

          <Select
            id="time"
            placeholder="HH:MM"
            label="Time"
            selectClass={styles.timeInput}
            options={timePickerSelectOptions}
            disabled={!keyDeliveryDateForm.values.date}
            value={keyDeliveryDateForm.values.time}
            onChange={(e) => {
              keyDeliveryDateForm.setFieldValue('time', e)
              keyDeliveryDateForm.submitForm()
            }}
            customIcon={<Icon name={IconName.TIME} type={IconType.LINE} className={styles.iconButton} />}
          />
        </FormikProvider>
      </div>

      <Text type="headline" weight="bold" className={styles.ownerDataTitle}>
        Key delivery point to the owner
      </Text>

      <div className={styles.deedsSignDateContainer}>
        <Select
          label="Delivery point"
          id="deliveryPointSelect"
          placeholder="Choose the place where you will deliver the keys"
          options={options}
          value={keyDeliveryPointForm.values.keyDeliveryPoint}
          onChange={async (value) => {
            await keyDeliveryPointForm.setFieldValue('keyDeliveryPoint', value)
            await keyDeliveryPointForm.submitForm()
          }}
        />
      </div>

      <hr className={styles.divider} />

      <Text type="headline" weight="bold" className={styles.ownerDataTitle}>
        Confirm the delivery of keys
      </Text>

      <div className={styles.fileInput}>
        <Text type="body">Upload filled document</Text>

        {isKeyReceiptFileAreaVisible && (
          <FileArea
            fileTypes={[FileType.PDF, FileType.IMAGE]}
            status={keyReceiptStatus}
            name="document"
            onChange={(files) => {
              setIsKeyReceiptFileAreaVisible(false)
              setKeyReceiptStatus(StatusFileArea.ACCEPTED)
              keyReceiptDocumentForm.setFieldValue('document', files[0])
              keyReceiptDocumentForm.submitForm()
            }}
            errorMessage={(keyReceiptDocumentForm.touched.document && (keyReceiptDocumentForm.errors.document as string)) || ''}
          />
        )}

        {!isKeyReceiptFileAreaVisible && (
          <FileContainer
            onReplaceFile={() => {
              setIsKeyReceiptFileAreaVisible(true)
              keyReceiptDocumentForm.setFieldValue('document', undefined)
              setKeyReceiptStatus(StatusFileArea.EMPTY)
            }}
            fileUrl={getDocumentUrl(purchase.id, lastKeyReceipt?.document.id || '') || ''}
          />
        )}

        <div className={styles.bottomBar}>
          <Button className={styles.finishButton} variant="primary" size="small" onClick={() => setIsFinishModalOpen(true)}>
            FINISH THE PROCESS
          </Button>
        </div>
      </div>

      <Modal isOpen={isFinishModalOpen} onClose={() => setIsFinishModalOpen(false)}>
        <div className={styles.finishModal}>
          <img src="/images/finish-dealflow-house.svg" role="presentation" alt="" />
          <Text type="h3" weight="bold" className={styles.ownerDataTitle}>
            Have you already delivered the keys?
          </Text>
          <Text type="headline" className={styles.ownerDataTitle}>
            If so, you can finish the process and this user will become a SmartHouser.
          </Text>
          <div className={styles.modalButtonsContainer}>
            <div>
              <Button
                className={styles.finishButton}
                variant="secondary"
                size="small"
                onClick={() => setIsFinishModalOpen(false)}
                width="expand"
              >
                BACK TO DEALFLOW DETAIL
              </Button>
            </div>
            <div>
              <Button
                className={styles.finishButton}
                variant="primary"
                disabled={isKeyDeliveryButtonDisabled}
                size="small"
                onClick={async () => {
                  setIsFinishModalOpen(false)
                  handleCheckboxClick()
                  router.replace(`/admin/smarthousers/${purchase.smarthouserId}`)
                }}
                width="expand"
              >
                FINISH PROCESS
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
