import classNames from 'classnames'
import { Icon, IconColor, IconName } from 'components/DataDisplay/Icon/Icon'
import Link from 'next/link'
import { ButtonHTMLAttributes, FC } from 'react'
import styles from './Button.module.scss'

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary'
export type ButtonSize = 'small' | 'large'
export type ButtonColor = 'red' | 'blue' | 'white' | 'grey'
export type ButtonStatus = 'disabled' | 'active' | 'hover'
export type ButtonWidth = 'expand' | 'fit' | 'auto' | number

const getIconColor = (variant: ButtonVariant, status: ButtonStatus = 'active'): IconColor => {
  switch (variant) {
    case 'primary':
      return IconColor.WHITE
    case 'secondary':
      return status === 'disabled' ? IconColor.GREY_LIGHT : IconColor.BLUE
    case 'tertiary':
      return status === 'disabled' ? IconColor.GREY_LIGHT : IconColor.BLUE
    default:
      return IconColor.WHITE
  }
}

const getDefaultColor = (variant: ButtonVariant, color: ButtonColor | undefined): ButtonColor => {
  // has color?
  if (color) {
    return color
  }
  // if not has color then get default based in variant
  switch (variant) {
    case 'primary':
      return 'blue'
    case 'secondary':
      return 'blue'
    case 'tertiary':
      return 'grey'
    default:
      return 'blue'
  }
}

interface ButtonProps {
  variant?: ButtonVariant
  size?: ButtonSize
  color?: ButtonColor
  icon?: IconName
  width?: ButtonWidth
  disabled?: boolean
  onClick?(): void
  className?: string
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  href?: string
  external?: boolean
}

export const Button: FC<ButtonProps> = ({
  children,
  variant = 'primary',
  size = 'large',
  color: myColor,
  width = 'fit',
  icon,
  onClick,
  disabled,
  className,
  href,
  type = 'button',
  external = false,
}) => {
  const color = getDefaultColor(variant, myColor)
  const status: ButtonStatus = disabled ? 'disabled' : 'active'
  const iconColor = getIconColor(variant, status)

  const myClassName = classNames(
    styles.button,
    styles[`button--${variant}`],
    styles[`button--${size}`],
    styles[`button--${color}`],
    { [styles[`button--width-${width}`]]: typeof width !== 'number' },
    { [styles[`button--only-icon`]]: !children },
    className
  )

  if (href) {
    return (
      <Link href={href} passHref>
        <a
          className={myClassName}
          target={external ? '_blank' : '_self'}
          style={{ width: typeof width === 'number' ? `${width}px` : undefined }}
        >
          {icon && <Icon name={icon} color={iconColor} />}
          {children}
        </a>
      </Link>
    )
  }

  return (
    <button
      // Disable the rule because the prop `type` is typed from ButtonHTMLAttributes type
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={myClassName}
      disabled={disabled}
      onClick={onClick}
      style={{ width: typeof width === 'number' ? `${width}px` : undefined }}
    >
      {icon && <Icon name={icon} color={iconColor} />}
      {children}
    </button>
  )
}
