import { SmarthouserShowResponseDto } from 'repositories/http/swagger/schema'

export const smarthouserFixture: SmarthouserShowResponseDto = {
  id: '00000000-0000-0000-0000-000000000000',
  propertySlug: 'lbv0001',
  savings: {
    currentSavings: 45500,
    totalSavings: 80000,
    savingsPercentage: 5,
    monthlySavings: 1200,
    entryPayment: 30000,
  },
  hasActiveAlert: false,
}
