import { BackofficePropertyDetailBuilder } from '../../builders/BackofficePropertyDetailBuilder'
import { BackofficePropertyDetail } from '../../model/Property'
import { DeleteAdminPropertyDraft } from '../domain/DeleteAdminPropertyDraft'

export class DeleteAdminPropertyDraftFake implements DeleteAdminPropertyDraft {
  private readonly propertyDetails: BackofficePropertyDetail

  constructor(propertyDetails = new BackofficePropertyDetailBuilder().build()) {
    this.propertyDetails = propertyDetails
  }

  execute(): Promise<void> {
    return Promise.resolve()
  }
}
