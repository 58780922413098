import { LocaleKeys } from '../i18n/keys'

export enum PurchaseStatus {
  STEP_ONE = 'STEP_ONE',
  AWAIT_APPROVAL_STEP_ONE = 'AWAIT_APPROVAL_STEP_ONE',
  STEP_TWO = 'STEP_TWO',
  AWAIT_APPROVAL_STEP_TWO = 'AWAIT_APPROVAL_STEP_TWO',
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
}

export const purchaseGlobalStatusToi18nText = (t: LocaleKeys): { [key in PurchaseStatus]: string } => ({
  [PurchaseStatus.STEP_ONE]: t.common.purchaseStatus.step1,
  [PurchaseStatus.STEP_TWO]: t.common.purchaseStatus.step2,
  [PurchaseStatus.AWAIT_APPROVAL_STEP_ONE]: t.common.purchaseStatus.await1,
  [PurchaseStatus.AWAIT_APPROVAL_STEP_TWO]: t.common.purchaseStatus.await2,
  [PurchaseStatus.APPROVED]: t.common.purchaseStatus.completedAll,
  [PurchaseStatus.CANCELLED]: t.common.purchaseStatus.cancelledAll,
})

export const purchaseStepStatusToi18nText = (t: LocaleKeys, stepNumber: number): { [key in PurchaseStatus]: string } => {
  if (stepNumber === 1) {
    return {
      [PurchaseStatus.STEP_ONE]: t.common.purchaseStatus.doing,
      [PurchaseStatus.STEP_TWO]: t.common.purchaseStatus.completed,
      [PurchaseStatus.AWAIT_APPROVAL_STEP_ONE]: t.common.purchaseStatus.review,
      [PurchaseStatus.AWAIT_APPROVAL_STEP_TWO]: t.common.purchaseStatus.completed,
      [PurchaseStatus.APPROVED]: t.common.purchaseStatus.completed,
      [PurchaseStatus.CANCELLED]: t.common.purchaseStatus.cancelled,
    }
  }
  if (stepNumber === 2) {
    return {
      [PurchaseStatus.STEP_ONE]: t.common.purchaseStatus.pending,
      [PurchaseStatus.STEP_TWO]: t.common.purchaseStatus.doing,
      [PurchaseStatus.AWAIT_APPROVAL_STEP_ONE]: t.common.purchaseStatus.pending,
      [PurchaseStatus.AWAIT_APPROVAL_STEP_TWO]: t.common.purchaseStatus.review,
      [PurchaseStatus.APPROVED]: t.common.purchaseStatus.completed,
      [PurchaseStatus.CANCELLED]: t.common.purchaseStatus.cancelled,
    }
  }
  throw Error('Invalid Step number: step number must be either 1 or 2')
}

export const purchaseGlobalStatusToSpanishTextForSorting: { [key in PurchaseStatus]: string } = {
  [PurchaseStatus.STEP_ONE]: 'PASO 1',
  [PurchaseStatus.STEP_TWO]: 'PASO 2',
  [PurchaseStatus.AWAIT_APPROVAL_STEP_ONE]: 'REVISAR 1',
  [PurchaseStatus.AWAIT_APPROVAL_STEP_TWO]: 'REVISAR 2',
  [PurchaseStatus.APPROVED]: 'COMPLETADO',
  [PurchaseStatus.CANCELLED]: 'CANCELADO',
}

export const purchaseStepStatusToSpanishTextForSorting = (stepNumber: number): { [key in PurchaseStatus]: string } => {
  if (stepNumber === 1) {
    return {
      [PurchaseStatus.STEP_ONE]: 'EN CURSO',
      [PurchaseStatus.STEP_TWO]: 'COMPLETADO',
      [PurchaseStatus.AWAIT_APPROVAL_STEP_ONE]: 'REVISAR',
      [PurchaseStatus.AWAIT_APPROVAL_STEP_TWO]: 'COMPLETADO',
      [PurchaseStatus.APPROVED]: 'COMPLETADO',
      [PurchaseStatus.CANCELLED]: 'CANCELADO',
    }
  }
  if (stepNumber === 2) {
    return {
      [PurchaseStatus.STEP_ONE]: 'PENDIENTE',
      [PurchaseStatus.STEP_TWO]: 'EN CURSO',
      [PurchaseStatus.AWAIT_APPROVAL_STEP_ONE]: 'PENDIENTE',
      [PurchaseStatus.AWAIT_APPROVAL_STEP_TWO]: 'REVISAR',
      [PurchaseStatus.APPROVED]: 'COMPLETADO',
      [PurchaseStatus.CANCELLED]: 'CANCELADO',
    }
  }
  throw Error('Invalid Step number: step number must be either 1 or 2')
}
