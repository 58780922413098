export const formatterNumber = (value: number, options?: Intl.NumberFormatOptions | undefined) => {
  const fmt = new Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    ...options,
  }).format(value)
  return fmt
}

export const formatterCurrency = (value: number, symbol = '€', options?: Intl.NumberFormatOptions | undefined) => {
  return `${formatterNumber(value, {
    currency: 'EUR',
    minimumFractionDigits: 2,
    ...options,
  })}${symbol}`
}

export const formatterCurrencyNoDecimals = (value: number, symbol = '€', options?: Intl.NumberFormatOptions | undefined) => {
  return `${formatterNumber(value, {
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
  })}${symbol}`
}
