import { Icon, IconName } from 'components/DataDisplay/Icon/Icon'
import styles from './ModalButtonClose.module.scss'

interface ModalButtonCloseProps {
  onClose(): void
}

export const ModalButtonClose = ({ onClose }: ModalButtonCloseProps) => {
  return (
    <button type="button" aria-label="close" className={styles.close} onClick={onClose}>
      <Icon name={IconName.CLOSE} />
    </button>
  )
}
