import { HttpClient } from '../../utils/http-client/HttpClient'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { FinishPurchase } from '../domain/FinishPurchase'

export class FinishPurchaseHttp implements FinishPurchase {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(slug: string): Promise<void> {
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/admin/purchases/${slug}/finish`
    const options = { headers: {}, params: {} }
    await this.httpClient.post(url, {}, options)
  }
}
