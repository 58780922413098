import { UserBuilder } from '../../builders/UserBuilder'
import { User } from '../../model/User'
import { GetUser } from '../domain/GetUser'

export class GetUserFake implements GetUser {
  readonly user: User

  constructor(user = new UserBuilder().build()) {
    this.user = user
  }

  execute(): Promise<User> {
    return Promise.resolve(this.user)
  }
}
