import { useContext } from 'react'
import { TranslationContext } from '../context/TranslationContext'

/**
 * @deprecated The method should not be used
 */
export const useTranslation = () => {
  const context = useContext(TranslationContext)

  if (context == null) {
    throw new Error('useTranslation must be used within an TranslationProvider')
  }

  return {
    t: context,
  }
}
