import { LoadingProps } from 'components/Loading/Loading'
import React, { useState } from 'react'

type Loading = LoadingProps | null

const LoadingContext = React.createContext<Loading | null | undefined>(undefined)
const LoadingUpdaterContext = React.createContext<{ setLoading: (loading: Loading) => void } | undefined>(undefined)

export const LoadingProvider: React.FC = ({ children }) => {
  const [Loading, setLoading] = useState<Loading | null>(null) // TODO: useReducer instead, not critical

  return (
    <LoadingContext.Provider value={Loading}>
      <LoadingUpdaterContext.Provider value={{ setLoading }}>{children}</LoadingUpdaterContext.Provider>
    </LoadingContext.Provider>
  )
}

export function useLoadingProvider() {
  const context = React.useContext(LoadingContext)

  if (typeof context === 'undefined') {
    throw new Error('useLoadingProvider must be used within a LoadingProvider')
  }

  return context
}

export function useLoadingUpdater() {
  const loadingUpdater = React.useContext(LoadingUpdaterContext)

  if (typeof loadingUpdater === 'undefined') {
    throw new Error('useLoadingUpdater must be used within a LoadingProvider')
  }

  return loadingUpdater
}

type LoadingContextType = {
  loading: Loading | null
  setLoading: (Loading: Loading) => void
}
export const useLoading = (): LoadingContextType => ({
  loading: useLoadingProvider(),
  ...useLoadingUpdater(),
})
