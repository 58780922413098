import { Payment } from '../../model/Payment'
import { Purchase } from '../../model/Purchase'
import { PurchaseStatus } from '../../model/PurchaseStatus'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { GetPurchase } from '../domain/GetPurchase'
import { PaymentReceiptResponseDto } from './swagger/schema'
import { Documentation, EmploymentTypes, IdentityTypes } from '../../model/Documentation'

export class GetPurchaseHttp implements GetPurchase {
  private readonly httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  private mapPaymentToModel(payment: PaymentReceiptResponseDto): Payment {
    return {
      id: payment.id,
      amount: payment.amount,
      documents: payment.documents.map((document) => ({
        id: document.id,
      })),
    }
  }

  private mapResponseToModel(response: any): any {
    return {
      slug: response.purchaseSlug,
      propertySlug: response.propertySlug,
      email: response.email,
      phoneNumber: response.phoneNumber,
      checkinDate: response.checkinDate,
      totalOwners: response.totalOwners,
      reservationFee: response.reservationFee,
      downpayment: response.entryPayment,
      status: response.status as PurchaseStatus,
      errorMessage: response.errorMessage,
      owners: response.owners.map(
        (owner: {
          slug: any
          name: any
          email: any
          surname: any
          birthDate: any
          address: any
          documentation: { createdAt: any; type: IdentityTypes | EmploymentTypes; serialNumber: any; documents: { id: any }[] }[]
        }) => ({
          slugId: owner.slug,
          name: owner.name,
          email: owner.email,
          surname: owner.surname,
          birthDate: owner.birthDate,
          address: owner.address,
          documentation: owner.documentation.map(
            (documentation: {
              createdAt: any
              type: IdentityTypes | EmploymentTypes
              serialNumber: any
              documents: { id: any }[]
            }): Documentation => ({
              createdAt: documentation.createdAt,
              // slug: documentation.slug,
              type: documentation.type as IdentityTypes | EmploymentTypes,
              serialNumber: documentation.serialNumber || '',
              documents: documentation.documents.map((document: { id: any }) => ({
                id: document.id,
              })),
            })
          ),
        })
      ),
      payments: {
        reservationPayment: response.payments.bookingPaymentReceipt
          ? this.mapPaymentToModel(response.payments.bookingPaymentReceipt)
          : (null as unknown as Payment),
        downpaymentPayment: response.payments.entryPaymentReceipt
          ? this.mapPaymentToModel(response.payments.entryPaymentReceipt)
          : (null as unknown as Payment),
        rentPayments:
          response.payments.rentPaymentsReceipts.length > 0
            ? response.payments.rentPaymentsReceipts.map(
                (payment: {
                  id: string
                  type: 'BOOKING' | 'ENTRY' | 'MONTHLY' | 'ADDITIONAL'
                  createdAt: string
                  amount: number
                  documents: { id: string }[]
                }) => this.mapPaymentToModel(payment)
              )
            : [],
        additionalPayments:
          response.payments.additionalPaymentsReceipts.length > 0
            ? response.payments.additionalPaymentsReceipts.map(
                (payment: {
                  id: string
                  type: 'BOOKING' | 'ENTRY' | 'MONTHLY' | 'ADDITIONAL'
                  createdAt: string
                  amount: number
                  documents: { id: string }[]
                }) => this.mapPaymentToModel(payment)
              )
            : [],
      },
      totalSaved: response.totalSaved,
      totalSavedPercentage: response.totalSavedPercentage,
      totalSavings: response.totalSavings,
    }
  }

  async execute(purchaseSlug: string): Promise<Purchase> {
    const response = await this.httpClient.get<any>(`${getPublicRuntimeConfig().API_ENDPOINT}/purchases/${purchaseSlug}`)

    return this.mapResponseToModel(response)
  }
}
