import { ValidatePurchaseCurrentStep } from '../domain/ValidatePurchaseCurrentStep'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'

export class ValidatePurchaseCurrentStepHttp implements ValidatePurchaseCurrentStep {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  execute(purchaseSlug: string): Promise<void> {
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/admin/purchases/${purchaseSlug}/accept`
    const options = { headers: {}, params: {} }
    return this.httpClient.post(url, {}, options)
  }
}
