import { GetSmarthouser } from 'repositories/domain/GetSmarthouser'
import { SmarthouserShowResponseDto } from 'repositories/http/swagger/schema'
import { smarthouserFixture } from '../../../test/fixtures/smarthouser'

export class GetSmarthouserFake implements GetSmarthouser {
  private readonly smarthouser: SmarthouserShowResponseDto

  constructor() {
    this.smarthouser = smarthouserFixture
  }

  execute(): Promise<SmarthouserShowResponseDto> {
    return Promise.resolve(this.smarthouser)
  }
}
