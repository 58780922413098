import { TranslationKeys } from 'i18n/locales/translationKeys'
import useTranslationBase from 'next-translate/useTranslation'
import type { TranslationQuery } from 'next-translate'

export const useTranslation = (defaultNs?: string) => {
  const { t, ...rest } = useTranslationBase(defaultNs)

  return {
    t: <B extends boolean = false>(
      key: TranslationKeys,
      query?: TranslationQuery,
      options?: {
        interpolation?: { escapeValue?: boolean }
        returnObjects?: B
      }
    ): B extends false ? string : string[] => t(key, query, options),
    ...rest,
  }
}
