import { MarketplaceProperties, MarketplaceProperty } from '../../model/Property'
import { PropertyStatus } from '../../model/PropertyStatus'
import { PropertyTag } from '../../model/PropertyTag'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { calculateOffset } from '../../utils/calculateOffset'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { GetProperties } from '../domain/GetProperties'
import { PropertyIndexResponseDto, PropertyResultsDto } from './swagger/schema'

type PropertiesResponse = PropertyIndexResponseDto

export class GetPropertiesHttp implements GetProperties {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  private mapPropertyApiToModel(property: PropertyResultsDto): MarketplaceProperty {
    return {
      id: property.id,
      slug: property.slug,
      images: property.images,
      status: property.status as PropertyStatus, // TODO: Check that backend/frontend enums matches,
      isFavorite: false, // TODO: fix isFavorite at from when composer is done
      address: {
        city: property.address.city,
        neighborhood: property.address.neighborhood,
      },
      title: property.title,
      pricePerMonth: property.pricePerMonth,
      monthlySavings: property.monthlySavings,
      downPayment: property.entryPayment,
      entryPayment: property.entryPayment,
      surface: property.surface,
      bedroomsAmount: property.bedroomsAmount,
      bathroomsAmount: property.bathroomsAmount,
      tags: property.tags as PropertyTag[], // TODO: Check that backend/frontend enums matches
      tryBeforeYouBuyIt: property.tryBeforeYouBuyIt,
      wholeRentalForBuying: property.wholeRentalForBuying,
      yearPlan: property.yearPlan,
      specialOffer: property.specialOffer,
      latitude: property.latitude,
      longitude: property.longitude,
    }
  }

  private mapResponseToModel(response: PropertiesResponse): MarketplaceProperties {
    return {
      properties: response.properties.map((property) => this.mapPropertyApiToModel(property)),
      totalCount: response.totalCount,
    }
  }

  async execute(
    tags: PropertyTag[],
    neighborhoods: string[],
    minPricePerMonth?: number,
    maxPricePerMonth?: number,
    minSurface?: number,
    maxSurface?: number,
    city?: string,
    bedrooms?: number[],
    bathrooms?: number[],
    page?: number,
    limit?: number
  ): Promise<MarketplaceProperties> {
    // const paramsTags = tags ? { 'tags[]': tags } : {}
    const paramsMinPricePerMonth = minPricePerMonth ? { minPricePerMonth } : {}
    const paramsMaxPricePerMonth = maxPricePerMonth ? { maxPricePerMonth } : {}
    const paramsMinSurface = minSurface ? { minSurface } : {}
    const paramsMaxSurface = maxSurface ? { maxSurface } : {}
    const paramsCity = city ? { city } : {}
    const paramsNeighborhoods = neighborhoods ? { 'neighborhoods[]': neighborhoods } : {}
    const paramsBedrooms = bedrooms ? { 'bedrooms[]': bedrooms } : {}
    const paramsBathrooms = bathrooms ? { 'bathrooms[]': bathrooms } : {}
    const paramsPagination = page && limit && limit > 0 ? { offset: calculateOffset(page, limit), limit } : {}

    const response = await this.httpClient.get<PropertiesResponse>(`${getPublicRuntimeConfig().API_ENDPOINT}/properties`, {
      headers: {},
      params: {
        ...paramsPagination,
        // ...paramsTags,
        ...paramsMinPricePerMonth,
        ...paramsMaxPricePerMonth,
        ...paramsMinSurface,
        ...paramsMaxSurface,
        ...paramsCity,
        ...paramsNeighborhoods,
        ...paramsBedrooms,
        ...paramsBathrooms,
      },
    })

    return this.mapResponseToModel(response)
  }
}
