import { DealflowShowWithPropertyResponseDto, HomeOwnerResponseDto, PaymentReceiptResponseDto } from 'repositories/http/swagger/schema'
import { DealflowStatusEnum } from 'model/DealflowStatus'
import { OwnerBuilder } from './OwnerBuilder'

export class BackofficeDealflowDetailBuilder {
  private readonly dealflow: DealflowShowWithPropertyResponseDto

  constructor(id = 'aff15ddd-819c-4809-9d93-836044bbf548') {
    const checkinDate = new Date()
    // Set te checkin month for the next month
    checkinDate.setMonth(checkinDate.getMonth() + 1)

    const mainOwner = new OwnerBuilder().build()

    this.dealflow = {
      id,
      propertyId: '',
      propertySlug: 'cool-house-in-madrid',
      homeOwners: [{ ...mainOwner, id: '', userId: '', birthdate: '', documentation: [], phone: '' }],
      status: DealflowStatusEnum.PENDING_USER_DATA,
      payments: [],
      documents: [],
    }
  }

  withPropertySlug(propertySlug: string) {
    this.dealflow.propertySlug = propertySlug
    return this
  }

  withUserName(name: string) {
    this.dealflow.homeOwners[0].name = name
    return this
  }

  withStatus(status: DealflowStatusEnum) {
    this.dealflow.status = status
    return this
  }

  withMainOwnerName(ownerName: string) {
    this.dealflow.homeOwners[0].name = ownerName
    return this
  }

  build() {
    return this.dealflow
  }

  withOwners(owners: HomeOwnerResponseDto[]) {
    this.dealflow.homeOwners = owners
    return this
  }

  withPayments(payments: PaymentReceiptResponseDto[]) {
    this.dealflow.payments = payments
    return this
  }
}
