import React, { FC } from 'react'
import classNames from 'classnames'
import { Typography, TypographyType } from '../../DataDisplay/Typography/Typography'
import styles from './ErrorInputMessage.module.scss'

type ErrorMessageProps = {
  htmlFor: string
  text: string
  className?: string
}

export const ErrorInputMessage: FC<ErrorMessageProps> = ({ htmlFor, text, className }) => {
  if (!text) return null
  return (
    <Typography className={classNames(styles.container, className)} typographyType={TypographyType.FOOTNOTE} htmlFor={htmlFor}>
      {text}
    </Typography>
  )
}
