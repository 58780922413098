import { IdentityTypes } from 'model/Documentation'
import { LocaleKeys } from '../i18n/keys'

export function translateIdentityDocument(t: LocaleKeys, document: IdentityTypes): string {
  const translation: Record<IdentityTypes, string> = {
    [IdentityTypes.DNI]: t.purchase.stepOne.documentation.dni,
    [IdentityTypes.PASSPORT]: t.purchase.stepOne.documentation.passport,
    [IdentityTypes.NIE]: t.purchase.stepOne.documentation.nie,
    [IdentityTypes.DRIVING_LICENSE]: t.purchase.stepOne.documentation.driverLicense,
  }

  return translation[document]
}
