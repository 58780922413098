import { FramedIcon } from 'components/DataDisplay/FramedIcon/FramedIcon'
import { IconName, IconColor } from 'components/DataDisplay/Icon/Icon'
import { Text } from 'components/Text/Text'
import Link from 'next/link'
import React, { FC } from 'react'

import styles from './FileContainer.module.scss'

type FileContainerProps = {
  fileUrl: string
  onReplaceFile: () => void
}

export const FileContainer: FC<FileContainerProps> = ({ fileUrl, onReplaceFile }) => {
  return (
    <div className={styles.container}>
      <FramedIcon color={IconColor.WHITE} name={IconName.CHECK} size={15} className={styles.roundIcon} />
      <div>
        <Text weight="bold" className={styles.viewFile} color="blue-600">
          Your file has been successfully uploaded.{' '}
          <Link target="_blank" href={fileUrl} passHref>
            <a target="_blank">View document</a>
          </Link>
        </Text>
      </div>
      <div>
        <div role="button" tabIndex={0} onKeyDown={() => onReplaceFile()} onClick={() => onReplaceFile()} className={styles.replaceFile}>
          <Text weight="bold">Replace file</Text>
        </div>
      </div>
    </div>
  )
}
