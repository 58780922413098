import { LocaleKeys } from '../i18n/keys'

export enum PropertyStatus {
  AVAILABLE = 'AVAILABLE',
  RESERVED = 'RESERVED',
  DRAFT = 'DRAFT',
  SOLD = 'SOLD',
}

export const propertyStatusToi18nText = (t: LocaleKeys): { [key in PropertyStatus]: string } => ({
  [PropertyStatus.AVAILABLE]: t.common.propertyStatus.available,
  [PropertyStatus.RESERVED]: t.common.propertyStatus.booked,
  [PropertyStatus.SOLD]: t.common.propertyStatus.sold,
  [PropertyStatus.DRAFT]: t.common.propertyStatus.draft,
})

export const propertyStatusToSpanishTextForSorting: { [key in PropertyStatus]: string } = {
  [PropertyStatus.AVAILABLE]: 'DISPONIBLE',
  [PropertyStatus.RESERVED]: 'RESERVADA',
  [PropertyStatus.SOLD]: 'VENDIDA',
  [PropertyStatus.DRAFT]: 'BORRADOR',
}
