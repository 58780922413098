import { Document } from './Document'

export enum EmploymentTypes {
  PAYROLL = 'PAYROLL',
  WORK_TRAJECTORY_REPORT = 'WORK_TRAJECTORY_REPORT',
  TAXES = 'TAXES',
  SOCIAL_INSURANCE_RECEIPT = 'SOCIAL_INSURANCE_RECEIPT',
  INCOME_CERTIFICATE = 'INCOME_CERTIFICATE',
}

export enum IdentityTypes {
  DNI = 'DNI',
  PASSPORT = 'PASSPORT',
  NIE = 'NIE',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
}

export type Documentation = {
  /**
   * It's actually a Date
   */
  createdAt: string
  slug?: string
  type: IdentityTypes | EmploymentTypes
  serialNumber: string
  documents: Document[]
}

export type DocumentationIdentity = Documentation & {
  type: IdentityTypes
}

export type DocumentationEmployment = Documentation & {
  type: EmploymentTypes
}

export type AllDocumentsType = {
  id: string
  type: string
  createdAt: string
  document: Document
}
export const documentationIsIdentity = (
  documentation: DocumentationIdentity | DocumentationEmployment
): documentation is DocumentationIdentity => documentation.type in IdentityTypes

export const documentationIsEmployment = (
  documentation: DocumentationIdentity | DocumentationEmployment
): documentation is DocumentationEmployment => documentation.type in EmploymentTypes

function sortByCreatedAt<T extends Documentation>(documentations: T[]) {
  return [...documentations].sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  })
}

export function getMostRecentDocumentations<T extends Documentation>(documentations: T[]): { first: T | undefined; second: T | undefined } {
  const sortedDocs = sortByCreatedAt(documentations)

  return { first: sortedDocs[0], second: sortedDocs[1] }
}
