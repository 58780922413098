import { DeviceType } from 'hooks/useDevice'
import { createContext, useContext } from 'react'

interface UserContextProps {
  initialDevice: DeviceType
}

const UserContext = createContext<UserContextProps | undefined>(undefined)

interface UserProviderProps {
  initialDevice: UserContextProps['initialDevice']
  children: React.ReactNode
}

export const UserProvider = ({ children, initialDevice }: UserProviderProps) => {
  return <UserContext.Provider value={{ initialDevice }}>{children}</UserContext.Provider>
}

export const useUserContext = (): UserContextProps => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context
}
