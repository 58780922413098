import { useEffect, useRef } from 'react'

/**
 * Align the checkbox to top when the label is multiline
 * @returns
 */
export const useAlignCheckbox = () => {
  const ref = useRef<HTMLInputElement>(null)
  const labelRef = useRef<HTMLLabelElement>(null)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { target } = entry
        const { clientHeight } = target

        if (!labelRef.current) {
          return
        }
        if (clientHeight > 26) {
          labelRef.current.style.alignItems = 'flex-start'
        } else {
          labelRef.current.style.alignItems = 'center'
        }
      }
    })

    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    return () => {
      resizeObserver?.disconnect()
    }
  }, [labelRef, ref])

  return {
    ref,
    labelRef,
  }
}
