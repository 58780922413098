import { BackofficePurchase } from '../model/Purchase'
import { PurchaseStatus } from '../model/PurchaseStatus'
import { formatISODateToDateOnly } from '../utils/formatDate'

export class BackofficePurchaseBuilder {
  private readonly purchase: BackofficePurchase

  constructor(slug = 'aff15ddd-819c-4809-9d93-836044bbf548') {
    const checkinDate = new Date()
    // Set te checkin month for the next month
    checkinDate.setMonth(checkinDate.getMonth() + 1)

    this.purchase = {
      slug,
      propertySlug: 'cool-house-in-madrid',
      checkinDate: formatISODateToDateOnly(checkinDate),
      userName: 'Juan Antonio',
      downpayment: 15000,
      pricePerMonth: 1100,
      createdAt: '2020-07-07',
      status: PurchaseStatus.STEP_ONE,
    }
  }

  withPropertySlug(propertySlug: string) {
    this.purchase.propertySlug = propertySlug
    return this
  }

  withUserName(name: string) {
    this.purchase.userName = name
    return this
  }

  createdAt(date: string | Date) {
    if (date instanceof Date) {
      this.purchase.createdAt = formatISODateToDateOnly(date)
    } else {
      this.purchase.createdAt = date
    }
    return this
  }

  withCheckinDate(date: string | Date) {
    if (date instanceof Date) {
      this.purchase.checkinDate = formatISODateToDateOnly(date)
    } else {
      this.purchase.checkinDate = date
    }
    return this
  }

  withDownpayment(downpayment: number) {
    this.purchase.downpayment = downpayment
    return this
  }

  withPricePerMonth(pricePerMonth: number) {
    this.purchase.pricePerMonth = pricePerMonth
    return this
  }

  withStatus(status: PurchaseStatus) {
    this.purchase.status = status
    return this
  }

  build(): BackofficePurchase {
    return this.purchase
  }
}
