export const careerKeys = {
  all: ['careers'] as const,
}

export const propertiesKeys = {
  all: ['properties'] as const,
  bySlug: (slug: string) => ['properties', slug] as const,
  byParams: (params: Record<string, unknown>) => ['properties', params] as const,
}

export const userKeys = {
  all: ['users'] as const,
  me: () => [...userKeys.all, 'me'] as const,
}

export const areaKeys = {
  hierarchy: ['areas', 'hierarchy'] as const,
}

export const purchaseProcessKeys = {
  all: ['dealflow'] as const,
  byId: (id: string) => ['dealflow', id],
}

export const smarthouserKeys = {
  all: ['smarthouser'] as const,
  byId: (id: string) => ['smarthouser', id],
}
