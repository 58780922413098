import classNames from 'classnames'
import React, { FC } from 'react'
import SlickSlider, { Settings } from 'react-slick'
import { Icon, IconName, IconType, IconColor } from '../Icon/Icon'

import styles from './Slider.module.scss'

const DEFAULT_OPTIONS: Settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
}

type SliderProps = {
  options?: Settings
  sliderSlickSlide100?: boolean
  hideFirstAndLastSlidesArrows?: boolean
  className?: string
  arrowLeftClassName?: string
  arrowRightClassName?: string
}

type SliderButtonProps = {
  onClick?: () => void
  currentSlide?: number
  hideFirstAndLastSlidesArrows?: boolean
  customClass?: string
}

type SliderNextButtonProps = SliderButtonProps & {
  sliderLenght?: number
}

const SliderButtonNext: FC<SliderNextButtonProps> = ({ onClick, currentSlide, sliderLenght, hideFirstAndLastSlidesArrows, customClass }) => {
  if (hideFirstAndLastSlidesArrows && currentSlide === sliderLenght) {
    return <div />
  }

  return (
    <button
      type="button"
      className={classNames(styles.sliderButton, customClass || styles.nextButton)}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        if (onClick) onClick()
      }}
    >
      <Icon className={styles.sliderButtonIcon} name={IconName.RIGHT_ARROW} type={IconType.LINE} color={IconColor.BLACK} size={22} />
    </button>
  )
}

const SliderButtonPrev: FC<SliderButtonProps> = ({ onClick, currentSlide, hideFirstAndLastSlidesArrows, customClass }) => {
  if (hideFirstAndLastSlidesArrows && currentSlide === 0) {
    return <div />
  }
  return (
    <button
      type="button"
      className={classNames(styles.sliderButton, customClass || styles.prevButton)}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        if (onClick) onClick()
      }}
    >
      <Icon className={styles.sliderButtonIcon} name={IconName.LEFT_ARROW} type={IconType.LINE} color={IconColor.BLACK} size={22} />
    </button>
  )
}

export const Slider: FC<SliderProps> = ({
  children,
  options = DEFAULT_OPTIONS,
  className,
  sliderSlickSlide100 = false,
  hideFirstAndLastSlidesArrows = false,
  arrowLeftClassName,
  arrowRightClassName,
}) => {
  const buttons = {
    nextArrow: (
      <SliderButtonNext
        customClass={arrowRightClassName}
        sliderLenght={React.Children.toArray(children).length - 1}
        hideFirstAndLastSlidesArrows={hideFirstAndLastSlidesArrows}
      />
    ),
    prevArrow: <SliderButtonPrev customClass={arrowLeftClassName} hideFirstAndLastSlidesArrows={hideFirstAndLastSlidesArrows} />,
  }
  return (
    <SlickSlider {...options} {...buttons} className={`${styles.slider} ${sliderSlickSlide100 ? styles.sliderSlickSlide100 : ''} ${className}`}>
      {children}
    </SlickSlider>
  )
}
