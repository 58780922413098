import React, { FC } from 'react'
import { Repositories } from '../repositories/domain/Repositories'

export const RepositoriesContext = React.createContext<Repositories>(null as any)

type RepositoriesProviderProps = {
  repositories: Repositories
}

export const RepositoriesProvider: FC<RepositoriesProviderProps> = ({ children, repositories }) => (
  <RepositoriesContext.Provider value={repositories}>{children}</RepositoriesContext.Provider>
)
