export enum PropertyTag {
  SUNNY = 'SUNNY',
  PETS_ALLOWED = 'PETS_ALLOWED',
  SHOPPING_AREA = 'SHOPPING_AREA',
  RESTAURANTS_NEAR = 'RESTAURANTS_NEAR',
  PUBLIC_TRANSPORT = 'PUBLIC_TRANSPORT',
  FOR_BIKERS = 'FOR_BIKERS',
  FOR_FAMILIES = 'FOR_FAMILIES',
  PARKS_NEAR = 'PARKS_NEAR',
}
