export const esTranslation = {
  consentModal: {
    title: 'En esta web utilizamos cookies para mejorar la calidad de nuestros servicios y su experiencia de navegación.',
    description: 'Podrá encontrar más información en nuestra <a target="_blank" href="/cookies">Política de Cookies</a>',
    acceptAllBtn: 'Aceptar todas',
    acceptNecessaryBtn: 'Rechazar todas',
    showPreferencesBtn: 'Manejar preferencias',
  },
  preferencesModal: {
    title: 'Preferencias de cookies',
    acceptAllBtn: 'Aceptar todas',
    acceptNecessaryBtn: 'Rechazar todas',
    savePreferencesBtn: 'Guardar preferencias',
    closeIconLabel: 'Cerrar',
    sections: [
      {
        title: 'Uso de las cookies',
        description: 'Utilizamos cookies para garantizar las funcionalidades básicas del sitio web y mejorar su experiencia en línea...',
      },
      {
        title: 'Cookies estrictamente necesarias',
        description:
          'Estas cookies son esenciales para el funcionamiento adecuado de mi sitio web. Sin estas cookies, el sitio web no funcionaría correctamente',
        linkedCategory: 'necessary',
      },
      {
        title: 'Cookies de rendimiento y análisis',
        description: 'Estas cookies permiten que el sitio web recuerde las elecciones que ha hecho en el pasado',
        linkedCategory: 'analytics',
        cookieTable: {
          headers: {
            name: 'Nombre',
            domain: 'Servicio',
            description: 'Descripción',
            expiration: 'Expiración',
          },
          body: [
            {
              name: '_ga',
              domain: 'Google Analytics',
              description:
                'Cookie set by <a target="_blank" href="https://support.google.com/analytics/answer/11397207?hl=en">Google Analytics</a>',
              expiration: 'Caduca después 2 años',
            },
            {
              name: '_gid',
              domain: 'Google Analytics',
              description:
                'Cookie set by <a target="_blank" href="https://support.google.com/analytics/answer/11397207?hl=en">Google Analytics</a>',
              expiration: 'Caduca en 1 día',
            },
            {
              name: '_fbp',
              domain: 'Meta Analytics',
              description: 'Cookie set by <a target="_blank" href="https://www.facebook.com/privacy/policies/cookies/">Meta Analytics</a>',
              expiration: 'Caduca después de 3 meses',
            },
            {
              name: '__cf_bm',
              domain: 'Hubspot',
              description:
                'Cookie set by <a target="_blank" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser">Hubspot</a>',
              expiration: 'Caduca en 6 meses',
            },
            {
              name: '_cfuvid',
              domain: 'Hubspot',
              description:
                'Cookie set by <a target="_blank" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser">Hubspot</a>',
              expiration: 'Caduca en 6 meses',
            },
          ],
        },
      },
    ],
  },
}

export const enTranslation = {
  consentModal: {
    title: 'On this website, we use cookies to improve the quality of our services and your browsing experience.',
    description: 'You can find more information in our <a target="_blank" href="/cookies">Cookie Policy</a>',
    acceptAllBtn: 'Accept all',
    acceptNecessaryBtn: 'Reject all',
    showPreferencesBtn: 'Manage preferences',
  },
  preferencesModal: {
    title: 'Cookie preferences',
    acceptAllBtn: 'Accept all',
    acceptNecessaryBtn: 'Reject all',
    savePreferencesBtn: 'Save preferences',
    closeIconLabel: 'Close',
    sections: [
      {
        title: 'Use of cookies',
        description: 'We use cookies to ensure the basic functionalities of the website and to enhance your online experience...',
      },
      {
        title: 'Strictly necessary cookies',
        description:
          'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work correctly',
        linkedCategory: 'necessary',
      },
      {
        title: 'Performance and analytics cookies',
        description: 'These cookies allow the website to remember the choices you have made in the past',
        linkedCategory: 'analytics',
        cookieTable: {
          headers: {
            name: 'Name',
            domain: 'Service',
            description: 'Description',
            expiration: 'Expiration',
          },
          body: [
            {
              name: '_ga',
              domain: 'Google Analytics',
              description:
                'Cookie set by <a target="_blank" href="https://support.google.com/analytics/answer/11397207?hl=en">Google Analytics</a>',
              expiration: 'Expires after 2 years',
            },
            {
              name: '_gid',
              domain: 'Google Analytics',
              description:
                'Cookie set by <a target="_blank" href="https://support.google.com/analytics/answer/11397207?hl=en">Google Analytics</a>',
              expiration: 'Expires in 1 day',
            },
            {
              name: '_fbp',
              domain: 'Meta Analytics',
              description: 'Cookie set by <a target="_blank" href="https://www.facebook.com/privacy/policies/cookies/">Meta Analytics</a>',
              expiration: 'Expires after 3 months',
            },
            {
              name: '__cf_bm',
              domain: 'Hubspot',
              description:
                'Cookie set by <a target="_blank" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser">Hubspot</a>',
              expiration: 'Expires in 6 months',
            },
            {
              name: '_cfuvid',
              domain: 'Hubspot',
              description:
                'Cookie set by <a target="_blank" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser">Hubspot</a>',
              expiration: 'Expires in 6 months',
            },
          ],
        },
      },
    ],
  },
}
