import { CreatePropertyDraft } from '../domain/CreatePropertyDraft'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { BackofficePropertyDetail } from '../../model/Property'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { AdminPropertyDetailResponseDto, AdminPropertyDraftCreateDto } from './swagger/schema'
import { mapBackofficePropertyDetailResponseToModel } from './maps/backofficePropertyDetailResponseMap'

export class CreatePropertyDraftHttp implements CreatePropertyDraft {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(body: AdminPropertyDraftCreateDto): Promise<BackofficePropertyDetail> {
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/admin/property_drafts`
    const options = { headers: {}, params: {} }

    const response = await this.httpClient.post<AdminPropertyDetailResponseDto>(url, body, options)

    return mapBackofficePropertyDetailResponseToModel(response)
  }
}
