import { CreatePropertyDraftsUpload } from '../domain/CreatePropertyDraftsUpload'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { FormDataObject } from '../../utils/http-client/HttpClientNode'

export class CreatePropertyDraftsUploadHttp implements CreatePropertyDraftsUpload {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  execute(propertyDraftsFile: File): Promise<any> {
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/admin/property_drafts/upload`
    const dataCsv = [
      {
        name: 'file',
        value: propertyDraftsFile,
      },
    ] as FormDataObject[]
    return this.httpClient.postFiles<any>(url, dataCsv)
  }
}
