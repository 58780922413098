import { LocaleKeys } from '../i18n/keys'

export enum PropertyOrientation {
  NORTH = 'NORTH',
  EAST = 'EAST',
  WEST = 'WEST',
  SOUTH = 'SOUTH',
}

export function translatePropertyOrientation(t: LocaleKeys, propertyOrientation: PropertyOrientation): string {
  const propertyOrientationTranslation: { [key in PropertyOrientation]: string } = {
    [PropertyOrientation.NORTH]: t.common.propertyOrientation.north,
    [PropertyOrientation.EAST]: t.common.propertyOrientation.east,
    [PropertyOrientation.WEST]: t.common.propertyOrientation.west,
    [PropertyOrientation.SOUTH]: t.common.propertyOrientation.south,
  }

  return propertyOrientationTranslation[propertyOrientation]
}
