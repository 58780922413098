import { HttpClient } from '../../utils/http-client/HttpClient'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { AdminPropertyDetailResponseDto } from './swagger/schema'
import { UnpublishAdminProperty } from '../domain/UnpublishAdminProperty'

export class UnpublishAdminPropertyHttp implements UnpublishAdminProperty {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(slug: string): Promise<void> {
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/admin/properties/${slug}/unpublish`
    const options = { headers: {}, params: {} }

    await this.httpClient.put<AdminPropertyDetailResponseDto>(url, {}, options)
  }
}
