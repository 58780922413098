import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { PublishAdminPropertyDraft } from '../domain/PublishAdminPropertyDraft'
import { AdminPropertyDetailResponseDto } from './swagger/schema'

export class PublishAdminPropertyDraftHttp implements PublishAdminPropertyDraft {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(slug: string): Promise<void> {
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/admin/property_drafts/${slug}/publish`
    const options = { headers: {}, params: {} }

    await this.httpClient.put<AdminPropertyDetailResponseDto>(url, undefined, options)
  }
}
