import { Purchase } from '../../model/Purchase'
import { RecursivePartial } from '../../model/RecursivePartial'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { UpdatePurchase } from '../domain/UpdatePurchase'

export class UpdatePurchaseHttp implements UpdatePurchase {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  private purchaseToDto(purchase: RecursivePartial<Purchase>): any {
    const owners =
      purchase.owners && purchase.owners.length > 0
        ? purchase.owners.map((owner) => ({
            slug: owner.slugId,
            name: owner.name,
            email: owner.email,
            surname: owner.surname,
            birthDate: owner.birthDate ? new Date(owner.birthDate).toISOString() : undefined,
            address: owner.address,
          }))
        : []
    return {
      email: purchase.email,
      phoneNumber: purchase.phoneNumber,
      checkinDate: purchase.checkinDate ? new Date(purchase.checkinDate).toISOString() : undefined,
      totalOwners: purchase.totalOwners,
      owners,
    }
  }

  execute(purchaseSlug: string, purchase: RecursivePartial<Purchase>): Promise<void> {
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/purchases/${purchaseSlug}`
    const options = { headers: {}, params: {} }

    return this.httpClient.patch<void>(url, this.purchaseToDto(purchase), options)
  }
}
