import { format, formatISO } from 'date-fns'

/**
 * Format a date to ISO representation but without time -> example: 2020-06-31
 * @param date a Date() object
 */
export const formatISODateToDateOnly = (date: Date): string => {
  return formatISO(date, { representation: 'date' })
}

export const formatToDateAndTime = (date: Date): string => {
  return format(date, 'dd/MM/yyyy, HH:mm')
}
