import classNames from 'classnames'
import { Icon, IconName, IconType } from 'components/DataDisplay/Icon/Icon'
import { Typography, TypographyType } from 'components/DataDisplay/Typography/Typography'
import { Checkbox } from 'components/Inputs/Checkbox/Checkbox'
import { ErrorInputMessage } from 'components/Inputs/ErrorInputMessage/ErrorInputMessage'
import { FormLabel } from 'components/Inputs/Label/FormLabel'
import { useSelect } from 'downshift'
import { useField } from 'formik'
import { createRef, FC, RefObject, useEffect, useState } from 'react'

import styles from './Select.module.scss'

type Sizes = 'small' | 'medium'

const sizes: Record<Sizes, string> = {
  small: styles.small,
  medium: styles.medium,
}

export interface SelectProps<TValues extends string = string> {
  id: string
  options: SelectOptionModel<TValues>[]
  onChange: (value: any) => void
  label?: string
  placeholder?: string
  containerClass?: string
  selectClass?: string
  dropdownClass?: string
  search?: boolean
  firstOption?: boolean
  value?: string
  error?: string
  disabled?: boolean
  size?: Sizes
  shrink?: boolean
  position?: 'left' | 'right'
  component?: JSX.Element
  hasError?: boolean
  multiSelect?: boolean
  selectedValuesMulti?: string[]
  customIcon?: JSX.Element
  clearMultiValues?: boolean
}

export type SelectOptionModel<TValues extends string = string> = {
  title: JSX.Element | string
  value: TValues
  description?: string
  default?: boolean
  emoji?: string
}

export const Select: FC<SelectProps> = ({
  id,
  options,
  onChange,
  label,
  placeholder,
  containerClass,
  selectClass,
  dropdownClass,
  search,
  firstOption,
  value,
  error,
  disabled,
  size = 'medium',
  shrink = false,
  component,
  position = 'left',
  hasError,
  multiSelect,
  selectedValuesMulti = [],
  customIcon,
  clearMultiValues = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const ref: RefObject<HTMLInputElement> = createRef()
  const emptyOption: SelectOptionModel = { title: '', value: '' }
  const [selectedValues, setSelectedValues] = useState<string[]>(selectedValuesMulti)

  const itemToString = (item: SelectOptionModel | null) => (item ? (item.title as string) : '')
  const getOptionByValue = (value: string) => options.find((option) => option.value === value)
  const filterByValue = (array: Array<SelectOptionModel>, string: string) => {
    if (!string) return array
    return array.filter((o) => (o.title as string).toLowerCase().includes(string.toLowerCase()))
  }

  useEffect(() => {
    if (!clearMultiValues) return
    setSelectedValues([])
  }, [clearMultiValues])

  // by preference: default value, first option (if it is enable) and empty option
  const itemToSelect: SelectOptionModel = getOptionByValue(value ?? '') ?? ((firstOption && options[0]) || emptyOption)

  const { isOpen, selectedItem, getToggleButtonProps, getMenuProps, getItemProps } = useSelect({
    id,
    selectedItem: itemToSelect,
    items: options,
    itemToString,
    onSelectedItemChange: (item) => {
      onChange?.(item.selectedItem?.value ?? '')
    },
  })

  const otherProps: { onClick?: (e: any) => void } = {}

  if (multiSelect) {
    otherProps.onClick = (e: any) => {
      const clickedElem = e.target
      let elemToCheck = clickedElem
      if (clickedElem.nodeName !== 'LI') elemToCheck = clickedElem.closest('LI')
      const newElem = elemToCheck?.id
      if (newElem) toggleOptionForMulti(newElem)
    }
  }

  const toggleOptionForMulti = (option: string) => {
    let arr = selectedValues
    if (isSelected(option)) {
      const newArr = selectedValues.filter((item) => {
        return item !== option
      })
      arr = newArr
    } else {
      arr = [...arr, option]
    }
    setSelectedValues(arr)
    onChange(arr)
  }

  const isSelected = (option: string) => {
    return !!selectedValues.find((elem) => elem === option)
  }

  return (
    <div
      ref={ref}
      className={classNames([styles.relative, { [styles['relative--shrink']]: shrink }, containerClass])}
      aria-invalid={!!error || hasError}
    >
      <div className={styles.label}>{label && <FormLabel text={label} htmlFor={id} />}</div>
      {/** button */}
      {component ? (
        <div
          type="button"
          {...getToggleButtonProps({
            tabIndex: 0,
          })}
          disabled={disabled}
        >
          {component}
        </div>
      ) : (
        <button
          aria-label={label}
          type="button"
          {...getToggleButtonProps({
            tabIndex: 0,
            className: classNames([styles.selectClass, sizes[size], selectClass, { [styles['selectClass--shrink']]: shrink }]),
          })}
          disabled={disabled}
        >
          <Typography
            aria-label={selectedItem?.title ? 'title-selected' : 'title-placeholder'}
            className={classNames(styles.selectTitle, {
              [styles['selectTitle--disabled']]: disabled,
              [styles['selectTitle--small']]: size === 'small',
              [styles['selectTitle--placeholder']]: !selectedItem?.title && placeholder,
            })}
            typographyType={size === 'medium' ? TypographyType.PARAGRAPH : TypographyType.SMALL_PARAGRAPH}
          >
            {selectedItem?.title || placeholder}
          </Typography>
          {customIcon || (
            <Icon
              name={isOpen ? IconName.ARROW_DROP_UP : IconName.ARROW_DROP_DOWN}
              size={24}
              type={IconType.FILL}
              className={classNames(styles.arrowContainer, { [styles['arrowContainer--disabled']]: disabled })}
            />
          )}
        </button>
      )}
      {/** list items */}
      <ul
        {...getMenuProps({
          className: classNames(
            styles.selectDropdown,
            { [styles.hideDropdown]: !isOpen },
            [styles[`selectDropdown--${position}`]],
            dropdownClass
          ),
        })}
      >
        {search && (
          <div className={styles.searchContainer}>
            <input
              type="search"
              className={styles.searchTitle}
              placeholder={`Buscar ${placeholder ?? ''}...`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        )}
        {filterByValue(options, searchTerm).map((option, index) => (
          <li
            onKeyDown={() => {}}
            key={option.value}
            {...getItemProps({
              item: option,
              index,
              key: option.title as string,
              className: `
                  ${styles.selectOption}
                  ${selectedItem === option ? styles.selected : ''}
                  ${option.description ? styles.withDescription : ''}
                `,
            })}
            {...otherProps}
            id={option.value}
          >
            <div className={styles.titleRow}>
              {multiSelect && (
                <Checkbox label="" name="Incidents" value="Incidents" size="small" onChange={() => {}} checked={isSelected(option.value)} />
              )}
              {option.emoji && <span className={styles.titleEmoji}>{option.emoji}</span>}
              <span className={styles.titleLabel}>{option.title}</span>
            </div>
            {option.description && <div className={styles.optionDescription}>{option.description}</div>}
          </li>
        ))}
      </ul>
      <ErrorInputMessage text={error ?? ''} htmlFor={id} className={styles.errorMessage} />
    </div>
  )
}

export const FormSelect = <TValues extends string = string>(props: SelectProps<TValues>) => {
  const [input, meta, helpers] = useField({
    name: props.id,
  })
  return (
    <Select
      {...props}
      onChange={
        props.onChange ??
        ((v) => {
          helpers.setValue(v)
        })
      }
      value={input.value}
      error={meta.touched && meta.error ? meta.error : ''}
    />
  )
}
