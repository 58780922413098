import { DealflowAdminIndexResponseDto } from 'repositories/http/swagger/schema'
import { BackofficePurchaseBuilder } from '../../builders/BackofficePurchaseBuilder'
import { BackofficePurchase } from '../../model/Purchase'
import { PurchaseStatus } from '../../model/PurchaseStatus'
import { removeStopWords } from '../../utils/removeStopWords'
import { GetAdminPurchases } from '../domain/GetAdminPurchases'

export class GetAdminPurchasesFake implements GetAdminPurchases {
  private readonly purchases: BackofficePurchase[]

  constructor(
    purchases = [
      new BackofficePurchaseBuilder('ref0001')
        .withPropertySlug('casa-azul')
        .withUserName('Pepe')
        .createdAt(new Date(2020, 6, 30))
        .withCheckinDate(new Date(202, 7, 12))
        .withDownpayment(10000)
        .withPricePerMonth(1000)
        .withStatus(PurchaseStatus.STEP_ONE)
        .build(),
      new BackofficePurchaseBuilder('ref0002')
        .withPropertySlug('casa-roja')
        .withUserName('Juan')
        .createdAt(new Date(2020, 6, 31))
        .withCheckinDate(new Date(202, 7, 15))
        .withDownpayment(12000)
        .withPricePerMonth(1200)
        .withStatus(PurchaseStatus.STEP_TWO)
        .build(),
      new BackofficePurchaseBuilder('ref0003')
        .withPropertySlug('casa-verde')
        .withUserName('María')
        .createdAt(new Date(2020, 6, 25))
        .withCheckinDate(new Date(202, 7, 10))
        .withDownpayment(11000)
        .withPricePerMonth(1100)
        .withStatus(PurchaseStatus.AWAIT_APPROVAL_STEP_ONE)
        .build(),
      new BackofficePurchaseBuilder('ref0004')
        .withPropertySlug('casa-morada')
        .withUserName('Rosa')
        .createdAt(new Date(2020, 6, 28))
        .withCheckinDate(new Date(202, 7, 3))
        .withDownpayment(15000)
        .withPricePerMonth(1500)
        .withStatus(PurchaseStatus.AWAIT_APPROVAL_STEP_TWO)
        .build(),
      new BackofficePurchaseBuilder('ref0005')
        .withPropertySlug('casa-rosa')
        .withUserName('Rodrigo')
        .createdAt(new Date(2020, 6, 26))
        .withCheckinDate(new Date(202, 7, 6))
        .withDownpayment(13000)
        .withPricePerMonth(1300)
        .withStatus(PurchaseStatus.APPROVED)
        .build(),
    ]
  ) {
    this.purchases = purchases
  }

  private purchaseToPlainText(purchase: BackofficePurchase): string {
    return Object.values(purchase).join(' ').replace(/,/g, ' ').split('-').join(' ')
  }

  private matchsTextSearch(purchase: BackofficePurchase, searchText: string, language?: 'es' | 'en') {
    const purchaseText = this.purchaseToPlainText(purchase)
    const purchaseWords = removeStopWords(purchaseText.toUpperCase(), language)
    const searchTerms = removeStopWords(searchText.toUpperCase(), language)
    const result = purchaseWords.some((term) => searchTerms.includes(term))
    return result
  }

  async execute(): Promise<DealflowAdminIndexResponseDto> {
    // let purchases = search ? this.purchases.filter((purchase) => this.matchsTextSearch(purchase, search)) : this.purchases
    // if (statusFilter) purchases = purchases.filter((purchase) => purchase.status === statusFilter)
    // const totalCount = purchases.length
    // if (paginate) {
    //   if (paginate.offset && paginate.offset < purchases.length) {
    //     purchases = purchases.slice(paginate.offset)
    //   }
    //   if (paginate.limit < purchases.length) {
    //     purchases = purchases.slice(0, paginate.limit)
    //   }
    // }
    // return { purchases, totalCount }
    throw new Error('method not implemented')
  }
}
