import { UpdatePurchase } from '../domain/UpdatePurchase'
import { RecursivePartial } from '../../model/RecursivePartial'
import { Purchase } from '../../model/Purchase'

export class UpdatePurchaseFake implements UpdatePurchase {
  private spy: (...args: any[]) => void

  constructor(spy: (...args: any[]) => void = () => null) {
    this.spy = spy
  }

  execute(purchaseSlug: string, purchase: RecursivePartial<Purchase>): Promise<void> {
    this.spy(purchaseSlug, purchase)
    return Promise.resolve()
  }
}
