import classNames from 'classnames'
import { FC } from 'react'
import { Colors } from 'types/colors'
import styles from './Text.module.scss'

export type TextType = 'h1' | 'h2' | 'h3' | 'headline' | 'body' | 'small' | 'mini'
export type TextWeight = 'normal' | 'bold'
export type TextAlign = 'left' | 'center' | 'right'
export type TextDecoration = 'underline' | 'none'
export type TextDisplay = 'block' | 'inline' | 'inline-block' | 'none'

const tagFromType = (type: TextType) => {
  switch (type) {
    case 'h1':
      return 'h1'
    case 'h2':
      return 'h2'
    case 'h3':
      return 'h3'
    case 'headline':
      return 'h4'
    case 'body':
      return 'p'
    case 'small':
      return 'small'
    case 'mini':
      return 'small'
    default:
      return 'p'
  }
}

export interface TextProps {
  type?: TextType
  weight?: TextWeight
  align?: TextAlign
  color?: Colors
  textDecoration?: TextDecoration
  display?: TextDisplay
  className?: string
  dangerouslySetInnerHTML?: any
}

export const Text: FC<TextProps> = ({
  type = 'body',
  weight = 'normal',
  align = 'left',
  color = 'blue-600',
  textDecoration = 'none',
  display = 'block',
  className,
  children,
  dangerouslySetInnerHTML,
}) => {
  const Tag = tagFromType(type)
  return (
    <Tag
      className={classNames(
        styles[`text-${type}`],
        styles[`text-${weight}`],
        styles[`text-${align}`],
        styles[`textDecoration-${textDecoration}`],
        styles[`display-${display}`],
        className
      )}
      style={{ color: `var(--${color})` }}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </Tag>
  )
}
