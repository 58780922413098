import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { CreatePurchase } from '../domain/CreatePurchase'

export class CreatePurchaseHttp implements CreatePurchase {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(propertySlug: string): Promise<{ purchaseSlug: string }> {
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/purchases`
    const options = { headers: {}, params: {} }

    const response = await this.httpClient.post<any>(url, { propertySlug }, options)

    return { purchaseSlug: response.purchaseSlug }
  }
}
