import { useMutation, UseMutationOptions } from 'react-query'
import { DealflowUpdateDto } from 'repositories/http/swagger/schema'
import { ApiClient } from 'services/ApiClient'
import { FetchOptions } from 'utils/createApiClient'

type UpdateDealflowParams = { dealflowId: string; data: DealflowUpdateDto }

export const updateDealfow = ({ dealflowId, data }: UpdateDealflowParams, options?: FetchOptions): Promise<void> => {
  return ApiClient.patch<void>(`/admin/dealflows/${dealflowId}`, {
    data,
    ...options,
  })
}

export const useUpdateDealflow = (options?: Omit<UseMutationOptions<void, unknown, UpdateDealflowParams, unknown>, 'mutationFn'>) => {
  return useMutation(
    async (params: UpdateDealflowParams) => {
      return updateDealfow(params)
    },
    {
      ...options,
    }
  )
}
