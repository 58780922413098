export class RepositoryError extends Error {
  private readonly code: string
  readonly sources: any[]

  constructor(code: string, message: string, sources = []) {
    super(message)
    this.code = code
    this.sources = sources
  }

  getCode<T>(): T {
    return this.code as any as T
  }
}
