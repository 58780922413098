import React, { FC } from 'react'
import { LocaleKeys } from '../i18n/keys'

export const TranslationContext = React.createContext<LocaleKeys>(null as any as LocaleKeys)

export enum LocaleTypes {
  ES_ES = 'es-ES',
}

type Locales = {
  [key in LocaleTypes]: LocaleKeys
}

function getLocale(): LocaleTypes | null {
  // TODO: Get the current locale
  return LocaleTypes.ES_ES
}

export const TranslationProvider: FC<{ fallback: LocaleKeys; locales: Locales }> = ({ children, fallback, locales }) => {
  const locale = getLocale()
  const language = locale ? locales[locale] : fallback

  return <TranslationContext.Provider value={language || fallback}>{children}</TranslationContext.Provider>
}
