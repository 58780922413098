import { useField } from 'formik'
import React, { ComponentPropsWithRef, FocusEvent, useEffect, useRef, useState } from 'react'
import { format } from 'date-fns'
import FocusTrap from 'focus-trap-react'
import { ClassNames, DayPicker } from 'react-day-picker'
import { usePopper } from 'react-popper'
import { IconName } from 'components/DataDisplay/Icon/Icon'
import classNames from 'classnames'
import { useTranslation } from 'hooks/useTranslation'
import dateStyles from 'react-day-picker/dist/style.module.css'
import { ErrorInputMessage } from '../ErrorInputMessage/ErrorInputMessage'
import { TextField } from '../TextField/TextField'

import styles from './DateInput.module.scss'

type DateInputBaseProps = {
  className?: string
  label?: string
  name: string
  warningText?: string
  errorMessage?: string
  dateFormat?: string
  selectedDay?: Date
  onDayChange?: (date?: Date) => void
}

export type DateInputProps = DateInputBaseProps & ComponentPropsWithRef<'input'>
const modifiersStyles = {
  today: { color: '#2D445A' },
  selected: { backgroundColor: '#2D445A', color: 'white', borderRadius: '4px', fontWeight: '700', fontFamily: 'Apercu' },
}
const datePickerStyles: ClassNames = {
  ...dateStyles,
  button: styles.customCell,
  head: styles.customHead,
  caption_dropdowns: styles.customCaption,
  caption_label: styles.customCaptionLabel,
}

export function DateInput({
  name,
  label,
  value,
  errorMessage,
  placeholder,
  className,
  onBlur,
  onChange,
}: {
  name: string
  label?: string
  placeholder?: string
  value?: Date
  errorMessage?: string
  className?: string
  onBlur: (e: FocusEvent) => void
  onChange: (date: Date) => void
}) {
  const [defaultValue, setDefaultValue] = useState(value ? format(value, 'dd-MM-yyyy') : undefined)
  const [selected, setSelected] = useState<Date | undefined>(value)
  const [isPopperOpen, setIsPopperOpen] = useState(false)

  const popperRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLInputElement>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-end',
  })

  useEffect(() => {
    setDefaultValue(value ? format(value, 'dd-MM-yyyy') : undefined)
    setSelected(value || undefined)
  }, [value])

  const closePopper = () => {
    setIsPopperOpen(false)
    buttonRef?.current?.focus()
  }

  const handleInputClick = () => {
    setIsPopperOpen(true)
  }

  const handleDaySelect = (date: Date) => {
    setSelected(date)
    onChange(date)
    closePopper()
    if (popperRef?.current) popperRef?.current.blur()
  }

  return (
    <div className={className}>
      <div ref={popperRef} className={classNames(styles.container, styles.overlayWrapper)}>
        <TextField
          placeholder={placeholder || 'DD-MM-YYYY'}
          label={label}
          name={name}
          rightIconName={IconName.CALENDAR2}
          onClick={handleInputClick}
          onBlur={isPopperOpen ? undefined : onBlur}
          value={defaultValue || ''}
          className={styles.input}
          type="text"
        />
        {errorMessage && <ErrorInputMessage text={errorMessage || ''} htmlFor={name} className={styles.errorMessage} />}
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            fallbackFocus: popperRef.current || undefined,
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className={classNames(styles.wrapper, styles.border)}
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              classNames={datePickerStyles}
              captionLayout="dropdown"
              fromYear={1920}
              toYear={2040}
              weekStartsOn={1}
              initialFocus={isPopperOpen}
              defaultMonth={selected}
              selected={selected}
              mode="single"
              onSelect={(value: any) => value && handleDaySelect(value)}
              modifiersStyles={modifiersStyles}
            />
          </div>
        </FocusTrap>
      )}
    </div>
  )
}

export const FormDateInput = (props: DateInputProps) => {
  const [input, meta, helpers] = useField({
    name: props.name,
    value: undefined,
  })
  const { t } = useTranslation()
  const errorMessage = (meta.error as any)?.key ? t((meta.error as any)?.key, { max: format(new Date(), 'dd-MM-yyyy') }) : undefined

  return (
    <DateInput
      {...props}
      onChange={(date: Date) => {
        helpers.setTouched(true)
        if (props.onDayChange) {
          props.onDayChange(date)
        } else {
          helpers.setValue(date)
        }
      }}
      value={input.value}
      errorMessage={meta.touched && meta.error && errorMessage ? errorMessage : ''}
      onBlur={input.onBlur}
      label={props.label || ''}
      name={props.name}
    />
  )
}
