import { HttpClient } from '../../utils/http-client/HttpClient'
import { RejectPurchaseCurrentStep } from '../domain/RejectPurchaseCurrentStep'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'

export class RejectPurchaseCurrentStepHttp implements RejectPurchaseCurrentStep {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(purchaseSlug: string, errorMessage: string): Promise<void> {
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/admin/purchases/${purchaseSlug}/reject`
    const options = { headers: {}, params: {} }
    await this.httpClient.post<any>(url, { errorMessage }, options)
  }
}
