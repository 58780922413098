import { Payment } from '../model/Payment'

export class PaymentBuilder {
  private readonly payment: Payment

  constructor(id = 'aff15ddd-819c-4809-9d93-836044bbf548') {
    this.payment = {
      id,
      amount: 1000,
      documents: [],
    }
  }

  build(): Payment {
    return this.payment
  }
}
