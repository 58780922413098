import { BackofficePropertyDetailBuilder } from '../../builders/BackofficePropertyDetailBuilder'
import { BackofficePropertyDetail } from '../../model/Property'
import { GetAdminPropertyDraft } from '../domain/GetAdminPropertyDraft'

export class GetAdminPropertyDraftFake implements GetAdminPropertyDraft {
  private readonly propertyDetails: BackofficePropertyDetail

  constructor(propertyDetails = new BackofficePropertyDetailBuilder().build()) {
    this.propertyDetails = propertyDetails
  }

  execute(): Promise<BackofficePropertyDetail> {
    return Promise.resolve(this.propertyDetails)
  }
}
