import { ToastProps } from 'components/Toast/Toast'
import React, { useEffect, useState } from 'react'

type Toast = ToastProps | null

const ToastContext = React.createContext<Toast | null | undefined>(undefined)
const ToastUpdaterContext = React.createContext<{ setToast: (toast: Toast) => void } | undefined>(undefined)

export const ToastProvider: React.FC = ({ children }) => {
  const [toast, setToast] = useState<Toast | null>(null) // TODO: useReducer instead, not critical

  useEffect(() => {
    if (!toast) return

    setTimeout(() => setToast(null), toast.duration)
  }, [toast])

  return (
    <ToastContext.Provider value={toast}>
      <ToastUpdaterContext.Provider value={{ setToast }}>{children}</ToastUpdaterContext.Provider>
    </ToastContext.Provider>
  )
}

export function useToastProvider() {
  const context = React.useContext(ToastContext)

  if (typeof context === 'undefined') {
    throw new Error('useToastProvider must be used within a ToastProvider')
  }

  return context
}

export function useToastUpdater() {
  const toastUpdater = React.useContext(ToastUpdaterContext)

  if (typeof toastUpdater === 'undefined') {
    throw new Error('useToastUpdater must be used within a ToastProvider')
  }

  return toastUpdater
}

type ToastContextType = {
  toast: Toast | null
  setToast: (toast: Toast) => void
}
export const useToast = (): ToastContextType => ({
  toast: useToastProvider(),
  ...useToastUpdater(),
})
