import { GetSmarthouser } from 'repositories/domain/GetSmarthouser'
import { CreatePurchase } from '../domain/CreatePurchase'
import { DeleteAdminPropertyDraft } from '../domain/DeleteAdminPropertyDraft'
import { GeocodeAddress } from '../domain/GeocodeAddress'
import { GetAdminProperties } from '../domain/GetAdminProperties'
import { GetAdminProperty } from '../domain/GetAdminProperty'
import { GetAdminPropertyDraft } from '../domain/GetAdminPropertyDraft'
import { GetAdminPurchases } from '../domain/GetAdminPurchases'
import { GetProperties } from '../domain/GetProperties'
import { GetPropertyDetails } from '../domain/GetPropertyDetails'
import { GetPurchase } from '../domain/GetPurchase'
import { GetUser } from '../domain/GetUser'
import { Login } from '../domain/Login'
import { PublishAdminPropertyDraft } from '../domain/PublishAdminPropertyDraft'
import { Repositories } from '../domain/Repositories'
import { UnpublishAdminProperty } from '../domain/UnpublishAdminProperty'
import { UpdateAdminPropertyDraft } from '../domain/UpdateAdminPropertyDraft'
import { UpdatePaymentReceipt } from '../domain/UpdatePaymentReceipt'
import { UpdatePropertyDraftImages } from '../domain/UpdatePropertyDraftImages'
import { UpdatePurchase } from '../domain/UpdatePurchase'
import { UpdatePurchaseFiles } from '../domain/UpdatePurchaseFiles'
import { CreatePropertyDraftFake } from './CreatePropertyDraftFake'
import { CreatePropertyDraftsUploadFake } from './CreatePropertyDraftsUploadFake'
import { CreatePurchaseFake } from './CreatePurchaseFake'
import { DeleteAdminPropertyDraftFake } from './DeleteAdminPropertyDraftFake'
import { GeocodeAddressFake } from './GeocodeAddressFake'
import { GetAdminPropertiesFake } from './GetAdminPropertiesFake'
import { GetAdminPropertyDraftFake } from './GetAdminPropertyDraftFake'
import { GetAdminPropertyFake } from './GetAdminPropertyFake'
import { GetAdminPurchasesFake } from './GetAdminPurchasesFake'
import { GetPropertiesFake } from './GetPropertiesFake'
import { GetPropertyDetailsFake } from './GetPropertiyDetailsFake'
import { GetPurchaseAdminFake } from './GetPurchaseAdminFake'
import { GetPurchaseFake } from './GetPurchaseFake'
import { GetUserFake } from './GetUserFake'
import { LoginFake } from './LoginFake'
import { PublishAdminPropertyDraftFake } from './PublishAdminPropertyDraftFake'
import { UnpublishAdminPropertyFake } from './UnpublishAdminPropertyFake'
import { UpdateAdminPropertyDraftFake } from './UpdateAdminPropertyDraftFake'
import { UpdatePaymentReceiptFake } from './UpdatePaymentReceiptFake'
import { UpdatePropertyDraftImagesFake } from './UpdatePropertyDraftImagesFake'
import { UpdatePurchaseFake } from './UpdatePurchaseFake'
import { UpdatePurchaseFilesFake } from './UpdatePurchaseFilesFake'
import { GetPurchaseAdmin } from '../domain/GetPurchaseAdmin'
import { ValidatePurchaseCurrentStep } from '../domain/ValidatePurchaseCurrentStep'
import { ValidatePurchaseCurrentStepFake } from './ValidatePurchaseCurrentStepFake'
import { RejectPurchaseCurrentStep } from '../domain/RejectPurchaseCurrentStep'
import { RejectPurchaseCurrentStepFake } from './RejectPurchaseCurrentStepFake'
import { DownloadPurchaseDocumentation } from '../domain/DownloadPurchaseDocumentation'
import { DownloadPurchaseDocumentationFake } from './DownloadPurchaseDocumentationFake'
import { ContinuePurchase } from '../domain/ContinuePurchase'
import { ContinuePurchaseFake } from './ContinuePurchaseFake'
import { FinishPurchase } from '../domain/FinishPurchase'
import { FinishPurchaseFake } from './FinishPurchaseFake'
import { CancelPurchase } from '../domain/CancelPurchase'
import { CancelPurchaseFake } from './CancelPurchaseFake'
import { GetNeighborhoods } from '../domain/GetNeighborhoods'
import { GetNeighborhoodsFake } from './GetNeighborhoodsFake'
import { GetCitiesFake } from './GetCitiesFake'
import { GetCities } from '../domain/GetCities'
import { GetAdminCitiesFake } from './GetAdminCitiesFake'
import { GetAdminCities } from '../domain/GetAdminCities'
import { GetSmarthouserFake } from './GetSmarthouserFake'

export class RepositoriesFake implements Repositories {
  readonly getAdminProperties: GetAdminProperties['execute']

  readonly getProperties: GetProperties['execute']

  readonly getPropertyDetails: GetPropertyDetails['execute']

  readonly login: Login['execute']

  readonly createPropertyDraft: CreatePropertyDraftFake['execute']

  readonly createPropertyDraftsUpload: CreatePropertyDraftsUploadFake['execute']

  readonly getAdminPropertyDraft: GetAdminPropertyDraft['execute']

  readonly updateAdminProperyDraft: UpdateAdminPropertyDraft['execute']

  readonly publishAdminPropertyDraft: PublishAdminPropertyDraft['execute']

  readonly deleteAdminPropertyDraft: DeleteAdminPropertyDraft['execute']

  readonly getAdminProperty: GetAdminProperty['execute']

  readonly unpublishAdminProperty: UnpublishAdminProperty['execute']

  readonly updateAdminProperyDraftImages: UpdatePropertyDraftImages['execute']

  readonly geocodeAddress: GeocodeAddress['execute']

  readonly getUser: GetUser['execute']

  readonly createPurchase: CreatePurchase['execute']

  readonly getPurchase: GetPurchase['execute']

  readonly updatePurchase: UpdatePurchase['execute']

  readonly updatePurchaseFiles: UpdatePurchaseFiles['execute']

  readonly updatePaymentReceipt: UpdatePaymentReceipt['execute']

  readonly getAdminPurchases: GetAdminPurchases['execute']

  readonly getPurchaseAdmin: GetPurchaseAdmin['execute']

  readonly validatePurchaseCurrentStep: ValidatePurchaseCurrentStep['execute']

  readonly rejectPurchaseCurrentStep: RejectPurchaseCurrentStep['execute']

  readonly downloadPurchaseDocumentation: DownloadPurchaseDocumentation['execute']

  readonly continuePurchase: ContinuePurchase['execute']

  readonly finishPurchase: FinishPurchase['execute']

  readonly cancelPurchase: CancelPurchase['execute']

  readonly getNeighborhoods: GetNeighborhoods['execute']

  readonly getCities: GetCities['execute']

  readonly getAdminCities: GetAdminCities['execute']

  readonly getSmarthouser: GetSmarthouser['execute']

  constructor({
    getAdminProperties = new GetAdminPropertiesFake(),
    getProperties = new GetPropertiesFake(),
    getPropertyDetails = new GetPropertyDetailsFake(),
    login = new LoginFake(),
    createPropertyDraft = new CreatePropertyDraftFake(),
    createPropertyDraftsUpload = new CreatePropertyDraftsUploadFake(),
    getAdminPropertyDraft = new GetAdminPropertyDraftFake(),
    updateAdminProperyDraft = new UpdateAdminPropertyDraftFake(),
    publishAdminPropertyDraft = new PublishAdminPropertyDraftFake(),
    deleteAdminPropertyDraft = new DeleteAdminPropertyDraftFake(),
    getAdminProperty = new GetAdminPropertyFake(),
    unpublishAdminProperty = new UnpublishAdminPropertyFake(),
    updateAdminProperyDraftImages = new UpdatePropertyDraftImagesFake(),
    geocodeAddress = new GeocodeAddressFake(),
    getUser = new GetUserFake(),
    createPurchase = new CreatePurchaseFake(),
    getPurchase = new GetPurchaseFake(),
    updatePurchase = new UpdatePurchaseFake(),
    updatePurchaseFiles = new UpdatePurchaseFilesFake(),
    updatePaymentReceipt = new UpdatePaymentReceiptFake(),
    getAdminPurchases = new GetAdminPurchasesFake(),
    getPurchaseAdmin = new GetPurchaseAdminFake(),
    validatePurchaseCurrentStep = new ValidatePurchaseCurrentStepFake(),
    rejectPurchaseCurrentStep = new RejectPurchaseCurrentStepFake(),
    downloadPurchaseDocumentation = new DownloadPurchaseDocumentationFake(),
    continuePurchase = new ContinuePurchaseFake(),
    finishPurchase = new FinishPurchaseFake(),
    cancelPurchase = new CancelPurchaseFake(),
    getNeighborhoods = new GetNeighborhoodsFake(),
    getCities = new GetCitiesFake(),
    getAdminCities = new GetAdminCitiesFake(),
    getSmarthouser = new GetSmarthouserFake(),
  } = {}) {
    this.getAdminProperties = getAdminProperties.execute.bind(getAdminProperties)
    this.getProperties = getProperties.execute.bind(getProperties)
    this.getPropertyDetails = getPropertyDetails.execute.bind(getPropertyDetails)
    this.login = login.execute.bind(login)
    this.createPropertyDraft = createPropertyDraft.execute.bind(createPropertyDraft)
    this.createPropertyDraftsUpload = createPropertyDraftsUpload.execute.bind(createPropertyDraftsUpload)
    this.updateAdminProperyDraft = updateAdminProperyDraft.execute.bind(updateAdminProperyDraft)
    this.deleteAdminPropertyDraft = deleteAdminPropertyDraft.execute.bind(deleteAdminPropertyDraft)
    this.getAdminPropertyDraft = getAdminPropertyDraft.execute.bind(getAdminPropertyDraft)
    this.updateAdminProperyDraft = updateAdminProperyDraft.execute.bind(updateAdminProperyDraft)
    this.publishAdminPropertyDraft = publishAdminPropertyDraft.execute.bind(publishAdminPropertyDraft)
    this.deleteAdminPropertyDraft = deleteAdminPropertyDraft.execute.bind(deleteAdminPropertyDraft)
    this.getAdminProperty = getAdminProperty.execute.bind(getAdminProperty)
    this.unpublishAdminProperty = unpublishAdminProperty.execute.bind(unpublishAdminProperty)
    this.updateAdminProperyDraftImages = updateAdminProperyDraftImages.execute.bind(updateAdminProperyDraftImages)
    this.geocodeAddress = geocodeAddress.execute.bind(geocodeAddress)
    this.getUser = getUser.execute.bind(getUser)
    this.createPurchase = createPurchase.execute.bind(createPurchase)
    this.getPurchase = getPurchase.execute.bind(getPurchase)
    this.updatePurchase = updatePurchase.execute.bind(updatePurchase)
    this.updatePurchaseFiles = updatePurchaseFiles.execute.bind(updatePurchaseFiles)
    this.updatePaymentReceipt = updatePaymentReceipt.execute.bind(updatePaymentReceipt)
    this.getAdminPurchases = getAdminPurchases.execute.bind(getAdminPurchases)
    this.getPurchaseAdmin = getPurchaseAdmin.execute.bind(getPurchaseAdmin)
    this.validatePurchaseCurrentStep = validatePurchaseCurrentStep.execute.bind(validatePurchaseCurrentStep)
    this.rejectPurchaseCurrentStep = rejectPurchaseCurrentStep.execute.bind(rejectPurchaseCurrentStep)
    this.downloadPurchaseDocumentation = downloadPurchaseDocumentation.execute.bind(downloadPurchaseDocumentation)
    this.continuePurchase = continuePurchase.execute.bind(continuePurchase)
    this.finishPurchase = finishPurchase.execute.bind(finishPurchase)
    this.cancelPurchase = cancelPurchase.execute.bind(cancelPurchase)
    this.getNeighborhoods = getNeighborhoods.execute.bind(getNeighborhoods)
    this.getCities = getCities.execute.bind(getCities)
    this.getAdminCities = getAdminCities.execute.bind(getAdminCities)
    this.getSmarthouser = getSmarthouser.execute.bind(getSmarthouser)
  }
}
