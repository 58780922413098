import { MarketplaceProperty } from '../model/Property'
import { PropertyStatus } from '../model/PropertyStatus'
import { PropertyTag } from '../model/PropertyTag'

export class MarketplacePropertyBuilder {
  private readonly property: MarketplaceProperty

  static VERTICAL_IMAGE = 'https://i.imgur.com/c4xYvbL.jpg'

  constructor(slug = Math.random().toString()) {
    this.property = {
      id: '',
      slug,
      images: ['https://s3-eu-west-1.amazonaws.com/squareclockdata/wp-content/uploads/2018/07/18145134/shutterstock_729468436.jpg'],
      status: PropertyStatus.AVAILABLE,
      isFavorite: false,
      address: {
        city: 'Madrid',
        neighborhood: 'Chamberi',
      },
      title: 'Calle Vallehermoso 48',
      pricePerMonth: 1300.5,
      monthlySavings: 200.5,
      downPayment: 15927.5,
      entryPayment: 15927.5,
      surface: 59,
      bedroomsAmount: 2,
      bathroomsAmount: 2,
      tags: [PropertyTag.SUNNY, PropertyTag.PETS_ALLOWED, PropertyTag.SHOPPING_AREA, PropertyTag.RESTAURANTS_NEAR, PropertyTag.PUBLIC_TRANSPORT],
      tryBeforeYouBuyIt: true,
      wholeRentalForBuying: true,
      yearPlan: undefined,
      specialOffer: undefined,
      latitude: 40.4167,
      longitude: -3.7033,
    }
  }

  withSlug(slug: string) {
    this.property.slug = slug
    return this
  }

  withTitle(title: string) {
    this.property.title = title
    return this
  }

  withPricePerMonth(pricePerMonth: number) {
    this.property.pricePerMonth = pricePerMonth
    return this
  }

  withStatus(status: PropertyStatus) {
    this.property.status = status
    return this
  }

  withTags(tags: PropertyTag[]) {
    this.property.tags = tags
    return this
  }

  markAsFavorite() {
    this.property.isFavorite = true
    return this
  }

  withMainImages(images: string[]) {
    this.property.images = images
    return this
  }

  withCity(city: string) {
    this.property.address.city = city
    return this
  }

  withNeighborhood(neighborhood: string) {
    this.property.address.neighborhood = neighborhood
    return this
  }

  withSurface(surface: number) {
    this.property.surface = surface
    return this
  }

  withBedrooms(bedrooms: number) {
    this.property.bedroomsAmount = bedrooms
    return this
  }

  withBathrooms(bathrooms: number) {
    this.property.bathroomsAmount = bathrooms
    return this
  }

  withEntryPayment(entryPayment: number) {
    this.property.downPayment = entryPayment
    return this
  }

  build(): MarketplaceProperty {
    return this.property
  }
}
