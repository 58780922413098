import { DocumentationIdentity } from 'model/Documentation'
import { FC } from 'react'
import { getDocumentUrl, getLabelFromIdentityDocument } from 'utils/dealflowDetailUtils'
import { translateIdentityDocument } from 'utils/translateIdentityDocument'
import { useTranslation } from 'hooks/useTranslation'
import { Text } from 'components/Text/Text'
import { Button } from 'components/Button/Button'
import { IconName } from 'components/DataDisplay/Icon/Icon'
import { LabeledText } from '../LabeledText/LabeledText'
import { useTranslation as useTranslationDeprecated } from '../../../../hooks/useTranslationDeprecated'
import styles from './IdentityDocumentation.module.scss'

export const IdentityDocumentation: FC<{
  identityDocumentation: DocumentationIdentity
  purchaseSlug: string
}> = ({ identityDocumentation, purchaseSlug }) => {
  const { t } = useTranslationDeprecated()
  const { t: translation } = useTranslation()

  return (
    <div className={styles.container}>
      <Text type="headline" weight="bold" className={styles.ownerDataTitle}>
        {translation('admin:dealflowDetail.ownerLabels.identificationDocument')}
      </Text>

      <LabeledText className={styles.spaceBottom} field="Documentation type" value={translateIdentityDocument(t, identityDocumentation.type)} />

      <LabeledText className={styles.spaceBottom} field="Documentation number" value={identityDocumentation?.serialNumber ?? 'No disponible'} />

      <Text type="small" weight="bold">
        Images of the ID card
      </Text>

      {identityDocumentation.documents.reverse().map((document, index: number) => (
        <div className={styles.documentButtonsContainer} key={document.id}>
          <Button
            icon={index === 0 ? IconName.BRING_TO_FRONT : IconName.SEND_TO_BACK}
            variant="tertiary"
            size="small"
            onClick={() => {
              return window.open(getDocumentUrl(purchaseSlug, document.id), '_blank')
            }}
          >
            {getLabelFromIdentityDocument(index, t, identityDocumentation.type)}
          </Button>
        </div>
      ))}
    </div>
  )
}
