import classNames from 'classnames'
import { useField } from 'formik'
import React, { ComponentPropsWithRef, FC, useState } from 'react'
import { Icon, IconName, IconType } from '../../DataDisplay/Icon/Icon'
import { ErrorInputMessage } from '../ErrorInputMessage/ErrorInputMessage'
import { FormLabel } from '../Label/FormLabel'
import styles from './TextField.module.scss'

export type InputFieldBaseProps = {
  label?: string
  name: string
  type: string
  errorMessage?: string
  leftIconName?: IconName
  onButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  rightIconName?: IconName
  iconType?: IconType
  warningText?: string
  width?: 'fit' | 'expand' | 'auto'
  register?: boolean
  hasError?: boolean
}

export type InputFieldProps = InputFieldBaseProps & ComponentPropsWithRef<'input'>

const PasswordEyeButton: FC<{
  passwordVisible: boolean
  setPasswordVisible: () => void
}> = ({ passwordVisible, setPasswordVisible }) => (
  <Icon
    onClick={setPasswordVisible}
    className={classNames(styles.icon, styles.eyeButton)}
    name={passwordVisible ? IconName.EYE : IconName.EYE_CLOSE}
    size={24}
  />
)

/**
 * @deprecated use FormTextField instead.
 */
export const TextField: FC<InputFieldProps> = ({
  label,
  className,
  type,
  name,
  errorMessage,
  leftIconName,
  onButtonClick,
  rightIconName,
  iconType,
  disabled,
  readOnly,
  warningText,
  hasError = false,
  width = 'expand',
  ...props
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false)

  return (
    <div className={classNames(styles.container, styles[`container--${width}`], className)}>
      <div className={styles.label}>{label && <FormLabel text={label} htmlFor={name} warningText={warningText} />}</div>
      <div className={classNames(styles.inputContainer, (hasError || errorMessage) && styles.error)}>
        <input
          id={name}
          name={name}
          className={classNames(styles.input, leftIconName && styles.leftIconPadding, rightIconName && styles.rightIconPadding)}
          type={getInputType(type, passwordVisible)}
          disabled={disabled}
          readOnly={readOnly}
          data-testid="text-input"
          onWheel={type === 'number' ? (event) => event.currentTarget.blur() : undefined}
          {...props}
        />
        {type === 'password' && (
          <PasswordEyeButton passwordVisible={passwordVisible} setPasswordVisible={() => !disabled && setPasswordVisible(!passwordVisible)} />
        )}
        {leftIconName &&
          (onButtonClick ? (
            <button
              type="button"
              className={classNames(styles.button, styles.left)}
              data-testid="input-button"
              onClick={onButtonClick}
              disabled={disabled}
            >
              <Icon name={leftIconName} className={styles.iconButton} />
            </button>
          ) : (
            <Icon name={leftIconName} className={classNames(styles.icon, styles.left)} />
          ))}
        {rightIconName &&
          (onButtonClick ? (
            <button
              type="button"
              className={classNames(styles.button, styles.right)}
              data-testid="input-button"
              onClick={onButtonClick}
              disabled={disabled}
            >
              <Icon name={rightIconName} type={iconType} className={styles.iconButton} />
            </button>
          ) : (
            <Icon name={rightIconName} type={iconType} className={classNames(styles.icon, styles.right)} />
          ))}
      </div>
      <ErrorInputMessage text={errorMessage || ''} htmlFor={name} className={styles.errorMessage} />
    </div>
  )
}

function getInputType(type: string, passwordVisible: boolean) {
  if (type === 'password') {
    return passwordVisible ? 'text' : type
  }

  return type
}

export const FormTextField = (props: InputFieldProps) => {
  const [input, meta] = useField({
    name: props.name,
  })

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(e)
    } else {
      input.onChange(e)
    }
  }

  return (
    <TextField
      {...props}
      onChange={onChange}
      onBlur={props.onBlur || input.onBlur}
      value={input.value}
      errorMessage={meta.touched && meta.error ? meta.error : ''}
    />
  )
}
