import React, { FC } from 'react'
import classNames from 'classnames'
import { TypographyType, Typography } from '../../DataDisplay/Typography/Typography'

import styles from './FormLabel.module.scss'

type FormLabelProps = {
  text: string
  className?: string
  htmlFor: string
  warningText?: string
}

export const FormLabel: FC<FormLabelProps> = ({ text, className, htmlFor, warningText = '' }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <Typography typographyType={TypographyType.SMALL_PARAGRAPH} type="label" htmlFor={htmlFor}>
        <strong>{text}</strong>
      </Typography>
      {warningText && (
        <Typography typographyType={TypographyType.SMALL_PARAGRAPH} className={styles.warningTextStyle} type="label" htmlFor={htmlFor}>
          {warningText}
        </Typography>
      )}
    </div>
  )
}
