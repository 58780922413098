import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { Login } from '../domain/Login'
import { AdminJwtCreateDto } from './swagger/schema'

export class LoginHttp implements Login {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(email: string, password: string): Promise<void> {
    await this.httpClient.post<AdminJwtCreateDto>(`${getPublicRuntimeConfig().API_ENDPOINT}/admin/login`, {
      email,
      password,
    })
  }
}
