import { Admin } from 'model/Admin'
import { User, AuthRoleType } from 'model/User'
import { useQuery, UseQueryOptions } from 'react-query'
import { AdminResponseDto, UserResponseDto } from 'repositories/http/swagger/schema'
import { ApiClient } from 'services/ApiClient'
import { ApiRoutes } from 'services/router/ApiRoutes'
import { Api } from 'types/Api'
import { FetchOptions } from 'utils/createApiClient'
import { userKeys } from './keys'

export namespace FetchMe {
  export type Response = User | Admin
  export type Error = Api.Error
  export type Options = UseQueryOptions<Response, Error>
}

export const fetchMe = (options?: FetchOptions): Promise<User | Admin> => {
  return ApiClient.get<UserResponseDto>(ApiRoutes.me(), options).then((res) => mapResponseToModel(res))
}

export const useMe = () => {
  const { data } = useQuery<FetchMe.Response, FetchMe.Error>(userKeys.me(), {
    enabled: false,
    keepPreviousData: false,
  })
  return data ?? null
}

const mapResponseToModel = (response: UserResponseDto | AdminResponseDto): User | Admin => {
  if (isAdmin(response)) {
    return {
      slug: response.slug,
      role: response.role as AuthRoleType.ADMIN,
      email: response.email,
    }
  }

  return {
    email: response.email,
    role: response.role as AuthRoleType.USER,
    favorites: [], // TODO: favorite response when api is implemented
    name: response.name ?? null,
    phone: response.phone ?? null,
    slug: response.id,
    surname: response.surname ?? null,
    purchaseSlug: null,
  }
}

function isAdmin(response: UserResponseDto | AdminResponseDto): response is AdminResponseDto {
  return response.role === AuthRoleType.ADMIN
}
