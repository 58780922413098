/* eslint-disable camelcase */
import * as stopword from 'stopword'

/*
 * stopword en su versión v2 cambió el código de idioma
 * al estandard iso-639-3 (3 letras en minúsculas)
 */

export function removeStopWords(text: string, language?: 'es' | 'en'): string[] {
  const lang = language || 'es'
  const iso639_3 = lang === 'es' ? 'spa' : 'eng'
  return stopword.removeStopwords(text.split(' '), stopword[iso639_3])
}
