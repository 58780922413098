import classNames from 'classnames'
import { Text } from 'components/Text/Text'
import React, { FC } from 'react'
import styles from './LabeledText.module.scss'

export type LabeledTextProps = {
  field: string
  value?: string
  type?: LabeledTextWith
  url?: string
  className?: string
  onClick?: () => void
}

export enum LabeledTextWith {
  NOTHING = 'NOTHING',
  URL = 'URL',
  FILE = 'FILE',
}

export const LabeledText: FC<LabeledTextProps> = ({ field, value, type = LabeledTextWith.NOTHING, url, className, onClick }) => {
  function getAReferenceWithOnClick() {
    return (
      <a
        href={url}
        onClick={(event) => {
          if (onClick) {
            event.preventDefault()
            onClick()
          }
        }}
      >
        {value}
      </a>
    )
  }

  function getElementByType(labeledTextWith: LabeledTextWith): JSX.Element {
    const tag: Record<LabeledTextWith, JSX.Element> = {
      [LabeledTextWith.NOTHING]: <span>{value}</span>,
      [LabeledTextWith.URL]: <a href={url}>{value} </a>,
      [LabeledTextWith.FILE]: getAReferenceWithOnClick(),
    }

    return tag[labeledTextWith]
  }

  return (
    <div className={classNames(styles.container, className)}>
      <Text type="small" weight="bold" className={styles.title}>
        {field}
      </Text>
      {value && (
        <Text type="body" className={styles.data}>
          {getElementByType(type)}
        </Text>
      )}
    </div>
  )
}
