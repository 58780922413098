/* eslint-disable no-continue */
import {
  AllDocumentsType,
  Documentation,
  DocumentationEmployment,
  DocumentationIdentity,
  documentationIsEmployment,
  documentationIsIdentity,
  EmploymentTypes,
  IdentityTypes,
} from 'model/Documentation'
import { getPublicRuntimeConfig } from 'services/config/publicRuntimeConfig'
import { LocaleKeys } from '../i18n/keys'

function getPayrollLabel(index: number) {
  return `Payroll ${index + 1}`
}

function getDniNieLabel(index: number) {
  return index === 0 ? 'Frontal image' : 'Back image'
}

export function getLabelFromIdentityDocument(index: number, t: LocaleKeys, document: IdentityTypes): string {
  const translation: Record<IdentityTypes, string> = {
    [IdentityTypes.DNI]: getDniNieLabel(index),
    [IdentityTypes.PASSPORT]: t.admin.dealflowDetail.ownerLabels.passport,
    [IdentityTypes.NIE]: getDniNieLabel(index),
    [IdentityTypes.DRIVING_LICENSE]: t.admin.dealflowDetail.ownerLabels.driver_license,
  }

  return translation[document]
}

export function getLabelFromEmploymentDocumentation(index: number, t: LocaleKeys, document: EmploymentTypes): string {
  const translation: Record<EmploymentTypes, string> = {
    [EmploymentTypes.PAYROLL]: getPayrollLabel(index),
    [EmploymentTypes.TAXES]: t.admin.dealflowDetail.ownerLabels.taxes,
    [EmploymentTypes.SOCIAL_INSURANCE_RECEIPT]: getPayrollLabel(index),
    [EmploymentTypes.INCOME_CERTIFICATE]: t.admin.dealflowDetail.ownerLabels.incomeCertificate,
    [EmploymentTypes.WORK_TRAJECTORY_REPORT]: t.admin.dealflowDetail.ownerLabels.workTrajectory,
  }

  return translation[document]
}

// get last document for each type (id: NIE, PASSPORT, NIE) or (WorkSituation)
export function findDocumentType(documentationArr: Documentation[]): [DocumentationIdentity | null, DocumentationEmployment | null] {
  let identity: DocumentationIdentity | null = null
  let workSituation: DocumentationEmployment | null = null
  for (const document of documentationArr) {
    if (documentationIsIdentity(document)) {
      identity = document
    } else if (documentationIsEmployment(document)) {
      workSituation = document
    }
  }

  return [identity, workSituation]
}

export function getDocumentUrl(purchaseSlug: string, documentSlug: string) {
  return `${getPublicRuntimeConfig().API_ENDPOINT}/admin/dealflows/${purchaseSlug}/files/${documentSlug}`
}

export function getPublicDocumentUrl(documentSlug: string) {
  return `${getPublicRuntimeConfig().API_ENDPOINT}/dealflow/files/${documentSlug}`
}

export function getLastDocumentIdByType(
  type: 'signedContract' | 'financialCheck' | 'contract' | 'legal' | 'technicalInspection' | 'deeds' | 'keyReceipt',
  documentList: AllDocumentsType[]
) {
  if (!documentList) return ''
  let lastDocument: AllDocumentsType | undefined
  for (const document of documentList) {
    if (document.type !== type) continue
    if (lastDocument === undefined) {
      lastDocument = document
      continue
    }
    if (new Date(document.createdAt) > new Date(lastDocument.createdAt)) lastDocument = document
  }
  return lastDocument?.document.id || ''
}
