import { DealflowReservationStep } from 'containers/admin/components/DealflowReservationStep/DealflowReservationStep'
import { TranslationKeys } from 'i18n/locales/translationKeys'
import { DealflowOfferStep } from 'containers/admin/components/DealflowOfferStep/DealflowOfferStep'
import { DealflowSignStep } from 'containers/admin/components/DealflowSignStep/DealflowSignStep'
import { DealflowBuyStep } from 'containers/admin/components/DealflowBuyStep/DealflowBuyStep'
import { DealflowKeyDeliveryStep } from 'containers/admin/components/DealflowKeyDeliveryStep/DealflowKeyDeliveryStep'

export enum DealflowStatusEnum {
  PENDING_USER_DATA = 'PENDING_USER_DATA',
  CLOSED_BY_OTHER_DEALFLOW = 'CLOSED_BY_OTHER_DEALFLOW',
  POSSIBLE_REFOUND_PENDING = 'POSSIBLE_REFOUND_PENDING',
  REFOUND_DONE = 'REFOUND_DONE',
  CANCEL_BY_USER = 'CANCEL_BY_USER',
  CHECKING_USER_DATA = 'CHECKING_USER_DATA',
  USER_REJECTED = 'USER_REJECTED',
  FINANCIAL_CHECK = 'FINANCIAL_CHECK',
  FINANCIAL_CHECK_REJECTED = 'FINANCIAL_CHECK_REJECTED',
  MAKING_OFFER_TO_OWNER = 'MAKING_OFFER_TO_OWNER',
  OWNER_OFFER_REJECTED = 'OWNER_OFFER_REJECTED',
  SIGN_AND_PAY = 'SIGN_AND_PAY',
  SIGN_AND_PAY_FAILED = 'SIGN_AND_PAY_FAILED',
  BUYING_HOUSE = 'BUYING_HOUSE',
  BUYING_HOUSE_FAILED = 'BUYING_HOUSE_FAILED',
  LEGAL = 'LEGAL',
  REJECTED_LEGAL = 'REJECTED_LEGAL',
  QA = 'QA',
  REJECTED_QA = 'REJECTED_QA',
  CLOSING_PURCHASE = 'CLOSING_PURCHASE',
  CLOSING_PURCHASE_REJECTED = 'CLOSING_PURCHASE_REJECTED',
  KEY_DELIVERY = 'KEY_DELIVERY',
  KEY_DELIVERY_REJECTED = 'KEY_DELIVERY_REJECTED',
  NEW_SMARTHOUSER = 'NEW_SMARTHOUSER',
}

export const DealflowStatusArray = [
  'PENDING_USER_DATA',
  'CLOSED_BY_OTHER_DEALFLOW',
  'POSSIBLE_REFOUND_PENDING',
  'REFOUND_DONE',
  'CANCEL_BY_USER',
  'CHECKING_USER_DATA',
  'USER_REJECTED',
  'FINANCIAL_CHECK',
  'FINANCIAL_CHECK_REJECTED',
  'MAKING_OFFER_TO_OWNER',
  'OWNER_OFFER_REJECTED',
  'SIGN_AND_PAY',
  'SIGN_AND_PAY_FAILED',
  'BUYING_HOUSE',
  'BUYING_HOUSE_FAILED',
  'LEGAL',
  'REJECTED_LEGAL',
  'QA',
  'REJECTED_QA',
  'CLOSING_PURCHASE',
  'CLOSING_PURCHASE_REJECTED',
  'KEY_DELIVERY',
  'KEY_DELIVERY_REJECTED',
  'NEW_SMARTHOUSER',
]

export const isDealflowStatusCompleted = (currentStatus: DealflowStatus, statusToCompare: DealflowStatus) =>
  DealflowStatusArray.indexOf(currentStatus) > DealflowStatusArray.indexOf(statusToCompare)

export type DealflowStatus = keyof typeof DealflowStatusEnum

export const dealflowSteps = ['RESERVATION', 'OWNER_OFFER', 'CONTRACT_SIGN_AND_PAYMENT', 'HOME_BUY', 'KEY_DELIVERY'] as const

export type DealflowStep = (typeof dealflowSteps)[number]

export const DealflowStatusToStep: { [x: string]: DealflowStep } = {
  PENDING_USER_DATA: 'RESERVATION',
  CLOSED_BY_OTHER_DEALFLOW: 'RESERVATION',
  POSSIBLE_REFOUND_PENDING: 'RESERVATION',
  REFOUND_DONE: 'RESERVATION',
  CANCEL_BY_USER: 'RESERVATION',
  CHECKING_USER_DATA: 'RESERVATION',
  USER_REJECTED: 'RESERVATION',
  FINANCIAL_CHECK: 'OWNER_OFFER',
  FINANCIAL_CHECK_REJECTED: 'OWNER_OFFER',
  MAKING_OFFER_TO_OWNER: 'OWNER_OFFER',
  OWNER_OFFER_REJECTED: 'OWNER_OFFER',
  SIGN_AND_PAY: 'CONTRACT_SIGN_AND_PAYMENT',
  SIGN_AND_PAY_FAILED: 'CONTRACT_SIGN_AND_PAYMENT',
  BUYING_HOUSE: 'CONTRACT_SIGN_AND_PAYMENT',
  BUYING_HOUSE_FAILED: 'CONTRACT_SIGN_AND_PAYMENT',
  LEGAL: 'HOME_BUY',
  REJECTED_LEGAL: 'HOME_BUY',
  QA: 'HOME_BUY',
  REJECTED_QA: 'HOME_BUY',
  CLOSING_PURCHASE: 'HOME_BUY',
  CLOSING_PURCHASE_REJECTED: 'HOME_BUY',
  KEY_DELIVERY: 'KEY_DELIVERY',
  KEY_DELIVERY_REJECTED: 'KEY_DELIVERY',
  NEW_SMARTHOUSER: 'KEY_DELIVERY',
}

export const UserDealflowStatusToStep: { [x: string]: DealflowStep } = {
  PENDING_USER_DATA: 'RESERVATION',
  CHECKING_USER_DATA: 'RESERVATION',
  FINANCIAL_CHECK: 'OWNER_OFFER',
  MAKING_OFFER_TO_OWNER: 'OWNER_OFFER',
  SIGN_AND_PAY: 'OWNER_OFFER',
  BUYING_HOUSE: 'CONTRACT_SIGN_AND_PAYMENT',
  LEGAL: 'HOME_BUY',
  QA: 'HOME_BUY',
  CLOSING_PURCHASE: 'HOME_BUY',
  KEY_DELIVERY: 'KEY_DELIVERY',
}

export const DealflowStepTranslationsKeys: { [x: string]: TranslationKeys } = {
  RESERVATION: 'admin:dealflowDetail.dealflowStep.reservation',
  OWNER_OFFER: 'admin:dealflowDetail.dealflowStep.ownerOffer',
  CONTRACT_SIGN_AND_PAYMENT: 'admin:dealflowDetail.dealflowStep.contractSignAndPayment',
  HOME_BUY: 'admin:dealflowDetail.dealflowStep.homeBuy',
  KEY_DELIVERY: 'admin:dealflowDetail.dealflowStep.keyDelivery',
  NEW_SMARTHOUSER: 'admin:dealflowDetail.dealflowStep.newSmarthouser',
}

export const DealflowStatusToComponentMap = {
  RESERVATION: DealflowReservationStep,
  OWNER_OFFER: DealflowOfferStep,
  CONTRACT_SIGN_AND_PAYMENT: DealflowSignStep,
  HOME_BUY: DealflowBuyStep,
  KEY_DELIVERY: DealflowKeyDeliveryStep,
  NEW_SMARTHOUSER: DealflowReservationStep,
}

export const DealflowStatusToUrl: { [x: string]: string } = {
  CANCEL_BY_USER: 'user_rejection',
  USER_REJECTED: 'user_rejection',
  FINANCIAL_CHECK: 'financial_check',
  FINANCIAL_CHECK_REJECTED: 'financial_check_rejection',
  MAKING_OFFER_TO_OWNER: 'offer',
  OWNER_OFFER_REJECTED: 'offer_rejection',
  SIGN_AND_PAY: 'signature',
  SIGN_AND_PAY_FAILED: 'signature_rejection',
  BUYING_HOUSE: 'acquisition',
  BUYING_HOUSE_FAILED: 'acquisition_rejection',
  LEGAL: 'legal',
  REJECTED_LEGAL: 'legal_rejection',
  QA: 'inspection',
  REJECTED_QA: 'inpection_rejection',
  CLOSING_PURCHASE: 'closing_purchase',
  CLOSING_PURCHASE_REJECTED: 'closing_purchase_rejection',
  KEY_DELIVERY: 'key_delivery',
  KEY_DELIVERY_REJECTED: 'key_delivery_rejection',
  NEW_SMARTHOUSER: 'new_smarthouser',
}

export const DealflowStatusToCancelled: { [x: string]: DealflowStatus } = {
  CHECKING_USER_DATA: 'USER_REJECTED',
  FINANCIAL_CHECK: 'FINANCIAL_CHECK_REJECTED',
  MAKING_OFFER_TO_OWNER: 'OWNER_OFFER_REJECTED',
  SIGN_AND_PAY: 'SIGN_AND_PAY_FAILED',
  BUYING_HOUSE: 'BUYING_HOUSE_FAILED',
  LEGAL: 'REJECTED_LEGAL',
  QA: 'REJECTED_QA',
  CLOSING_PURCHASE: 'CLOSING_PURCHASE_REJECTED',
  KEY_DELIVERY: 'KEY_DELIVERY_REJECTED',
}
