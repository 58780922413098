import { Document } from '../model/Document'

export class DocumentBuilder {
  private readonly document: Document

  constructor(id = 'documentSlug') {
    this.document = {
      id,
    }
  }

  build(): Document {
    return this.document
  }
}
