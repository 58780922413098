import { HttpClient } from '../../utils/http-client/HttpClient'
import { CityResponseDto } from './swagger/schema'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { GetAdminCities } from '../domain/GetAdminCities'
import { City } from '../../model/City'

export class GetAdminCitiesHttp implements GetAdminCities {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(): Promise<City[]> {
    return this.httpClient.get<CityResponseDto[]>(`${getPublicRuntimeConfig().API_ENDPOINT}/admin/cities`)
  }
}
