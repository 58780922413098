/* eslint camelcase: 0 */
import { Country } from '../../model/Country'
import { DetailedLocation } from '../../model/DetailedLocation'
import { Region } from '../../model/Region'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { GeocodeAddress } from '../domain/GeocodeAddress'

enum GoogleAddressComponents {
  STREET = 'route',
  STREET_NUMBER = 'street_number',
  POSTAL_CODE = 'postal_code',
  CITY = 'locality',
  PROVINCE = 'administrative_area_level_2',
  REGION = 'administrative_area_level_1',
  COUNTRY = 'country',
}

enum GoogleAddressTypes {
  STREET_ADDRESS = 'street_addres',
  POSTAL_CODE = 'postal_code',
}

type GoogleGeocodingApiResponseAddressComponents = {
  long_name: string
  short_name: string
  types: GoogleAddressComponents[]
}

export type GoogleGeocodingApiResponse = {
  results: {
    address_components: GoogleGeocodingApiResponseAddressComponents[]
    formatted_address: string
    geometry: {
      location: {
        lat: number
        lng: number
      }
    }
    types: GoogleAddressTypes[]
  }[]
  status: string
}

const countryToRegion: { [key in Country]: string } = {
  [Country.SPAIN]: 'es',
}

const getAddressComponentValue = (
  allAddressComponents: GoogleGeocodingApiResponseAddressComponents[],
  filter: GoogleAddressComponents
): string => {
  let value = ''
  allAddressComponents.forEach((component) => {
    if (component.types.includes(filter)) value = component.long_name
  })
  return value
}

export class GeocodeAddressGoogle implements GeocodeAddress {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(address: string | number, country = Country.SPAIN): Promise<DetailedLocation> {
    const url = '/api/admin/geocode'
    const options = {
      headers: {},
      params: {
        address,
        region: countryToRegion[country],
      },
    }

    const response = (await this.httpClient.get<GoogleGeocodingApiResponse>(url, options, true)).results[0]

    const city = getAddressComponentValue(response.address_components, GoogleAddressComponents.CITY)
    const region = getAddressComponentValue(response.address_components, GoogleAddressComponents.PROVINCE) as Region
    const address1 = `${getAddressComponentValue(response.address_components, GoogleAddressComponents.STREET)}, ${getAddressComponentValue(
      response.address_components,
      GoogleAddressComponents.STREET_NUMBER
    )}`
    const postalCode = response.types.includes(GoogleAddressTypes.POSTAL_CODE)
      ? String(address).split(' ')[0]
      : getAddressComponentValue(response.address_components, GoogleAddressComponents.POSTAL_CODE)

    return {
      city,
      region,
      address1: address1 !== ', ' ? address1 : '',
      formattedAddress: response.formatted_address,
      postalCode,
      location: {
        latitude: response.geometry.location.lat,
        longitude: response.geometry.location.lng,
      },
    }
  }
}
