import { GetPurchaseAdmin } from '../domain/GetPurchaseAdmin'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { DealflowShowWithPropertyResponseDto } from './swagger/schema'

export class GetPurchaseAdminHttp implements GetPurchaseAdmin {
  private readonly httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(purchaseId: string): Promise<DealflowShowWithPropertyResponseDto> {
    const response = await this.httpClient.get<DealflowShowWithPropertyResponseDto>(
      `${getPublicRuntimeConfig().API_ENDPOINT}/admin/dealflows/${purchaseId}`
    )

    return response
  }
}
