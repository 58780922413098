import { BackofficePropertyDetailBuilder } from '../../builders/BackofficePropertyDetailBuilder'
import { BackofficePropertyDetail } from '../../model/Property'
import { PublishAdminPropertyDraft } from '../domain/PublishAdminPropertyDraft'

export class PublishAdminPropertyDraftFake implements PublishAdminPropertyDraft {
  private readonly propertyDetails: BackofficePropertyDetail

  constructor(propertyDetails = new BackofficePropertyDetailBuilder().build()) {
    this.propertyDetails = propertyDetails
  }

  execute(): Promise<void> {
    return Promise.resolve()
  }
}
