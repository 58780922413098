import classNames from 'classnames'
import { Text } from 'components/Text/Text'
import { useField } from 'formik'
import React, { ComponentPropsWithRef } from 'react'
import sanitize from 'sanitize-html'
import { Icon, IconName } from '../../DataDisplay/Icon/Icon'
import { Typography, TypographyType } from '../../DataDisplay/Typography/Typography'
import styles from './Checkbox.module.scss'
import { useAlignCheckbox } from './useAlignCheckbox'

type CheckboxSize = 'small' | 'medium'

type CheckboxWeight = 'normal' | 'bold'

type CheckboxBaseProps = {
  name: string
  label: string
  value: string
  defaultChecked?: boolean
  checked?: boolean
  size: CheckboxSize
  weight?: CheckboxWeight
  expand?: boolean
  labelClassName?: string
}

const sizeByVariant: Record<CheckboxSize, number> = {
  small: 16,
  medium: 24,
}

const typographyByVariant: Record<CheckboxSize, TypographyType> = {
  small: TypographyType.PARAGRAPH,
  medium: TypographyType.HEADLINE,
}

export type CheckboxProps = Omit<ComponentPropsWithRef<'input'>, 'size'> & CheckboxBaseProps

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      name,
      label,
      checked = false,
      defaultChecked,
      value,
      style,
      className,
      labelClassName,
      size: sizeProp = 'medium',
      weight = 'normal',
      expand = false,
      children,
      ...rest
    },
    forwardedRef
  ) => {
    const size = sizeByVariant[sizeProp]
    const typography = typographyByVariant[sizeProp]
    const body = weight === 'bold' ? `<strong>${label}</strong>` : label
    const { ref, labelRef } = useAlignCheckbox()

    return (
      <div className={classNames(styles.container, { [styles['container--expand']]: expand }, className)} style={style} ref={ref}>
        <input
          type="checkbox"
          id={`${name}-${value}`}
          name={name}
          value={value}
          className={styles.checkboxInput}
          ref={forwardedRef}
          defaultChecked={defaultChecked}
          checked={checked}
          {...rest}
        />
        <label htmlFor={`${name}-${value}`} className={`${styles.labelContainer} ${labelClassName && labelClassName}`} ref={labelRef}>
          <span className={styles.square} style={{ width: size, height: size }}>
            <Icon name={IconName.CHECK} className={styles.checkMark} size={size} />
          </span>
          {children ? (
            <Text className={styles.label}>{children}</Text>
          ) : (
            <Typography typographyType={typography} className={styles.label} dangerouslySetInnerHTML={{ __html: sanitize(body) }} />
          )}
        </label>
      </div>
    )
  }
)

export const FormCheckbox = (props: CheckboxProps) => {
  const [input, , helpers] = useField({
    name: props.name,
  })
  return (
    <Checkbox
      {...props}
      onChange={(e) => {
        const { checked } = e.target
        helpers.setValue(checked)
      }}
      checked={input.value}
      onBlur={input.onBlur}
    />
  )
}
