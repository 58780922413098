import classNames from 'classnames'
import { FC } from 'react'
import { useTranslation } from '../../../hooks/useTranslationDeprecated'
import { PropertyStatus } from '../../../model/PropertyStatus'
import styles from './Banner.module.scss'

type BannerProps = {
  type: PropertyStatus
  specialOffer?: string
  className?: string
}

const propertyStatusToStyle: { [key in PropertyStatus]: string } = {
  [PropertyStatus.AVAILABLE]: styles.available,
  [PropertyStatus.RESERVED]: styles.booked,
  [PropertyStatus.DRAFT]: styles.available,
  [PropertyStatus.SOLD]: styles.sold,
  [PropertyStatus.SOLD]: styles.sold,
}

/**
 * TODO: check for a better name
 */
export const Banner: FC<BannerProps> = ({ type, specialOffer, className }) => {
  const { t } = useTranslation()

  const propertyStatusToContent: { [key in PropertyStatus]: string } = {
    [PropertyStatus.AVAILABLE]: '', // When is available we don't need to show anything
    [PropertyStatus.RESERVED]: 'Reservada',
    [PropertyStatus.DRAFT]: '', // When is available we don't need to show anything
    [PropertyStatus.SOLD]: t.properties.banner.sold,
  }

  if (type === PropertyStatus.AVAILABLE && specialOffer) {
    return (
      <div className={classNames(styles.banner, styles.specialOffer, className)}>
        <span>{specialOffer}</span>
      </div>
    )
  }
  return (
    <div className={classNames(styles.banner, propertyStatusToStyle[type], className)}>
      <span>{propertyStatusToContent[type]}</span>
    </div>
  )
}
