import React, { FC, useState } from 'react'
import { DealflowAdminShowWithPropertyResponseDto, HomeOwnerResponseDto } from 'repositories/http/swagger/schema'

import { Text } from 'components/Text/Text'

import useTranslation from 'next-translate/useTranslation'
import { Icon, IconName, IconColor } from 'components/DataDisplay/Icon/Icon'
import { getDocumentUrl } from 'utils/dealflowDetailUtils'
import { Drawer } from 'components/Drawer/Drawer'
import { PaymentType } from 'model/PaymentType'
import { formatterCurrencyNoDecimals } from 'utils/number'
import { Button } from 'components/Button/Button'
import { Checkbox } from 'components/Inputs/Checkbox/Checkbox'
import { DealflowStatusEnum, isDealflowStatusCompleted } from 'model/DealflowStatus'
import { useSetDealflowStatus } from 'api/mutations/setDealflowStatus'
import styles from './DealflowReservationStep.module.scss'
import { HomeownerDataDrawer } from '../HomeownerDataDrawer/HomeownerDataDrawer'
import { useToast } from '../../../../context/ToastContext'

type DealflowReservationStepProps = {
  purchase: DealflowAdminShowWithPropertyResponseDto
  onRefetch: () => void
}

export const DealflowReservationStep: FC<DealflowReservationStepProps> = ({ purchase, onRefetch }) => {
  const { t: translation } = useTranslation()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [activeOwner, setActiveOwner] = useState(purchase.homeOwners[0])
  const ownerIndex = purchase.homeOwners.indexOf(activeOwner)
  const { setToast } = useToast()

  const isStepCompleted = isDealflowStatusCompleted(purchase.status, DealflowStatusEnum.CHECKING_USER_DATA)
  const isCheckboxDisabled = purchase.status !== DealflowStatusEnum.CHECKING_USER_DATA

  const reservationFee = purchase.payments.find(({ type }) => type === PaymentType.BOOKING)
  const [dataConfirmation, setDataConfirmation] = useState<boolean>(isStepCompleted)

  const { mutate: setDealflowStatus } = useSetDealflowStatus()

  const handleCheckboxClick = () => {
    if (dataConfirmation) return

    setDealflowStatus(
      { dealflowId: purchase.id, nextStatus: DealflowStatusEnum.FINANCIAL_CHECK },
      {
        onSuccess: () => {
          setDataConfirmation(true)
          setToast({
            message: 'Dealflow status updated successfully',
            tone: 'success',
            duration: 5000,
          })
          onRefetch()
        },
        onError: () => {
          setToast({
            message: 'There was an error while updating the Dealflow',
            tone: 'error',
            duration: 5000,
          })
        },
      }
    )
  }

  return (
    <div className={styles.container}>
      <Text weight="bold" type="headline">
        Homeowners
      </Text>

      <div className={styles.ownersContainer}>
        {purchase.homeOwners.map((owner: HomeOwnerResponseDto, index) => {
          return (
            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => setIsDrawerOpen(!isDrawerOpen)}
              onClick={() => {
                setActiveOwner(owner)
                setIsDrawerOpen(!isDrawerOpen)
              }}
              className={styles.ownerCard}
              key={owner.id}
            >
              <div className={styles.circleIcon}>
                <Icon size={19} name={IconName.USER} color={IconColor.WHITE} />
              </div>
              <div className={styles.ownerName}>
                <Text type="headline" weight="bold">{`${owner.name} ${owner.surname}`}</Text>
                <Text type="mini">{`Homeowner ${index + 1}`}</Text>
              </div>

              <Icon className={styles.arrowIcon} name={IconName.RIGHT_ARROW} />
            </div>
          )
        })}
      </div>

      <hr className={styles.divider} />

      <Text type="headline" weight="bold" className={styles.ownerDataTitle}>
        {`${translation('admin:dealflowDetail.bookingDownpayment')} (${formatterCurrencyNoDecimals(reservationFee?.amount || 0)})`}
      </Text>

      <Button
        icon={IconName.EURO}
        variant="tertiary"
        size="small"
        onClick={() => {
          return window.open(getDocumentUrl(purchase.id, purchase.payments[0].documents[0].id), '_blank')
        }}
        disabled={!purchase?.payments[0]?.documents[0]?.id}
      >
        Payment for the reservation proof
      </Button>

      <hr className={styles.divider} />

      <Text type="headline" weight="bold" className={styles.ownerDataTitle}>
        {translation('admin:dealflowDetail.dataConfirmation.title')}
      </Text>

      <Checkbox
        onChange={handleCheckboxClick}
        checked={dataConfirmation}
        disabled={isCheckboxDisabled}
        label={translation('admin:dealflowDetail.dataConfirmation.label')}
        value="dataConfirmation"
        name="dataConfirmation"
        weight="bold"
        size="small"
      />

      <Drawer isOpen={isDrawerOpen} onOpenChange={() => setIsDrawerOpen(!isDrawerOpen)}>
        <HomeownerDataDrawer activeOwner={activeOwner} ownerIndex={ownerIndex} purchaseId={purchase.id} />
      </Drawer>
    </div>
  )
}
