import { DetailedLocation } from '../../model/DetailedLocation'
import { Region } from '../../model/Region'
import { GeocodeAddress } from '../domain/GeocodeAddress'

export class GeocodeAddressFake implements GeocodeAddress {
  private detailedLocation: DetailedLocation

  constructor(
    detailedLocation = {
      city: 'Madrid',
      region: Region.MADRID,
      address1: 'Calle Lavanda, 25',
      formattedAddress: 'C. Lavanda, 25, Hortaleza, 28004 Madrid, Spain',
      postalCode: '28004',
      location: {
        latitude: 40.4183083,
        longitude: -3.70275,
      },
    }
  ) {
    this.detailedLocation = detailedLocation
  }

  async execute(address: string): Promise<DetailedLocation> {
    const postalCode = parseInt(address)
    if (Number.isNaN(postalCode)) {
      if (this.detailedLocation.address1 !== address) this.detailedLocation.address1 = address
    } else if (this.detailedLocation.postalCode !== address) this.detailedLocation.postalCode = String(postalCode)
    return this.detailedLocation
  }
}
