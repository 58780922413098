import React, { FC } from 'react'
import classNames from 'classnames'

export const Shower: FC<{ fillClassName: string; size?: number; className?: string }> = ({ fillClassName, size = 24, className }) => (
  <svg
    className={classNames(className)}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    style={{ display: 'inline' }}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M.2.2h15.6v15.6H.2z" />
      <path
        className={fillClassName}
        d="M9.6 9.67a.42.42 0 00-.42.42.42.42 0 00.85 0c0-.23-.2-.42-.43-.42zm.86-2.5a.42.42 0 00-.43.41c0 .23.2.42.43.42.23 0 .42-.19.42-.42a.42.42 0 00-.42-.42zm.85 1.67c.23 0 .42-.2.42-.42 0-.23-.19-.42-.42-.42a.42.42 0 00-.43.42c0 .23.2.42.43.42zm-.85.83c.23 0 .42-.19.42-.42a.42.42 0 00-.42-.41.42.42 0 00-.43.41c0 .23.2.42.43.42zM9.6 8a.42.42 0 00-.42.42.42.42 0 00.85 0c0-.23-.2-.42-.43-.42zm3.41-.84c.24 0 .43-.18.43-.41 0-.23-.19-.42-.43-.42a.42.42 0 00-.42.42c0 .23.19.41.42.41zm-1.27.42c0 .23.19.42.42.42.24 0 .43-.19.43-.42 0-.23-.2-.42-.43-.42a.42.42 0 00-.42.42zm-.43-1.25a.42.42 0 00-.43.42c0 .23.2.41.43.41.23 0 .43-.18.43-.41 0-.23-.2-.42-.43-.42zm2.56.83a.42.42 0 00-.43.42c0 .23.2.42.43.42.23 0 .42-.19.42-.42 0-.23-.19-.42-.42-.42zm.85-.83a.42.42 0 00-.43.42c0 .23.2.41.43.41.23 0 .43-.18.43-.41 0-.23-.2-.42-.43-.42zM13.02 8a.42.42 0 00-.43.42.42.42 0 00.85 0c0-.23-.2-.42-.43-.42zm-.86.84a.42.42 0 00-.43.41c0 .23.2.42.43.42.24 0 .43-.19.43-.42 0-.23-.2-.41-.43-.41zm-1.7 1.67a.42.42 0 00-.43.41c0 .23.2.42.43.42.23 0 .42-.19.42-.42a.42.42 0 00-.42-.41zm-.86.83a.42.42 0 00-.42.42.42.42 0 00.85 0c0-.23-.2-.42-.43-.42zm1.7-1.67a.42.42 0 00-.42.42c0 .23.2.42.43.42.23 0 .42-.2.42-.42 0-.23-.19-.42-.42-.42zm.59-5.7l-.3-.3a.43.43 0 00-.6 0l-.16.15a3 3 0 00-3.49-.47l-.26-.26c-.6-.6-1.43-.93-2.29-.94A3.38 3.38 0 001.5 5.52v7.9c0 .24.2.43.43.43h.85c.24 0 .43-.2.43-.42V5.45c0-.78.56-1.52 1.36-1.61.48-.06.96.1 1.3.43l.27.26a2.82 2.82 0 00.48 3.42l-.15.15a.41.41 0 000 .59l.3.3a.43.43 0 00.6 0l4.52-4.43a.41.41 0 000-.59z"
      />
    </g>
  </svg>
)
