import { MarketplacePropertyDetailsBuilder } from '../../builders/MarketplacePropertyDetailsBuilder'
import { MarketplacePropertyDetails } from '../../model/PropertyDetails'
import { GetPropertyDetails } from '../domain/GetPropertyDetails'

export class GetPropertyDetailsFake implements GetPropertyDetails {
  private readonly propertyDetails: MarketplacePropertyDetails

  constructor(propertyDetails = new MarketplacePropertyDetailsBuilder().build()) {
    this.propertyDetails = propertyDetails
  }

  execute(): Promise<MarketplacePropertyDetails> {
    return Promise.resolve(this.propertyDetails)
  }
}
