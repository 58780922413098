// React imports
import { useEffect } from 'react'

// Next imports
import Script from 'next/script'
import { useRouter } from 'next/router'

// Services imports
import { GA_TRACKING_ID, pageview } from 'services/analytics/gtag'
import { useMe } from 'api/queries/fetchUserMe'

export const GoogleAnalytics = () => {
  // Create hooks
  const router = useRouter()
  const me = useMe()

  const handleRouteChange = (url: string) => {
    pageview(url, me)
  }

  // Listen to router changes
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('hashChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('hashChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      <Script strategy="beforeInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              'debug_mode': false,
            });
          `,
        }}
      />
    </>
  )
}

export const NoScriptGoogleAnalytics = () => {
  return null
}
