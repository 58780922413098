import classNames from 'classnames'
import React, { FC } from 'react'
import { Icon, IconProps } from '../Icon/Icon'
import styles from './FramedIcon.module.scss'

type FramedIconProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  small?: boolean
  area?: boolean
}

const SMALL_ICON_SIZE = 11

export const FramedIcon: FC<IconProps & FramedIconProps> = ({ onClick, className, small, size, area, ...props }) => {
  function getStaticIcon() {
    return (
      <div className={classNames(styles.frame, small && styles.small, className, area && styles.bigArea)}>
        <Icon size={small ? SMALL_ICON_SIZE : size} {...props} />
      </div>
    )
  }

  if (onClick) {
    return (
      <button type="button" className={classNames(styles.frame, styles.clickable, small && styles.small, className)} onClick={onClick}>
        <Icon size={small ? SMALL_ICON_SIZE : size} {...props} />
      </button>
    )
  }

  if (area) {
    return <div className={classNames(styles.frame, small && styles.small, className, area && styles.bigArea)} />
  }

  return getStaticIcon()
}
