import { IconName } from 'components/DataDisplay/Icon/Icon'
import { InputFieldProps, TextField } from 'components/Inputs/TextField/TextField'
import React, { ComponentPropsWithRef, FC, useCallback, useEffect, useRef, useState } from 'react'
import { formatterNumber } from 'utils/number'

const isOutOfRange = (val: number, min: number, max: number): boolean => {
  if (val < 0) {
    return true
  }
  if (max && val > max) {
    return true
  }
  if (min && val < min) {
    return true
  }
  return false
}

const getCurrencyIcon = (type: 'EURO' | 'USD') => {
  switch (type) {
    case 'EURO':
      return IconName.EURO
    default:
      return IconName.EURO
  }
}

interface InputCurrencyBaseProps {
  min: number
  max: number
  currency?: 'EURO' | 'USD'
}

type INputCurrencyProps = InputFieldProps & InputCurrencyBaseProps & ComponentPropsWithRef<'input'>

export const InputCurrency: FC<INputCurrencyProps> = (props) => {
  const { value, min, max, currency = 'EURO' } = props
  const [mask, setMask] = useState<string>(formatterNumber(value as number) ?? '')
  const isFocus = useRef(false)

  const formatValue = useCallback(() => {
    if (value || value === 0) {
      setMask(formatterNumber(value as number))
    } else {
      setMask('')
    }
  }, [value])

  const onFocus = () => {
    isFocus.current = true
    setMask(value?.toString() ?? '')
  }

  const onBlur = () => {
    isFocus.current = false
    formatValue()
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const valInt = Number(value)
    props.onChange?.(e)
    if (Number.isNaN(valInt)) {
      return
    }
    if (isOutOfRange(valInt, min, max)) {
      return
    }
    setMask(valInt.toString())
  }

  useEffect(() => {
    // when is not focus then format when `value` change
    if (!isFocus.current) {
      formatValue()
    }
  }, [formatValue])

  const icon = getCurrencyIcon(currency)

  return (
    <TextField
      {...props}
      onFocus={props.onFocus || onFocus}
      onBlur={props.onBlur || onBlur}
      onChange={props.onChange || onChange}
      value={props.value || mask}
      rightIconName={icon}
    />
  )
}
