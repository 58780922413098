import { GetSmarthouser } from 'repositories/domain/GetSmarthouser'

import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { SmarthouserShowResponseDto } from './swagger/schema'

export class GetSmarthouserHttp implements GetSmarthouser {
  private readonly httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(): Promise<SmarthouserShowResponseDto> {
    const response = await this.httpClient.get<any>(`${getPublicRuntimeConfig().API_ENDPOINT}/smarthouser`)

    return response
  }
}
