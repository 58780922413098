import { LocaleKeys } from '../i18n/keys'

// Beware Regions are thought as provinces. If this changes in the future to
// Communities, we should update the GeocodeAddressGoogle repository aswell
export enum Region {
  MADRID = 'Madrid',
  BARCELONA = 'Barcelona',
  VALENCIA = 'Valencia',
}

export function translateRegion(t: LocaleKeys, region: Region): string {
  const translation: { [key in Region]: string } = {
    [Region.MADRID]: t.common.region.madrid,
    [Region.BARCELONA]: t.common.region.barcelona,
    [Region.VALENCIA]: t.common.region.valencia,
  }

  return translation[region]
}
