/**
 * @deprecated
 */
export enum PaymentTypeDeprecated {
  RESERVATION = 'RESERVATION',
  INITIAL_DOWNPAYMENT = 'INITIAL_DOWNPAYMENT',
  LAST_DOWNPAYMENT = 'LAST_DOWNPAYMENT',
  MONTHLY = 'MONTHLY',
  EXTRA = 'EXTRA',
}

export enum PaymentType {
  BOOKING = 'BOOKING',
  ENTRY = 'ENTRY',
  MONTHLY = 'MONTHLY',
  ADDITIONAL = 'ADDITIONAL',
}
