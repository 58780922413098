import { GetAdminCities } from '../domain/GetAdminCities'
import { City } from '../../model/City'

export class GetAdminCitiesFake implements GetAdminCities {
  execute(): Promise<City[]> {
    const answer: City[] = [
      {
        name: 'Madrid',
        description: 'Default city',
        image: 'images/madrid/madrid.jpg',
        enabled: true,
      },
      {
        name: 'Valecia',
        description: 'City of Valencia',
        image: 'https://i.imgur.com/j9rwkmR.jpg',
        enabled: false,
      },
      {
        name: 'Barcelona',
        description: 'City of Barcelona',
        image: '/images/barcelona/barcelona.jpg',
        enabled: true,
      },
    ]

    return Promise.resolve(answer)
  }
}
