import { BackofficePropertyDetailBuilder } from '../../builders/BackofficePropertyDetailBuilder'
import { BackofficePropertyDetail } from '../../model/Property'
import { CreatePropertyDraft } from '../domain/CreatePropertyDraft'
import { RepositoryError } from '../domain/RepositoryError'

export class CreatePropertyDraftFake implements CreatePropertyDraft {
  private readonly error: boolean

  static thatResolvesSuccessfully() {
    return new CreatePropertyDraftFake()
  }

  static thatRejectsWithOauthError() {
    return new CreatePropertyDraftFake({ error: true })
  }

  constructor({ error = false }: { error?: boolean } = {}) {
    this.error = error
  }

  async execute(): Promise<BackofficePropertyDetail> {
    if (this.error) {
      throw new RepositoryError('ERROR', 'Some unknown error has happened')
    }

    return new BackofficePropertyDetailBuilder().build()
  }
}
