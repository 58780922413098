import { HttpClient } from '../../utils/http-client/HttpClient'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { AdminPropertyDraftCreateDto } from './swagger/schema'
import { UpdateAdminPropertyDraft } from '../domain/UpdateAdminPropertyDraft'

export class UpdateAdminPropertyDraftHttp implements UpdateAdminPropertyDraft {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(slug: string, body: AdminPropertyDraftCreateDto): Promise<void> {
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/admin/property_drafts/${slug}`
    const options = { headers: {}, params: {} }

    return this.httpClient.put<void>(url, body, options)
  }
}
