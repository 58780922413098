import { LocaleKeys } from '../i18n/keys'

export enum PropertyEnergeticCertification {
  AAAA = 'AAAA',
  AAA = 'AAA',
  AA = 'AA',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}

export function translatePropertyEnergeticCertification(t: LocaleKeys, propertyEnergeticCertification: PropertyEnergeticCertification): string {
  const translation: { [key in PropertyEnergeticCertification]: string } = {
    [PropertyEnergeticCertification.AAAA]: t.common.propertyEnergeticCertification.aaaa,
    [PropertyEnergeticCertification.AAA]: t.common.propertyEnergeticCertification.aaa,
    [PropertyEnergeticCertification.AA]: t.common.propertyEnergeticCertification.aa,
    [PropertyEnergeticCertification.A]: t.common.propertyEnergeticCertification.a,
    [PropertyEnergeticCertification.B]: t.common.propertyEnergeticCertification.b,
    [PropertyEnergeticCertification.C]: t.common.propertyEnergeticCertification.c,
    [PropertyEnergeticCertification.D]: t.common.propertyEnergeticCertification.d,
    [PropertyEnergeticCertification.E]: t.common.propertyEnergeticCertification.e,
  }

  return translation[propertyEnergeticCertification]
}
