import { MarketplacePropertyBuilder } from '../../builders/MarketplacePropertyBuilder'
import { MarketplaceProperties, MarketplaceProperty } from '../../model/Property'
import { PropertyStatus } from '../../model/PropertyStatus'
import { PropertyTag, PropertyTag as pt } from '../../model/PropertyTag'
import { calculateOffset } from '../../utils/calculateOffset'
import { GetProperties } from '../domain/GetProperties'

export class GetPropertiesFake implements GetProperties {
  private readonly properties: MarketplaceProperty[]

  constructor(
    properties = [
      new MarketplacePropertyBuilder().withTitle('Vallehermoso 48').build(),
      new MarketplacePropertyBuilder()
        .withTitle('Monteleón 33')
        .withStatus(PropertyStatus.RESERVED)
        .withTags([pt.SUNNY, pt.PETS_ALLOWED, pt.SHOPPING_AREA, pt.RESTAURANTS_NEAR, pt.PUBLIC_TRANSPORT])
        .markAsFavorite()
        .build(),
      new MarketplacePropertyBuilder().withTitle('Isaac Peral 8').build(),
      new MarketplacePropertyBuilder().withTitle('Ibiza 168').withStatus(PropertyStatus.SOLD).markAsFavorite().build(),
      new MarketplacePropertyBuilder().withTitle('Ópera 148').markAsFavorite().build(),
      new MarketplacePropertyBuilder()
        .withTitle('Ponzano 48')
        .withTags([pt.SUNNY, pt.PETS_ALLOWED, pt.SHOPPING_AREA, pt.RESTAURANTS_NEAR, pt.PUBLIC_TRANSPORT])
        .build(),
      new MarketplacePropertyBuilder().withTitle('Dr Esquerdo 148').markAsFavorite().build(),
      new MarketplacePropertyBuilder()
        .withTitle('Dr Esquerdo 148')
        .withTags([pt.SUNNY, pt.PETS_ALLOWED, pt.SHOPPING_AREA, pt.RESTAURANTS_NEAR, pt.PUBLIC_TRANSPORT])
        .build(),
    ]
  ) {
    this.properties = properties
  }

  pricePerMonthGreaterThan = (minPricePerMonth: number) => (property: MarketplaceProperty) => property.pricePerMonth >= minPricePerMonth

  pricePerMonthLessThan = (maxPricePerMonth: number) => (property: MarketplaceProperty) => property.pricePerMonth <= maxPricePerMonth

  cityEqualTo = (city: string) => (property: MarketplaceProperty) => property.address.city.toLowerCase() === city.toLowerCase()

  async execute(
    tags: PropertyTag[],
    neighborhoods: string[],
    minPricePerMonth?: number,
    maxPricePerMonth?: number,
    minSurface?: number,
    maxSurface?: number,
    city?: string,
    bedrooms?: number[],
    bathrooms?: number[],
    page?: number,
    limit?: number
  ): Promise<MarketplaceProperties> {
    const filteredProperties = await Promise.resolve(this.properties)
      .then((properties: MarketplaceProperty[]) =>
        !(tags.length > 0) ? properties : properties.filter((property: MarketplaceProperty) => tags.every((tag) => property.tags.includes(tag)))
      )
      .then((properties: MarketplaceProperty[]) =>
        !minPricePerMonth ? properties : properties.filter(this.pricePerMonthGreaterThan(minPricePerMonth))
      )
      .then((properties: MarketplaceProperty[]) =>
        !maxPricePerMonth ? properties : properties.filter(this.pricePerMonthLessThan(maxPricePerMonth))
      )
      .then((properties: MarketplaceProperty[]) => (!minSurface ? properties : properties.filter((property) => property.surface >= minSurface)))
      .then((properties: MarketplaceProperty[]) => (!maxSurface ? properties : properties.filter((property) => property.surface <= maxSurface)))
      .then((properties: MarketplaceProperty[]) =>
        !(bedrooms && bedrooms.length > 0) ? properties : properties.filter((property) => bedrooms.includes(property.bedroomsAmount))
      )
      .then((properties: MarketplaceProperty[]) =>
        !(bathrooms && bathrooms.length > 0) ? properties : properties.filter((property) => bathrooms.includes(property.bathroomsAmount))
      )
      .then((properties: MarketplaceProperty[]) => (!city ? properties : properties.filter(this.cityEqualTo(city))))
      .then((properties: MarketplaceProperty[]) =>
        !(neighborhoods.length > 0)
          ? properties
          : properties.filter((property: MarketplaceProperty) =>
              neighborhoods.some((neighborhood) => property.address.neighborhood.toLowerCase() === neighborhood.toLowerCase())
            )
      )
    const totalProperties = filteredProperties.length
    const propertiesPaginated = !page || !limit ? filteredProperties : filteredProperties.slice(calculateOffset(page, limit), limit)

    return {
      properties: propertiesPaginated,
      totalCount: totalProperties,
    }
  }
}
