import React, { FC, useState } from 'react'
import { DealflowAdminShowWithPropertyResponseDto, DealflowUpdateDto } from 'repositories/http/swagger/schema'

import { Text } from 'components/Text/Text'

import { getDocumentUrl } from 'utils/dealflowDetailUtils'

import { Checkbox } from 'components/Inputs/Checkbox/Checkbox'
import { DealflowStatus, DealflowStatusEnum, isDealflowStatusCompleted } from 'model/DealflowStatus'
import { useSetDealflowStatus } from 'api/mutations/setDealflowStatus'
import { FileArea } from 'components/Inputs/FileInput/FileArea/FileArea'
import { FileType, StatusFileArea } from 'components/Inputs/FileInput/components/FileDropzone/FileDropzone'
import { FormikProvider, useFormik } from 'formik'
import { DealflowDocumentType, useUploadDealflowDocument } from 'api/mutations/uploadDealflowDocument'
import * as Yup from 'yup'
import { useUpdateDealflow } from 'api/mutations/useUpdateDealflow'
import { DateInput } from 'components/Inputs/DateInput/DateInput'
import { useToast } from 'context/ToastContext'
import { Select } from 'components/Select/Select'
import { Icon, IconName, IconType } from 'components/DataDisplay/Icon/Icon'
import { timePickerSelectOptions } from 'utils/time'
import { formatISODateToDateOnly } from 'utils/formatDate'
import styles from './DealflowBuyStep.module.scss'
import { FileContainer } from '../FileContainer/FileContainer'

type DealflowBuyStepProps = {
  purchase: DealflowAdminShowWithPropertyResponseDto
  onRefetch: () => void
}

export const DealflowBuyStep: FC<DealflowBuyStepProps> = ({ purchase, onRefetch }) => {
  const { setToast } = useToast()

  const [legalStatus, setLegalStatus] = useState<StatusFileArea>(StatusFileArea.EMPTY)
  const lastLegalDocument = purchase.documents.filter((document) => document.type === 'legal').pop()
  const [isLegalFileAreaVisible, setIsLegalFileAreaVisible] = useState<boolean>(!lastLegalDocument)

  const [QaStatus, setQaStatus] = useState<StatusFileArea>(StatusFileArea.EMPTY)
  const lastQaDocument = purchase.documents.filter((document) => document.type === 'technicalInspection').pop()
  const [isQaFileAreaVisible, setIsQaFileAreaVisible] = useState<boolean>(!lastQaDocument)

  const [deedsStatus, setDeedsStatus] = useState<StatusFileArea>(StatusFileArea.EMPTY)
  const lastDeedsDocument = purchase.documents.filter((document) => document.type === 'deeds').pop()
  const [isDeedsFileAreaVisible, setIsDeedsFileAreaVisible] = useState<boolean>(!lastDeedsDocument)

  const isLegalCompleted = isDealflowStatusCompleted(purchase.status, DealflowStatusEnum.LEGAL)
  const isLegalCheckboxDisabled = purchase.status !== DealflowStatusEnum.LEGAL || !lastLegalDocument
  const [dataConfirmation, setDataConfirmation] = useState<boolean>(isLegalCompleted)

  const isQaCompleted = isDealflowStatusCompleted(purchase.status, DealflowStatusEnum.QA)
  const isQaCheckboxDisabled = purchase.status !== DealflowStatusEnum.QA || !lastQaDocument
  const [dataConfirmation2, setDataConfirmation2] = useState<boolean>(isQaCompleted)

  const isClosingPurchaseCompleted = isDealflowStatusCompleted(purchase.status, DealflowStatusEnum.CLOSING_PURCHASE)
  const isDeedsCheckboxDisabled = purchase.status !== DealflowStatusEnum.CLOSING_PURCHASE || !lastDeedsDocument
  const [dataConfirmation3, setDataConfirmation3] = useState<boolean>(isClosingPurchaseCompleted)

  const { mutate: uploadContractDocument } = useUploadDealflowDocument()
  const { mutate: updateDealflow } = useUpdateDealflow()
  const { mutate: setDealflowStatus } = useSetDealflowStatus()

  const onDealflowUpdate = async (data: DealflowUpdateDto) => {
    updateDealflow(
      { dealflowId: purchase.id, data },
      {
        onSuccess: () => {
          setToast({
            message: 'Dealflow updated successfully.',
            tone: 'success',
            duration: 3000,
          })
          onRefetch()
        },
        onError: () => {
          setToast({
            message: 'There was an error while updating the Dealflow.',
            tone: 'error',
            duration: 3000,
          })
        },
      }
    )
  }

  const onDocumentUpdate = async (data: { document: File | null }, type: DealflowDocumentType) => {
    if (!data?.document) return
    uploadContractDocument(
      { type, dealflowId: purchase.id, files: data.document },
      {
        onSuccess: () => {
          setToast({
            message: 'File uploaded successfully.',
            tone: 'success',
            duration: 3000,
          })
          onRefetch()
        },
        onError: () => {
          setToast({
            message: 'There was an error while uploadibg the file.',
            tone: 'error',
            duration: 3000,
          })
        },
      }
    )
  }

  const handleCheckboxClick = (nextStatus: DealflowStatus) => {
    setDealflowStatus(
      { dealflowId: purchase.id, nextStatus },
      {
        onSuccess: () => {
          setToast({
            message: 'Dealflow status updated successfully.',
            tone: 'success',
            duration: 3000,
          })
          onRefetch()
        },
        onError: () => {
          setToast({
            message: 'There was an error while updating the Dealflow',
            tone: 'error',
            duration: 3000,
          })
        },
      }
    )
  }

  const documentSchema = () => {
    Yup.object().shape({
      document: Yup.mixed()
        .required('File is required')
        .test('fileType', 'Invalid file type', (value) => (value && value.type === FileType.PDF) || value.type === FileType.IMAGE),
    })
  }

  const deedsSignDateFormSchema = () => {
    Yup.object().shape({
      date: Yup.date().required(),
      time: Yup.string().required(),
    })
  }

  const legalDocumentForm = useFormik({
    initialValues: {
      document: null as unknown as File | null,
    },
    onSubmit: async (values) => {
      if (!values?.document) return
      await onDocumentUpdate(values, 'legal')
    },
    validationSchema: documentSchema(),
  })

  const qaDocumentForm = useFormik({
    initialValues: {
      document: null as unknown as File | null,
    },
    onSubmit: async (values) => {
      if (!values?.document) return
      await onDocumentUpdate(values, 'technicalInspection')
    },
    validationSchema: documentSchema(),
  })

  const deedsDocumentForm = useFormik({
    initialValues: {
      document: null as unknown as File | null,
    },
    onSubmit: async (values) => {
      if (!values?.document) return
      await onDocumentUpdate(values, 'deeds')
    },
    validationSchema: documentSchema(),
  })

  const deedsSignDateForm = useFormik({
    initialValues: {
      date: purchase?.deedsSignDate?.split('T')[0],
      time: purchase.deedsSignDate?.split('T')[1].substring(0, 5) || '',
    },
    onSubmit: async ({ date, time }) => {
      if (!date || !time) return
      const fullDate = new Date(`${date}T${time}`)
      const timezoneOffset = fullDate.getTimezoneOffset() * 60000
      const deedsSignDate = new Date(fullDate.getTime() - timezoneOffset).toISOString()
      await onDealflowUpdate({ deedsSignDate })
    },
    validationSchema: deedsSignDateFormSchema(),
  })

  return (
    <div className={styles.container}>
      <Text weight="bold" type="headline">
        Choose a date and time for signing the deeds
      </Text>
      <div className={styles.deedsSignDateContainer}>
        <FormikProvider value={deedsSignDateForm}>
          <DateInput
            {...deedsSignDateForm.getFieldProps('date')}
            value={deedsSignDateForm.values.date ? new Date(deedsSignDateForm.values.date) : undefined}
            label="Date"
            className={styles.dateInput}
            onChange={(date) => deedsSignDateForm.setFieldValue('date', formatISODateToDateOnly(date))}
            onBlur={() => deedsSignDateForm.submitForm()}
          />
          <input style={{ display: 'none' }} type="text" name="time" value={deedsSignDateForm.values.time} />
          <Select
            id="time"
            placeholder="HH:MM"
            label="Time"
            selectClass={styles.timeInput}
            containerClass={styles.timeInputContainer}
            options={timePickerSelectOptions}
            disabled={!deedsSignDateForm.values.date}
            onChange={(e) => {
              deedsSignDateForm.setFieldValue('time', e)
              deedsSignDateForm.submitForm()
            }}
            value={deedsSignDateForm.values.time}
            customIcon={<Icon name={IconName.TIME} type={IconType.LINE} className={styles.iconButton} />}
          />
        </FormikProvider>
      </div>

      <hr className={styles.divider} />

      <Text type="headline" weight="bold" className={styles.ownerDataTitle}>
        Legal aspects
      </Text>

      <div className={styles.fileInput}>
        <Text type="body">Upload filled document</Text>

        {isLegalFileAreaVisible && (
          <FileArea
            fileTypes={[FileType.PDF, FileType.IMAGE]}
            status={legalStatus}
            name="document"
            onChange={(files) => {
              setIsLegalFileAreaVisible(false)
              setLegalStatus(StatusFileArea.ACCEPTED)
              legalDocumentForm.setFieldValue('document', files[0])
              legalDocumentForm.submitForm()
            }}
            errorMessage={(legalDocumentForm.touched.document && (legalDocumentForm.errors.document as string)) || ''}
          />
        )}

        {!isLegalFileAreaVisible && (
          <FileContainer
            onReplaceFile={() => {
              setIsLegalFileAreaVisible(true)
              legalDocumentForm.setFieldValue('document', undefined)
              setLegalStatus(StatusFileArea.EMPTY)
            }}
            fileUrl={getDocumentUrl(purchase.id, lastLegalDocument?.document.id || '') || ''}
          />
        )}

        <Checkbox
          onChange={async () => {
            if (dataConfirmation2) return
            handleCheckboxClick(DealflowStatusEnum.QA)
            setDataConfirmation(true)
          }}
          className={styles.checkbox}
          checked={dataConfirmation}
          disabled={isLegalCheckboxDisabled}
          label="The property is free of legal charges."
          value="dataConfirmation"
          name="dataConfirmation"
          weight="bold"
          size="small"
        />
      </div>

      <hr className={styles.divider} />

      <Text type="headline" weight="bold" className={styles.ownerDataTitle}>
        Technical inspection
      </Text>

      <div className={styles.fileInput}>
        <Text type="body">Upload filled document</Text>

        {isQaFileAreaVisible && (
          <FileArea
            fileTypes={[FileType.PDF, FileType.IMAGE]}
            status={QaStatus}
            name="document"
            onChange={(files) => {
              setIsQaFileAreaVisible(false)
              setQaStatus(StatusFileArea.ACCEPTED)
              qaDocumentForm.setFieldValue('document', files[0])
              qaDocumentForm.submitForm()
            }}
            errorMessage={(qaDocumentForm.touched.document && (qaDocumentForm.errors.document as string)) || ''}
          />
        )}

        {!isQaFileAreaVisible && (
          <FileContainer
            onReplaceFile={() => {
              setIsQaFileAreaVisible(true)
              qaDocumentForm.setFieldValue('document', undefined)
              setQaStatus(StatusFileArea.EMPTY)
            }}
            fileUrl={getDocumentUrl(purchase.id, lastQaDocument?.document.id || '') || ''}
          />
        )}

        <Checkbox
          onChange={async () => {
            if (dataConfirmation2) return
            handleCheckboxClick(DealflowStatusEnum.CLOSING_PURCHASE)
            setDataConfirmation2(true)
          }}
          className={styles.checkbox}
          checked={dataConfirmation2}
          disabled={isQaCheckboxDisabled}
          label="The property is free of technical problems"
          value="dataConfirmation2"
          name="dataConfirmation2"
          weight="bold"
          size="small"
        />
      </div>

      <hr className={styles.divider} />

      <Text type="headline" weight="bold" className={styles.ownerDataTitle}>
        Upload the signed deeds and confirm that Libeen has purchased the property
      </Text>

      <div className={styles.fileInput}>
        <Text type="body">Upload filled document</Text>

        {isDeedsFileAreaVisible && (
          <FileArea
            fileTypes={[FileType.PDF, FileType.IMAGE]}
            status={deedsStatus}
            name="document"
            onChange={(files) => {
              setIsDeedsFileAreaVisible(false)
              setDeedsStatus(StatusFileArea.ACCEPTED)
              deedsDocumentForm.setFieldValue('document', files[0])
              deedsDocumentForm.submitForm()
            }}
            errorMessage={(deedsDocumentForm.touched.document && (deedsDocumentForm.errors.document as string)) || ''}
          />
        )}

        {!isDeedsFileAreaVisible && (
          <FileContainer
            onReplaceFile={() => {
              setIsDeedsFileAreaVisible(true)
              deedsDocumentForm.setFieldValue('document', undefined)
              setDeedsStatus(StatusFileArea.EMPTY)
            }}
            fileUrl={getDocumentUrl(purchase.id, lastDeedsDocument?.document.id || '') || ''}
          />
        )}

        <Checkbox
          onChange={async () => {
            if (dataConfirmation3) return
            handleCheckboxClick(DealflowStatusEnum.KEY_DELIVERY)
            setDataConfirmation3(true)
          }}
          className={styles.checkbox}
          checked={dataConfirmation3}
          disabled={isDeedsCheckboxDisabled}
          label="Deeds have been signed and Libeen has bought the property."
          value="dataConfirmation3"
          name="dataConfirmation3"
          weight="bold"
          size="small"
        />
      </div>
    </div>
  )
}
