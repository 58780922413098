import { GetNeighborhoods } from '../domain/GetNeighborhoods'
import { Neighborhood } from '../../model/Neighborhood'
import { NeighborhoodTag } from '../../model/NeighborhoodTag'

export class GetNeighborhoodsFake implements GetNeighborhoods {
  execute(): Promise<Neighborhood[]> {
    return Promise.resolve([
      {
        uuid: 'a-uuid-1',
        name: 'Tetuan',
        description: 'Default barrio',
        image: 'image.png',
        tags: [NeighborhoodTag.PARK],
      },
      {
        uuid: 'a-uuid-2',
        name: 'Chamberí',
        description: 'Default barrio',
        image: 'image.png',
        tags: [NeighborhoodTag.PARK],
      },
      {
        uuid: 'a-uuid-3',
        name: 'Moncloa',
        description: 'Default barrio',
        image: 'image.png',
        tags: [NeighborhoodTag.PARK],
      },
      {
        uuid: 'a-uuid-4',
        name: 'Retiro',
        description: 'Default barrio',
        image: 'image.png',
        tags: [NeighborhoodTag.PARK],
      },
      {
        uuid: 'a-uuid-5',
        name: 'Centro',
        description: 'Default barrio',
        image: 'image.png',
        tags: [NeighborhoodTag.PARK],
      },
      {
        uuid: 'a-uuid-6',
        name: 'Salamanca',
        description: 'Default barrio',
        image: 'image.png',
        tags: [NeighborhoodTag.PARK],
      },
    ])
  }
}
