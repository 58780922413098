import { HttpClientNode } from '../utils/http-client/HttpClientNode'
import { Repositories } from './domain/Repositories'
import { RepositoriesFake } from './fake/RepositoriesFake'
import { RepositoriesHttp } from './http/RepositoriesHttp'

export class RepositoriesFactory {
  static getInstance(accessToken?: string): Repositories {
    return this.createHttpRepositories(accessToken)
  }

  static createFakeRepositories() {
    return new RepositoriesFake()
  }

  static createHttpRepositories(accessToken?: string) {
    return new RepositoriesHttp(new HttpClientNode(accessToken))
  }
}
