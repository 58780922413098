import { Login, LoginError } from '../domain/Login'
import { RepositoryError } from '../domain/RepositoryError'

export class LoginFake implements Login {
  private readonly oauthError: boolean

  static thatResolvesSuccessfully() {
    return new LoginFake()
  }

  static thatRejectsWithOauthError() {
    return new LoginFake({ oauthError: true })
  }

  constructor({ oauthError = false }: { oauthError?: boolean } = {}) {
    this.oauthError = oauthError
  }

  async execute(): Promise<void> {
    if (this.oauthError) {
      throw new RepositoryError(LoginError.AUTH_ERROR, 'Debug message')
    }
  }
}
