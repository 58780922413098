import { Owner } from '../model/Owner'
import { Documentation, EmploymentTypes, IdentityTypes } from '../model/Documentation'
import { DocumentationBuilder } from './DocumentationBuilder'
import { DocumentBuilder } from './DocumentBuilder'

export class OwnerBuilder {
  private owner: Owner

  static withDNIAndPayroll() {
    return new OwnerBuilder()
      .withDocumentation([
        new DocumentationBuilder('dni-documentation')
          .withDocumentationType(IdentityTypes.DNI)
          .withDocuments([new DocumentBuilder('dni-front').build(), new DocumentBuilder('dni-back').build()])
          .build(),
        new DocumentationBuilder('payroll-documentation')
          .withDocumentationType(EmploymentTypes.PAYROLL)
          .withDocuments([
            new DocumentBuilder('payroll-05-2020').build(),
            new DocumentBuilder('payroll-06-2020').build(),
            new DocumentBuilder('payroll-07-2020').build(),
            new DocumentBuilder('test-slug3').build(),
          ])
          .build(),
      ])
      .build()
  }

  constructor(slugId = '78a650d68a49f4') {
    this.owner = {
      slugId,
      name: 'Aitor',
      email: 'alonso@renault.com',
      surname: '',
      birthDate: '',
      address: '',
      documentation: [],
    }
  }

  withName(name: string) {
    this.owner.name = name
    return this
  }

  withSurname(surname: string) {
    this.owner.surname = surname
    return this
  }

  withBirthDate(birthDate: string) {
    this.owner.birthDate = birthDate
    return this
  }

  withAddress(address: string) {
    this.owner.address = address
    return this
  }

  withDocumentation(documentation: Documentation[]) {
    this.owner.documentation = documentation
    return this
  }

  withEmail(email: string) {
    this.owner.email = email
    return this
  }

  build(): Owner {
    return this.owner
  }
}
