import { BackofficePropertyBuilder } from '../../builders/BackofficePropertyBuilder'
import { BackofficeProperties, BackofficeProperty } from '../../model/Property'
import { PropertyStatus } from '../../model/PropertyStatus'
import { removeStopWords } from '../../utils/removeStopWords'
import { GetAdminProperties } from '../domain/GetAdminProperties'

export class GetAdminPropertiesFake implements GetAdminProperties {
  private readonly properties: BackofficeProperty[]

  constructor(
    properties = [
      new BackofficePropertyBuilder().withTitle('Monteleón 33').withPricePerMonth(1200).withStatus(PropertyStatus.SOLD).build(),
      new BackofficePropertyBuilder().withTitle('Vallehermoso 48').withPricePerMonth(1300).withStatus(PropertyStatus.DRAFT).build(),
      new BackofficePropertyBuilder().withTitle('Isac Peral 78').withPricePerMonth(1400).build(),
      new BackofficePropertyBuilder().withTitle('Sagasta 26').withPricePerMonth(1300).withStatus(PropertyStatus.RESERVED).build(),
      new BackofficePropertyBuilder().withTitle('Castellana 59').withPricePerMonth(1300).withStatus(PropertyStatus.AVAILABLE).build(),
    ]
  ) {
    this.properties = properties
  }

  private propertyToPlainText(property: BackofficeProperty): string {
    const addressText = Object.values(property.address).join(' ')
    const newProperty = { addressText, ...property }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete newProperty.address
    return Object.values(newProperty).join(' ').replace(/,/g, ' ')
  }

  private matchsTextSearch(property: BackofficeProperty, searchText: string, language?: 'es' | 'en') {
    const propertyText = this.propertyToPlainText(property)
    const propertyWords = removeStopWords(propertyText.toUpperCase(), language)
    const searchTerms = removeStopWords(searchText.toUpperCase(), language)
    const result = propertyWords.some((term) => searchTerms.includes(term))
    return result
  }

  async execute(search?: string, paginate?: { offset: number; limit: number }, statusFilter?: PropertyStatus): Promise<BackofficeProperties> {
    let properties = search ? this.properties.filter((property) => this.matchsTextSearch(property, search)) : this.properties
    if (statusFilter) properties = properties.filter((property) => property.status === statusFilter)
    const totalCount = properties.length
    if (paginate) {
      if (paginate.offset && paginate.offset < properties.length) {
        properties = properties.slice(paginate.offset)
      }
      if (paginate.limit < properties.length) {
        properties = properties.slice(0, paginate.limit)
      }
    }
    return { properties, totalCount }
  }
}
