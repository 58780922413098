import { DealflowShowWithPropertyResponseDto, DocumentationResponseDto } from 'repositories/http/swagger/schema'
import { BackofficeDealflowDetailBuilder } from 'builders/BackofficeDealflowDetailBuilder'
import { PaymentType } from 'model/PaymentType'
import { DealflowStatusEnum } from 'model/DealflowStatus'
import { EmploymentTypes, IdentityTypes } from 'model/Documentation'
import { GetPurchaseAdmin } from '../domain/GetPurchaseAdmin'
import { PaymentBuilder } from '../../builders/PaymentBuilder'
import { OwnerBuilder } from '../../builders/OwnerBuilder'
import { DocumentationBuilder } from '../../builders/DocumentationBuilder'
import { DocumentBuilder } from '../../builders/DocumentBuilder'

function getSamplePurchase() {
  const owner = new OwnerBuilder('paco-slug')
    .withDocumentation([
      new DocumentationBuilder('paco-dni')
        .withNumberOrName('45352515P')
        .withDocumentationType(IdentityTypes.DNI)
        .withDocuments([new DocumentBuilder('dni-front').build(), new DocumentBuilder('dni-back').build()])
        .build(),
      new DocumentationBuilder('paco-payroll')
        .withDocumentationType(EmploymentTypes.PAYROLL)
        .withDocuments([
          new DocumentBuilder('payroll-slug0').build(),
          new DocumentBuilder('payroll-slug1').build(),
          new DocumentBuilder('payroll-slug2').build(),
          new DocumentBuilder('antiquity-slug3').build(),
        ])
        .build(),
    ])
    .withName('Paco')
    .withSurname('Nocerlo')
    .withAddress('San Mateo')
    .withBirthDate('02-07-2001')
    .build()
  const owner2 = new OwnerBuilder('florentino-slug')
    .withDocumentation([
      new DocumentationBuilder('florentino-driving-license')
        .withNumberOrName('45352513V')
        .withDocumentationType(IdentityTypes.DRIVING_LICENSE)
        .withDocuments([new DocumentBuilder('florentino-driving-license-file').build()])
        .build(),
      new DocumentationBuilder('florentino-insurance-receipt')
        .withDocumentationType(EmploymentTypes.SOCIAL_INSURANCE_RECEIPT)
        .withDocuments([
          new DocumentBuilder('test-slug0').build(),
          new DocumentBuilder('test-slug1').build(),
          new DocumentBuilder('test-slug2').build(),
        ])
        .build(),
    ])
    .withName('Florentino')
    .withSurname('Perez')
    .withAddress('Sella')
    .withBirthDate('02-07-2003')
    .build()
  return new BackofficeDealflowDetailBuilder('purchase-slug')
    .withPropertySlug('casa-azul')
    .withOwners([
      { ...owner, documentation: owner.documentation as DocumentationResponseDto[], id: '', userId: '', phone: '', birthdate: '' },
      { ...owner2, documentation: owner2.documentation as DocumentationResponseDto[], id: '', userId: '', phone: '', birthdate: '' },
    ])
    .withPayments([{ ...new PaymentBuilder('reservation-slug').build(), type: PaymentType.ENTRY, createdAt: '' }])
    .withStatus(DealflowStatusEnum.PENDING_USER_DATA)
    .build()
}

export class GetPurchaseAdminFake implements GetPurchaseAdmin {
  private readonly purchaseDetails: DealflowShowWithPropertyResponseDto

  constructor(purchaseDetails = getSamplePurchase()) {
    this.purchaseDetails = purchaseDetails
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  execute(purchaseSlug: string): Promise<DealflowShowWithPropertyResponseDto> {
    return Promise.resolve(this.purchaseDetails)
  }
}
