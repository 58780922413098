import { LocaleKeys } from '../i18n/keys'

export enum VendorType {
  LIBEEN = 'LIBEEN',
  EXTERNAL = 'EXTERNAL',
}

export function translateVendorType(t: LocaleKeys, vendorType: VendorType): string {
  const translation: { [key in VendorType]: string } = {
    [VendorType.LIBEEN]: t.common.vendorType.libeen,
    [VendorType.EXTERNAL]: t.common.vendorType.external,
  }

  return translation[vendorType]
}
