import { HttpClient } from '../../utils/http-client/HttpClient'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { CancelPurchase } from '../domain/CancelPurchase'

export class CancelPurchaseHttp implements CancelPurchase {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(slug: string): Promise<void> {
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/admin/purchases/${slug}/cancel`
    const options = { headers: {}, params: {} }

    return this.httpClient.post<void>(url, options)
  }
}
