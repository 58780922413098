import { useTranslation } from 'hooks/useTranslation'
import { useEffect } from 'react'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import * as CookieConsent from 'vanilla-cookieconsent'
import { esTranslation, enTranslation } from './translations'

export const CookieConsentBanner = () => {
  const { lang } = useTranslation()

  useEffect(() => {
    CookieConsent.run({
      onConsent: () => {
        window.fbq('consent', 'grant')
      },
      onChange: ({ changedCategories }) => {
        if (!changedCategories.includes('analytics')) return

        const isAnalyticsEnabled = CookieConsent.acceptedCategory('analytics')

        window.fbq('consent', isAnalyticsEnabled ? 'grant' : 'revoke')
      },
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        analytics: {
          enabled: true,
        },
      },
      language: {
        default: lang,
        translations: {
          en: enTranslation,
          es: esTranslation,
        },
      },
    })
  }, [lang])

  return null
}
