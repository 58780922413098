export enum AuthRoleType {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

/**
 * Los parametros opcionales serán tipados como null en el modelo del frontend
 * porque Next.js da un error al tratar de serializar un objeto con parametros con undefined
 * y eso lo hacemos en el SSR. https://github.com/vercel/next.js/discussions/11209
 */
export type User = {
  slug: string
  name: string | null
  role: AuthRoleType.USER
  surname: string | null
  email: string
  phone: string | null
  favorites: any[]
  purchaseSlug: string | null
}
