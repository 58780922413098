import { BackofficePropertyDetail } from '../../../model/Property'
import { PropertyEnergeticCertification } from '../../../model/PropertyEnergeticCertification'
import { PropertyFeature } from '../../../model/PropertyFeature'
import { PropertyOrientation } from '../../../model/PropertyOrientation'
import { PropertyPosition } from '../../../model/PropertyPosition'
import { PropertyStatus } from '../../../model/PropertyStatus'
import { PropertyTag } from '../../../model/PropertyTag'
import { PropertyType } from '../../../model/PropertyType'
import { Vendor } from '../../../model/Vendor'
import { VendorType } from '../../../model/VendorType'
import { AdminPropertyDetailResponseDto } from '../swagger/schema'

export function mapBackofficePropertyDetailResponseToModel(property: AdminPropertyDetailResponseDto): BackofficePropertyDetail {
  return {
    id: property.id,
    gallery: property.gallery,
    slug: property.slug,
    IBIYearlyCost: property.IBIYearlyCost,
    adminEmail: property.adminEmail,
    adminName: property.adminName,
    adminPhone: property.adminPhone,
    builtSurface: property.builtSurface,
    city: property.city,
    communityMonthlyCost: property.communityMonthlyCost,
    description: property.description,
    energeticCertification: property.energeticCertification as PropertyEnergeticCertification,
    entryPayment: property.entryPayment,
    featureTags: property.featureTags as PropertyFeature[],
    latitude: property.latitude,
    longitude: property.longitude,
    neighborhoodUuid: property.neighborhoodUuid || null, // TODO: solve this
    orientation: property.orientation as PropertyOrientation,
    ownerEmail: property.ownerEmail,
    ownerName: property.ownerName,
    ownerPhone: property.ownerPhone,
    postalCode: property.postalCode,
    presidentEmail: property.presidentEmail,
    presidentName: property.presidentName,
    presidentPhone: property.presidentPhone,
    priceOfCost: property.priceOfCost,
    priceToPay: property.priceToPay,
    propertyPosition: property.position as PropertyPosition,
    propertyTags: property.propertyTags as PropertyTag[],
    propertyType: property.type as PropertyType,
    referenceId: property.referenceId,
    region: property.region,
    subNeighborhood: property.subNeighborhood,
    utilSurface: property.utilSurface,
    vendorType: property.vendorType as VendorType,
    status: property.status as PropertyStatus,
    addressText: property.address,
    title: property.title,
    pricePerMonth: property.pricePerMonth,
    downPayment: property.entryPayment,
    surface: 0, // TODO: solve this
    bedroomsAmount: property.bedroomsAmount || 0, // TODO: solve this
    bathroomsAmount: property.bathroomsAmount || 0, // TODO: solve this
    vendor: property.vendor as Vendor,
    savings: property.savings,
    monthlySavings: property.monthlySavings,
    floor: property.floor || '', // TODO: solve this
    referenceLink: property.referenceLink || '', // TODO: solve this
    tryBeforeYouBuyIt: property.tryBeforeYouBuyIt,
    wholeRentalForBuying: property.wholeRentalForBuying,
    yearPlan: property.yearPlan,
    specialOffer: property.specialOffer,
  }
}
