// Next imports
import Script from 'next/script'

const GOOGLE_ADS_ID = 'AW-482667217'

export const GoogleAd = () => {
  return (
    <>
      <Script strategy="beforeInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ADS_ID}`} />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GOOGLE_ADS_ID}', {
              'debug_mode': false,
            });
          `,
        }}
      />
    </>
  )
}

export const NoScriptGoogleAnalytics = () => {
  return null
}
