import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { FormDataObject } from '../../utils/http-client/HttpClientNode'
import { UpdatePurchaseFiles, UpdatePurchaseFilesData } from '../domain/UpdatePurchaseFiles'

export class UpdatePurchaseFilesHttp implements UpdatePurchaseFiles {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  execute(purchaseSlug: string, ownerSlug: string, data: UpdatePurchaseFilesData[]): Promise<unknown>[] {
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/purchases/${purchaseSlug}/owners/${ownerSlug}/documentations`

    return data.map(async (document) => {
      const requestBody: FormDataObject[] = [{ name: 'type', value: document.type }]
      if (document.documentationNumber) {
        requestBody.push({ name: 'documentationNumber', value: document.documentationNumber })
      }
      const files = document.files.map((file) => {
        return { name: 'files', value: file } as FormDataObject
      })

      await this.httpClient.postFiles(url, requestBody.concat(files))
    })
  }
}
