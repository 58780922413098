import { UpdatePropertyDraftImages } from '../domain/UpdatePropertyDraftImages'
import { ImagePath } from '../../model/ImagePath'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { FormDataObject } from '../../utils/http-client/HttpClientNode'

export class UpdatePropertyDraftImagesHttp implements UpdatePropertyDraftImages {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  execute(slug: string, draftPropertyImages: File[]): Promise<ImagePath[]> {
    const url = `${getPublicRuntimeConfig().API_ENDPOINT}/admin/property_drafts/${slug}/images`
    const dataImages = draftPropertyImages.map((draftPropertyImage) => {
      return { name: 'images', value: draftPropertyImage } as FormDataObject
    })
    return this.httpClient.putFiles<ImagePath[]>(url, dataImages)
  }
}
