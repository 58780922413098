import { Owner } from '../model/Owner'
import { Payment } from '../model/Payment'
import { Purchase } from '../model/Purchase'
import { PurchasePayments } from '../model/PurchasePayments'
import { PurchaseStatus } from '../model/PurchaseStatus'
import { formatISODateToDateOnly } from '../utils/formatDate'
import { OwnerBuilder } from './OwnerBuilder'

export class PurchaseBuilder {
  private readonly purchase: Purchase

  constructor(slug = 'aff15ddd-819c-4809-9d93-836044bbf548') {
    const checkinDate = new Date()
    // Set te checkin month for the next month
    checkinDate.setMonth(checkinDate.getMonth() + 1)

    const mainOwner = new OwnerBuilder().build()

    const defaultPayments: PurchasePayments = {
      reservationPayment: null,
      downpaymentPayment: null,
      additionalPayments: [],
      rentPayments: [],
    }

    this.purchase = {
      slug,
      propertySlug: 'cool-house-in-madrid',
      email: 'email@email.com',
      phoneNumber: '612345789',
      checkinDate: formatISODateToDateOnly(checkinDate),
      totalOwners: 1,
      reservationFee: 2000,
      downpayment: 15000,
      status: PurchaseStatus.STEP_ONE,
      owners: [mainOwner],
      payments: defaultPayments,
      totalSaved: 0,
      totalSavedPercentage: 0,
      totalSavings: 75000,
    }
  }

  withTotalOwners(totalOwners: number) {
    this.purchase.totalOwners = totalOwners
    return this
  }

  withOwners(owners: Owner[]) {
    this.purchase.owners = owners
    return this
  }

  withReservationFee(reservationFee: number) {
    this.purchase.reservationFee = reservationFee
    return this
  }

  withPayments(payments: PurchasePayments) {
    this.purchase.payments = payments
    return this
  }

  withReservationPayment(payment: Payment) {
    this.purchase.payments.reservationPayment = payment
    return this
  }

  withDownpaymentPayment(payment: Payment) {
    this.purchase.payments.downpaymentPayment = payment
    return this
  }

  withStatus(status: PurchaseStatus) {
    this.purchase.status = status
    return this
  }

  build(): Purchase {
    return this.purchase
  }

  withErrorMessage(errorMessage: string) {
    this.purchase.errorMessage = errorMessage
    return this
  }
}
