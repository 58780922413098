import { useMutation, UseMutationOptions } from 'react-query'
import { ApiClient } from 'services/ApiClient'
import { FetchOptions } from 'utils/createApiClient'

export type DealflowDocumentType =
  | 'financialCheck'
  | 'contract'
  | 'signedContract'
  | 'legal'
  | 'technicalInspection'
  | 'deeds'
  | 'keyReceipt'
  | 'unsignedContract'
  | 'downPayment'

type UploadDocumentParams = { dealflowId: string; type: DealflowDocumentType; files: File }

export const uploadDealflowDocument = ({ dealflowId, type, files }: UploadDocumentParams, options?: FetchOptions): Promise<void> => {
  const formData = new FormData()
  formData.append('files', files)
  formData.append('type', type)
  return ApiClient.post<void>(`/admin/dealflows/${dealflowId}/documentations`, {
    data: formData,
    ...options,
  })
}

export const useUploadDealflowDocument = (options?: Omit<UseMutationOptions<void, unknown, UploadDocumentParams, unknown>, 'mutationFn'>) => {
  return useMutation(
    async (params: UploadDocumentParams) => {
      return uploadDealflowDocument(params)
    },
    {
      ...options,
    }
  )
}
