import { LocaleKeys } from '../i18n/keys'

export enum PropertyType {
  HOUSE = 'HOUSE',
  FLAT = 'FLAT',
  DUPLEX = 'DUPLEX',
}

export function translatePropertyType(t: LocaleKeys, propertyType: PropertyType): string {
  const propertyTypeTranslation: { [key in PropertyType]: string } = {
    [PropertyType.HOUSE]: t.common.propertyType.house,
    [PropertyType.FLAT]: t.common.propertyType.flat,
    [PropertyType.DUPLEX]: t.common.propertyType.duplex,
  }

  return propertyTypeTranslation[propertyType]
}
