import { DocumentationIdentity, DocumentationEmployment, Documentation } from 'model/Documentation'
import React, { FC } from 'react'
import { Text } from 'components/Text/Text'
import { HomeOwnerResponseDto } from 'repositories/http/swagger/schema'
import { findDocumentType } from 'utils/dealflowDetailUtils'
import { EmploymentDocumentation } from '../EmploymentDocumentation/EmploymentDocumentation'
import { IdentityDocumentation } from '../IdentityDocumentatiton/IdentityDocumentation'
import { LabeledText } from '../LabeledText/LabeledText'

import styles from './HomeownerDataDrawer.module.scss'

type HomeownerDataDrawerProps = {
  activeOwner: HomeOwnerResponseDto
  ownerIndex: number
  purchaseId: string
}

export const HomeownerDataDrawer: FC<HomeownerDataDrawerProps> = ({ activeOwner, ownerIndex, purchaseId }) => {
  const [identityDocs, employmentDocs] = findDocumentType(activeOwner.documentation as Documentation[])

  return (
    <div className={styles.container}>
      <div>
        <Text type="h3" weight="bold">
          Homeowner {ownerIndex + 1}
        </Text>
        <Text type="headline" weight="bold" className={styles.ownerDataTitle}>
          Personal data
        </Text>
        <div className={styles.ownerDataContainer}>
          <div className={styles.labelOwnerInformationContainer}>
            <LabeledText field="Legal name" value={activeOwner.name} />

            <LabeledText field="Legal surname" value={activeOwner.surname} />

            <LabeledText field="Email" value={activeOwner.email} />

            <LabeledText field="Phone" value={activeOwner.phone} />

            <LabeledText field="Date of birth" value={activeOwner.birthdate} />

            <LabeledText field="Current residence" value={activeOwner.address} />

            {identityDocs && <IdentityDocumentation identityDocumentation={identityDocs as DocumentationIdentity} purchaseSlug={purchaseId} />}
          </div>

          <div className={styles.employmentInformationContainer}>
            {employmentDocs && (
              <EmploymentDocumentation
                purchaseSlug={purchaseId}
                workSituation={activeOwner.workSituation}
                employmentDocumentation={employmentDocs as DocumentationEmployment}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
