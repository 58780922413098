import classNames from 'classnames'
import React, { FC } from 'react'
import { useDropzone } from 'react-dropzone'
import { FramedIcon } from '../../../../DataDisplay/FramedIcon/FramedIcon'
import { Icon, IconName } from '../../../../DataDisplay/Icon/Icon'
import { Typography } from '../../../../DataDisplay/Typography/Typography'
import { FormLabel } from '../../../Label/FormLabel'
import styles from './FileDropzone.module.scss'

export enum FileType {
  IMAGE = 'image/*',
  PDF = 'application/pdf',
  CSV = 'text/csv',
}

export enum StatusFileArea {
  EMPTY,
  ACCEPTED,
  REJECTED,
}

type FileDropzoneProps = {
  onDrop: (acceptedFiles: File[]) => void
  title: string
  subtitle?: string
  fileTypes: FileType[]
  status?: StatusFileArea
  name: string
  className?: string
}

const fileTypeToIconName: { [key in FileType]: IconName } = {
  [FileType.IMAGE]: IconName.IMAGE_ADD,
  [FileType.PDF]: IconName.FILE_LIST,
  [FileType.CSV]: IconName.FILE_LIST,
}

export const FileDropzone: FC<FileDropzoneProps> = ({ onDrop, title, name, subtitle, fileTypes, status, className }) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
  })

  const iconName = status === StatusFileArea.ACCEPTED ? IconName.CHECK : IconName.CLOSE
  const roundIcon = status === StatusFileArea.ACCEPTED ? styles.roundIconPass : styles.roundIconReject

  const fileTypesIconSelection = () => {
    if (fileTypes) {
      const fileTypesLength = fileTypes.length
      if (fileTypesLength > 1) {
        return fileTypeToIconName[FileType.IMAGE]
      }
      return fileTypeToIconName[fileTypes[0]]
    }
    return fileTypeToIconName[FileType.IMAGE]
  }

  return (
    <div
      data-testid="file-input"
      {...getRootProps({
        className: classNames(styles.container, isDragActive && styles.isDragActive, isDragReject && styles.isDragReject, className),
      })}
    >
      <FormLabel text="" htmlFor={name} />
      <input {...getInputProps()} id={name} name={name} />
      <div className={styles.dropzone}>
        {status ? <FramedIcon name={iconName} size={32} className={roundIcon} /> : <Icon name={fileTypesIconSelection()} size={32} />}

        <div className={styles.textsContainer}>
          <Typography className={styles.titleText}>{title}</Typography>
          <Typography className={styles.subtitleText}>{subtitle}</Typography>
        </div>
      </div>
    </div>
  )
}
