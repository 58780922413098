import { GetSmarthouser } from 'repositories/domain/GetSmarthouser'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { CreatePropertyDraft } from '../domain/CreatePropertyDraft'
import { CreatePropertyDraftsUpload } from '../domain/CreatePropertyDraftsUpload'
import { CreatePurchase } from '../domain/CreatePurchase'
import { DeleteAdminPropertyDraft } from '../domain/DeleteAdminPropertyDraft'
import { GeocodeAddress } from '../domain/GeocodeAddress'
import { GetAdminProperties } from '../domain/GetAdminProperties'
import { GetAdminProperty } from '../domain/GetAdminProperty'
import { GetAdminPropertyDraft } from '../domain/GetAdminPropertyDraft'
import { GetAdminPurchases } from '../domain/GetAdminPurchases'
import { GetProperties } from '../domain/GetProperties'
import { GetPropertyDetails } from '../domain/GetPropertyDetails'
import { GetPurchase } from '../domain/GetPurchase'
import { GetUser } from '../domain/GetUser'
import { Login } from '../domain/Login'
import { PublishAdminPropertyDraft } from '../domain/PublishAdminPropertyDraft'
import { Repositories } from '../domain/Repositories'
import { UnpublishAdminProperty } from '../domain/UnpublishAdminProperty'
import { UpdateAdminPropertyDraft } from '../domain/UpdateAdminPropertyDraft'
import { UpdatePaymentReceipt } from '../domain/UpdatePaymentReceipt'
import { UpdatePropertyDraftImages } from '../domain/UpdatePropertyDraftImages'
import { UpdatePurchase } from '../domain/UpdatePurchase'
import { UpdatePurchaseFiles } from '../domain/UpdatePurchaseFiles'
import { CreatePropertyDraftHttp } from './CreatePropertyDraftHttp'
import { CreatePropertyDraftsUploadHttp } from './CreatePropertyDraftsUploadHttp'
import { CreatePurchaseHttp } from './CreatePurchaseHttp'
import { DeleteAdminPropertyDraftHttp } from './DeleteAdminPropertyDraftHttp'
import { GeocodeAddressGoogle } from './GeocodeAddressGoogle'
import { GetAdminPropertiesHttp } from './GetAdminPropertiesHttp'
import { GetAdminPropertyDraftHttp } from './GetAdminPropertyDraftHttp'
import { GetAdminPropertyHttp } from './GetAdminPropertyHttp'
import { GetAdminPurchasesHttp } from './GetAdminPurchasesHttp'
import { GetPropertiesHttp } from './GetPropertiesHttp'
import { GetPropertyDetailsHttp } from './GetPropertyDetailsHttp'
import { GetPurchaseHttp } from './GetPurchaseHttp'
import { GetUserHttp } from './GetUserHttp'
import { LoginHttp } from './LoginHttp'
import { PublishAdminPropertyDraftHttp } from './PublishAdminPropertyDraftHttp'
import { UnpublishAdminPropertyHttp } from './UnpublishAdminPropertyHttp'
import { UpdateAdminPropertyDraftHttp } from './UpdateAdminPropertyDraftHttp'
import { UpdatePaymentReceiptHttp } from './UpdatePaymentReceiptHttp'
import { UpdatePropertyDraftImagesHttp } from './UpdatePropertyDraftImagesHttp'
import { UpdatePurchaseFilesHttp } from './UpdatePurchaseFilesHttp'
import { UpdatePurchaseHttp } from './UpdatePurchaseHttp'
import { GetPurchaseAdmin } from '../domain/GetPurchaseAdmin'
import { ValidatePurchaseCurrentStepHttp } from './ValidatePurchaseCurrentStepHttp'
import { GetPurchaseAdminHttp } from './GetPurchaseAdminHttp'
import { RejectPurchaseCurrentStepHttp } from './RejectPurchaseCurrentStepHttp'
import { DownloadPurchaseDocumentation } from '../domain/DownloadPurchaseDocumentation'
import { DownloadPurchaseDocumentationHttp } from './DownloadPurchaseDocumentationHttp'
import { ContinuePurchase } from '../domain/ContinuePurchase'
import { ContinuePurchaseHttp } from './ContinuePurchaseHttp'
import { FinishPurchase } from '../domain/FinishPurchase'
import { FinishPurchaseHttp } from './FinishPurchaseHttp'
import { CancelPurchase } from '../domain/CancelPurchase'
import { CancelPurchaseHttp } from './CancelPurchaseHttp'
import { GetNeighborhoods } from '../domain/GetNeighborhoods'
import { GetNeighborhoodsHttp } from './GetNeighborhoodsHttp'
import { GetCities } from '../domain/GetCities'
import { GetCitiesHttp } from './GetCitiesHttp'
import { GetAdminCitiesHttp } from './GetAdminCitiesHttp'
import { GetAdminCities } from '../domain/GetAdminCities'
import { GetSmarthouserHttp } from './GetSmarthouserHttp'

export class RepositoriesHttp implements Repositories {
  readonly getAdminProperties: GetAdminProperties['execute']

  readonly getProperties: GetProperties['execute']

  readonly getPropertyDetails: GetPropertyDetails['execute']

  readonly login: Login['execute']

  readonly createPropertyDraft: CreatePropertyDraft['execute']

  readonly createPropertyDraftsUpload: CreatePropertyDraftsUpload['execute']

  readonly getAdminPropertyDraft: GetAdminPropertyDraft['execute']

  readonly updateAdminProperyDraft: UpdateAdminPropertyDraft['execute']

  readonly publishAdminPropertyDraft: PublishAdminPropertyDraft['execute']

  readonly deleteAdminPropertyDraft: DeleteAdminPropertyDraft['execute']

  readonly getAdminProperty: GetAdminProperty['execute']

  readonly unpublishAdminProperty: UnpublishAdminProperty['execute']

  readonly updateAdminProperyDraftImages: UpdatePropertyDraftImages['execute']

  readonly geocodeAddress: GeocodeAddress['execute']

  readonly getUser: GetUser['execute']

  readonly createPurchase: CreatePurchase['execute']

  readonly getPurchase: GetPurchase['execute']

  readonly updatePurchase: UpdatePurchase['execute']

  readonly updatePurchaseFiles: UpdatePurchaseFiles['execute']

  readonly updatePaymentReceipt: UpdatePaymentReceipt['execute']

  readonly getAdminPurchases: GetAdminPurchases['execute']

  readonly getPurchaseAdmin: GetPurchaseAdmin['execute']

  readonly validatePurchaseCurrentStep: ValidatePurchaseCurrentStepHttp['execute']

  readonly rejectPurchaseCurrentStep: RejectPurchaseCurrentStepHttp['execute']

  readonly downloadPurchaseDocumentation: DownloadPurchaseDocumentation['execute']

  readonly continuePurchase: ContinuePurchase['execute']

  readonly finishPurchase: FinishPurchase['execute']

  readonly cancelPurchase: CancelPurchase['execute']

  readonly getNeighborhoods: GetNeighborhoods['execute']

  readonly getCities: GetCities['execute']

  readonly getAdminCities: GetAdminCities['execute']

  readonly getSmarthouser: GetSmarthouser['execute']

  constructor(httpClient: HttpClient) {
    const getAdminProperties = new GetAdminPropertiesHttp(httpClient)
    const getProperties = new GetPropertiesHttp(httpClient)
    const getPropertyDetails = new GetPropertyDetailsHttp(httpClient)
    const login = new LoginHttp(httpClient)
    const createPropertyDraft = new CreatePropertyDraftHttp(httpClient)
    const createPropertyDraftsUpload = new CreatePropertyDraftsUploadHttp(httpClient)
    const getAdminPropertyDraft = new GetAdminPropertyDraftHttp(httpClient)
    const updateAdminProperyDraft = new UpdateAdminPropertyDraftHttp(httpClient)
    const publishAdminPropertyDraft = new PublishAdminPropertyDraftHttp(httpClient)
    const deleteAdminPropertyDraft = new DeleteAdminPropertyDraftHttp(httpClient)
    const getAdminProperty = new GetAdminPropertyHttp(httpClient)
    const unpublishAdminProperty = new UnpublishAdminPropertyHttp(httpClient)
    const updateAdminProperyDraftImages = new UpdatePropertyDraftImagesHttp(httpClient)
    const geocodeAddress = new GeocodeAddressGoogle(httpClient)
    const getUser = new GetUserHttp(httpClient)
    const createPurchase = new CreatePurchaseHttp(httpClient)
    const getPurchase = new GetPurchaseHttp(httpClient)
    const updatePurchase = new UpdatePurchaseHttp(httpClient)
    const updatePurchaseFiles = new UpdatePurchaseFilesHttp(httpClient)
    const updatePaymentReceipt = new UpdatePaymentReceiptHttp(httpClient)
    const getAdminPurhchases = new GetAdminPurchasesHttp(httpClient)
    const getPurchaseAdmin = new GetPurchaseAdminHttp(httpClient)
    const validatePurchaseCurrentStepHttp = new ValidatePurchaseCurrentStepHttp(httpClient)
    const rejectPurchaseCurrentStepHttp = new RejectPurchaseCurrentStepHttp(httpClient)
    const downloadPurchaseDocumentation = new DownloadPurchaseDocumentationHttp(httpClient)
    const continuePurchase = new ContinuePurchaseHttp(httpClient)
    const finishPurchase = new FinishPurchaseHttp(httpClient)
    const cancelPurchase = new CancelPurchaseHttp(httpClient)
    const getNeighborhoods = new GetNeighborhoodsHttp(httpClient)
    const getCities = new GetCitiesHttp(httpClient)
    const getAdminCities = new GetAdminCitiesHttp(httpClient)
    const getSmarthouser = new GetSmarthouserHttp(httpClient)

    this.getProperties = getProperties.execute.bind(getProperties)
    this.getPropertyDetails = getPropertyDetails.execute.bind(getPropertyDetails)
    this.getAdminProperties = getAdminProperties.execute.bind(getAdminProperties)
    this.login = login.execute.bind(login)
    this.createPropertyDraft = createPropertyDraft.execute.bind(createPropertyDraft)
    this.createPropertyDraftsUpload = createPropertyDraftsUpload.execute.bind(createPropertyDraftsUpload)
    this.getAdminPropertyDraft = getAdminPropertyDraft.execute.bind(getAdminPropertyDraft)
    this.updateAdminProperyDraft = updateAdminProperyDraft.execute.bind(updateAdminProperyDraft)
    this.publishAdminPropertyDraft = publishAdminPropertyDraft.execute.bind(publishAdminPropertyDraft)
    this.deleteAdminPropertyDraft = deleteAdminPropertyDraft.execute.bind(deleteAdminPropertyDraft)
    this.getAdminProperty = getAdminProperty.execute.bind(getAdminProperty)
    this.unpublishAdminProperty = unpublishAdminProperty.execute.bind(unpublishAdminProperty)
    this.updateAdminProperyDraftImages = updateAdminProperyDraftImages.execute.bind(updateAdminProperyDraftImages)
    this.geocodeAddress = geocodeAddress.execute.bind(geocodeAddress)
    this.getUser = getUser.execute.bind(getUser)
    this.createPurchase = createPurchase.execute.bind(createPurchase)
    this.getPurchase = getPurchase.execute.bind(getPurchase)
    this.updatePurchase = updatePurchase.execute.bind(updatePurchase)
    this.updatePurchaseFiles = updatePurchaseFiles.execute.bind(updatePurchaseFiles)
    this.updatePaymentReceipt = updatePaymentReceipt.execute.bind(updatePaymentReceipt)
    this.getAdminPurchases = getAdminPurhchases.execute.bind(getAdminPurhchases)
    this.getPurchaseAdmin = getPurchaseAdmin.execute.bind(getPurchaseAdmin)
    this.validatePurchaseCurrentStep = validatePurchaseCurrentStepHttp.execute.bind(validatePurchaseCurrentStepHttp)
    this.rejectPurchaseCurrentStep = rejectPurchaseCurrentStepHttp.execute.bind(rejectPurchaseCurrentStepHttp)

    this.downloadPurchaseDocumentation = downloadPurchaseDocumentation.execute.bind(downloadPurchaseDocumentation)

    this.continuePurchase = continuePurchase.execute.bind(continuePurchase)
    this.finishPurchase = finishPurchase.execute.bind(finishPurchase)
    this.cancelPurchase = cancelPurchase.execute.bind(cancelPurchase)
    this.getNeighborhoods = getNeighborhoods.execute.bind(getNeighborhoods)
    this.getCities = getCities.execute.bind(getCities)
    this.getAdminCities = getAdminCities.execute.bind(getCities)
    this.getSmarthouser = getSmarthouser.execute.bind(getSmarthouser)
  }
}
