import { NeighborhoodTag } from '../model/NeighborhoodTag'
import { MarketplacePropertyDetails } from '../model/PropertyDetails'
import { PropertyFeature } from '../model/PropertyFeature'
import { PropertyStatus } from '../model/PropertyStatus'
import { PropertyTag } from '../model/PropertyTag'
import { Vendor } from '../model/Vendor'

export class MarketplacePropertyDetailsBuilder {
  private readonly propertyDetails: MarketplacePropertyDetails

  static VERTICAL_IMAGE = 'https://i.imgur.com/c4xYvbL.jpg'

  constructor(slug = Math.random().toString()) {
    this.propertyDetails = {
      id: '',
      specialOffer: undefined,
      tryBeforeYouBuyIt: false,
      wholeRentalForBuying: false,
      yearPlan: undefined,
      slug,
      images: ['https://s3-eu-west-1.amazonaws.com/squareclockdata/wp-content/uploads/2018/07/18145134/shutterstock_729468436.jpg'],
      status: PropertyStatus.AVAILABLE,
      isFavorite: false,
      address: {
        city: 'Madrid',
        neighborhood: 'Chamberi',
      },
      title: 'Vallehermoso 48',
      pricePerMonth: 1300.0,
      monthlySavings: 200.0,
      downPayment: 15927.5231,
      entryPayment: 15927.5231,
      surface: 59,
      bedroomsAmount: 2,
      bathroomsAmount: 2,
      tags: [PropertyTag.SUNNY, PropertyTag.PETS_ALLOWED, PropertyTag.SHOPPING_AREA, PropertyTag.RESTAURANTS_NEAR, PropertyTag.PUBLIC_TRANSPORT],
      features: [
        PropertyFeature.PARKING,
        PropertyFeature.CENTRAL_HEATING,
        PropertyFeature.AC,
        PropertyFeature.AREAWAY,
        PropertyFeature.POOL,
        PropertyFeature.GYM,
        PropertyFeature.ELEVATOR,
        PropertyFeature.JANITOR,
      ],
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      neighborhood: {
        uuid: 'a-uuid',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        image: 'https://i.imgur.com/fC4b2uC.jpg',
        name: 'Chamberi',
        tags: [NeighborhoodTag.PARK, NeighborhoodTag.SHOPPING, NeighborhoodTag.RESTAURANTS],
      },
      vendor: Vendor.LIBEEN,
      savings: 54126,
      totalBuyCost: 352000,
      location: {
        latitude: 40.428049,
        longitude: -3.697352,
      },
      latitude: 40.428049,
      longitude: -3.697352,
    }
  }

  withTitle(title: string) {
    this.propertyDetails.title = title
    return this
  }

  withStatus(status: PropertyStatus) {
    this.propertyDetails.status = status
    return this
  }

  withTags(tags: PropertyTag[]) {
    this.propertyDetails.tags = tags
    return this
  }

  markAsFavorite() {
    this.propertyDetails.isFavorite = true
    return this
  }

  withMainImages(images: string[]) {
    this.propertyDetails.images = images
    return this
  }

  build(): MarketplacePropertyDetails {
    return this.propertyDetails
  }
}
