import * as yup from 'yup'

yup.setLocale({
  mixed: {
    default: (attrs: any) => ({ key: 'common:validations.mixed.default', attrs }),
    required: (attrs: any) => ({ key: 'common:validations.mixed.required', attrs }),
    oneOf: (attrs: any) => ({ key: 'common:validations.mixed.oneOf', attrs }),
    notOneOf: (attrs: any) => ({ key: 'common:validations.mixed.notOneOf', attrs }),
    defined: (attrs: any) => ({ key: 'common:validations.mixed.defined', attrs }),
  },
  string: {
    length: (attrs: any) => ({ key: 'common:validations.string.length', attrs }),
    min: (attrs: any) => ({ key: 'common:validations.string.min', attrs }),
    max: (attrs: any) => ({ key: 'common:validations.string.max', attrs }),
    matches: (attrs: any) => ({ key: 'common:validations.string.matches', attrs }),
    email: (attrs: any) => ({ key: 'common:validations.string.email', attrs }),
    url: (attrs: any) => ({ key: 'common:validations.string.url', attrs }),
    uuid: (attrs: any) => ({ key: 'common:validations.string.uuid', attrs }),
    trim: (attrs: any) => ({ key: 'common:validations.string.trim', attrs }),
    lowercase: (attrs: any) => ({ key: 'common:validations.string.lowercase', attrs }),
    uppercase: (attrs: any) => ({ key: 'common:validations.string.uppercase', attrs }),
  },
  number: {
    min: (attrs: any) => ({ key: 'common:validations.number.min', attrs }),
    max: (attrs: any) => ({ key: 'common:validations.number.max', attrs }),
    lessThan: (attrs: any) => ({ key: 'common:validations.number.lessThan', attrs }),
    moreThan: (attrs: any) => ({ key: 'common:validations.number.moreThan', attrs }),
    positive: (attrs: any) => ({ key: 'common:validations.number.positive', attrs }),
    negative: (attrs: any) => ({ key: 'common:validations.number.negative', attrs }),
    integer: (attrs: any) => ({ key: 'common:validations.number.integer', attrs }),
  },
  date: {
    min: (attrs: any) => ({ key: 'common:validations.date.min', attrs }),
    max: (attrs: any) => ({ key: 'common:validations.date.max', attrs }),
  },
  boolean: {},
  object: {
    noUnknown: (attrs: any) => ({ key: 'common:validations.object.noUnknown', attrs }),
  },
  array: {
    min: (attrs: any) => ({ key: 'common:validations.array.min', attrs }),
    max: (attrs: any) => ({ key: 'common:validations.array.max', attrs }),
  },
})
