import { FC, useState } from 'react'
import { DealflowAdminShowWithPropertyResponseDto, HomeOwnerResponseDto } from 'repositories/http/swagger/schema'
import { Text } from 'components/Text/Text'
import { useSetDealflowStatus } from 'api/mutations/setDealflowStatus'
import { DealflowDocumentType, useUploadDealflowDocument } from 'api/mutations/uploadDealflowDocument'
import { Icon, IconColor, IconName } from 'components/DataDisplay/Icon/Icon'
import { Drawer } from 'components/Drawer/Drawer'
import { Checkbox } from 'components/Inputs/Checkbox/Checkbox'
import { FileType, StatusFileArea } from 'components/Inputs/FileInput/components/FileDropzone/FileDropzone'
import { FileArea } from 'components/Inputs/FileInput/FileArea/FileArea'
import { useFormik } from 'formik'
import { DealflowStatus, DealflowStatusEnum, isDealflowStatusCompleted } from 'model/DealflowStatus'
import useTranslation from 'next-translate/useTranslation'
import { getDocumentUrl } from 'utils/dealflowDetailUtils'
import * as Yup from 'yup'
import { useToast } from '../../../../context/ToastContext'
import { FileContainer } from '../FileContainer/FileContainer'
import { HomeownerDataDrawer } from '../HomeownerDataDrawer/HomeownerDataDrawer'
import styles from './DealflowSignStep.module.scss'

type DealflowSignStepProps = {
  purchase: DealflowAdminShowWithPropertyResponseDto
  onRefetch: () => void
}

export const DealflowSignStep: FC<DealflowSignStepProps> = ({ purchase, onRefetch }) => {
  const { t: translation } = useTranslation()
  const { setToast } = useToast()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [activeOwner, setActiveOwner] = useState(purchase.homeOwners[0])
  const ownerIndex = purchase.homeOwners.indexOf(activeOwner)

  const { mutate: uploadContractDocument, isLoading } = useUploadDealflowDocument()
  const { mutate: setDealflowStatus } = useSetDealflowStatus()

  const [unsignedContractStatus, setUnsignedContractStatus] = useState<StatusFileArea>(StatusFileArea.EMPTY)
  const lastUnsignedContract = purchase.documents.filter((document) => document.type === 'unsignedContract').pop()
  const [isUnsignedContractAreaVisible, setIsUnsignedContractAreaVisible] = useState<boolean>(!lastUnsignedContract)

  const [signedContractStatus, setSignedContractStatus] = useState<StatusFileArea>(StatusFileArea.EMPTY)
  const lastSignedContract = purchase.documents.filter((document) => document.type === 'signedContract').pop()
  const [isSignedContractAreaVisible, setIsSignedContractAreaVisible] = useState<boolean>(!lastSignedContract)

  const [downPaymentStatus, setDownPaymentStatus] = useState<StatusFileArea>(StatusFileArea.EMPTY)
  const lastDownPayment = purchase.documents.filter((document) => document.type === 'downPayment').pop()
  const [isDownPaymentAreaVisible, setIsDownPaymentAreaVisible] = useState<boolean>(!lastDownPayment)

  const isSignAndPayCompleted = isDealflowStatusCompleted(purchase.status, DealflowStatusEnum.SIGN_AND_PAY)
  const isContractIdCheckboxDisabled = purchase.status !== DealflowStatusEnum.SIGN_AND_PAY
  const [dataConfirmation, setDataConfirmation] = useState<boolean>(isSignAndPayCompleted)

  const isMakingOfferCompleted = isDealflowStatusCompleted(purchase.status, DealflowStatusEnum.BUYING_HOUSE)
  const [dataConfirmation2, setDataConfirmation2] = useState<boolean>(isMakingOfferCompleted)
  const isOfferCheckboxDisabled = !lastSignedContract || !lastDownPayment || dataConfirmation2 || isLoading

  const onDocumentUpdate = async (data: { document: File | null }, type: DealflowDocumentType) => {
    if (!data?.document) return
    uploadContractDocument(
      { type, dealflowId: purchase.id, files: data.document },
      {
        onSuccess: () => {
          setToast({
            message: 'File uploaded successfully.',
            tone: 'success',
            duration: 3000,
          })
          onRefetch()
        },
        onError: () => {
          setToast({
            message: 'There was an error while uploading the file.',
            tone: 'error',
            duration: 3000,
          })
        },
      }
    )
  }

  const handleCheckboxClick = (nextStatus: DealflowStatus) => {
    setDealflowStatus(
      { dealflowId: purchase.id, nextStatus },
      {
        onSuccess: () => {
          setToast({
            message: 'Dealflow status updated successfully.',
            tone: 'success',
            duration: 3000,
          })
          onRefetch()
        },
        onError: () => {
          setToast({
            message: 'There was an error while setting the dealflow status',
            tone: 'error',
            duration: 3000,
          })
        },
      }
    )
  }

  const signedContractFormSchema = () => {
    Yup.object().shape({
      document: Yup.mixed()
        .required('File is required')
        .test('fileType', 'Invalid file type', (value) => (value && value.type === FileType.PDF) || value.type === FileType.IMAGE),
    })
  }

  const unsignedContractForm = useFormik({
    initialValues: {
      document: null as unknown as File | null,
    },
    onSubmit: async (values) => {
      if (!values?.document) return
      await onDocumentUpdate(values, 'unsignedContract')
    },
    validationSchema: signedContractFormSchema(),
  })

  const downPaymentForm = useFormik({
    initialValues: {
      document: null as unknown as File | null,
    },
    onSubmit: async (values) => {
      if (!values?.document) return
      await onDocumentUpdate(values, 'downPayment')
    },
    validationSchema: signedContractFormSchema(),
  })

  const signedContractForm = useFormik({
    initialValues: {
      document: null as unknown as File | null,
    },
    onSubmit: async (values) => {
      if (!values?.document) return
      await onDocumentUpdate(values, 'signedContract')
    },
    validationSchema: signedContractFormSchema(),
  })

  return (
    <div className={styles.container}>
      <Text weight="bold" type="headline">
        Upload the contract to Signaturit
      </Text>

      <div className={styles.ownersContainer}>
        {purchase.homeOwners.map((owner: HomeOwnerResponseDto, index) => {
          return (
            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => setIsDrawerOpen(!isDrawerOpen)}
              onClick={() => {
                setActiveOwner(owner)
                setIsDrawerOpen(!isDrawerOpen)
              }}
              className={styles.ownerCard}
              key={owner.id}
            >
              <div className={styles.circleIcon}>
                <Icon size={19} name={IconName.USER} color={IconColor.WHITE} />
              </div>
              <div className={styles.ownerName}>
                <Text type="headline" weight="bold">{`${owner.name} ${owner.surname}`}</Text>
                <Text type="mini">{`${translation('admin:dealflowDetail.ownerLabels.owner')} ${index + 1}`}</Text>
              </div>

              <Icon className={styles.arrowIcon} name={IconName.RIGHT_ARROW} />
            </div>
          )
        })}
      </div>

      <div className={styles.contractIdInputContainer}>
        {isUnsignedContractAreaVisible && (
          <FileArea
            fileTypes={[FileType.PDF, FileType.IMAGE]}
            status={unsignedContractStatus}
            name="document"
            onChange={(files) => {
              setIsUnsignedContractAreaVisible(false)
              setUnsignedContractStatus(StatusFileArea.ACCEPTED)
              unsignedContractForm.setFieldValue('document', files[0])
              unsignedContractForm.submitForm()
            }}
            errorMessage={(unsignedContractForm.touched.document && (unsignedContractForm.errors.document as string)) || ''}
          />
        )}

        {!isUnsignedContractAreaVisible && (
          <FileContainer
            onReplaceFile={() => {
              setIsUnsignedContractAreaVisible(true)
              unsignedContractForm.setFieldValue('document', undefined)
              setUnsignedContractStatus(StatusFileArea.EMPTY)
            }}
            fileUrl={getDocumentUrl(purchase.id, lastUnsignedContract?.document.id || '') || ''}
          />
        )}

        <Checkbox
          onChange={async () => {
            if (dataConfirmation) return
            await unsignedContractForm.submitForm()
            await onRefetch()
            handleCheckboxClick(DealflowStatusEnum.BUYING_HOUSE)
            setDataConfirmation(true)
          }}
          className={styles.contractCheckbox}
          checked={dataConfirmation}
          disabled={isContractIdCheckboxDisabled || !lastUnsignedContract}
          label="The contract has been uploaded to Signaturit."
          value="dataConfirmation"
          name="dataConfirmation"
          weight="bold"
          size="small"
        />
      </div>

      <hr className={styles.divider} />

      <Text type="headline" weight="bold" className={styles.ownerDataTitle}>
        Down payment and signed contract
      </Text>

      <div className={styles.contractInput}>
        <Text type="body">Down payment</Text>

        {isDownPaymentAreaVisible && (
          <FileArea
            fileTypes={[FileType.PDF, FileType.IMAGE]}
            status={downPaymentStatus}
            name="document"
            onChange={(files) => {
              setIsDownPaymentAreaVisible(false)
              setDownPaymentStatus(StatusFileArea.ACCEPTED)
              downPaymentForm.setFieldValue('document', files[0])
              downPaymentForm.submitForm()
            }}
            errorMessage={(downPaymentForm.touched.document && (downPaymentForm.errors.document as string)) || ''}
          />
        )}

        {!isDownPaymentAreaVisible && (
          <FileContainer
            onReplaceFile={() => {
              setIsDownPaymentAreaVisible(true)
              downPaymentForm.setFieldValue('document', undefined)
              setDownPaymentStatus(StatusFileArea.EMPTY)
            }}
            fileUrl={getDocumentUrl(purchase.id, lastDownPayment?.document.id || '') || ''}
          />
        )}
      </div>

      <div className={styles.contractInput}>
        <Text type="body">Signed purchase contract</Text>

        {isSignedContractAreaVisible && (
          <FileArea
            fileTypes={[FileType.PDF, FileType.IMAGE]}
            status={signedContractStatus}
            name="document"
            onChange={(files) => {
              setIsSignedContractAreaVisible(false)
              setSignedContractStatus(StatusFileArea.ACCEPTED)
              signedContractForm.setFieldValue('document', files[0])
              signedContractForm.submitForm()
            }}
            errorMessage={(signedContractForm.touched.document && (signedContractForm.errors.document as string)) || ''}
          />
        )}

        {!isSignedContractAreaVisible && (
          <FileContainer
            onReplaceFile={() => {
              setIsSignedContractAreaVisible(true)
              signedContractForm.setFieldValue('document', undefined)
              setSignedContractStatus(StatusFileArea.EMPTY)
            }}
            fileUrl={getDocumentUrl(purchase.id, lastSignedContract?.document.id || '') || ''}
          />
        )}

        <Checkbox
          onChange={async () => {
            if (dataConfirmation2) return
            handleCheckboxClick(DealflowStatusEnum.LEGAL)
            setDataConfirmation2(true)
          }}
          className={styles.contractCheckbox}
          checked={dataConfirmation2}
          disabled={isOfferCheckboxDisabled}
          label="The down payment and the signing of the deeds are correct."
          value="dataConfirmation2"
          name="dataConfirmation2"
          weight="bold"
          size="small"
        />
      </div>

      <Drawer isOpen={isDrawerOpen} onOpenChange={() => setIsDrawerOpen(!isDrawerOpen)}>
        <HomeownerDataDrawer activeOwner={activeOwner} ownerIndex={ownerIndex} purchaseId={purchase.id} />
      </Drawer>
    </div>
  )
}
