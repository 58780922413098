import { Documentation, EmploymentTypes, IdentityTypes } from '../model/Documentation'
import { Document } from '../model/Document'

export class DocumentationBuilder {
  private readonly documentation: Documentation

  constructor(slug = 'documentationSlug') {
    this.documentation = {
      createdAt: new Date().toISOString(),
      slug,
      type: IdentityTypes.NIE,
      serialNumber: '',
      documents: [],
    }
  }

  withCreatedAt(date: Date) {
    this.documentation.createdAt = date.toISOString()
    return this
  }

  withDocuments(documents: Document[]) {
    this.documentation.documents = documents
    return this
  }

  withDocumentationType(identityDocumentation: IdentityTypes | EmploymentTypes) {
    this.documentation.type = identityDocumentation
    return this
  }

  withNumberOrName(nameOrNumber: string) {
    this.documentation.serialNumber = nameOrNumber
    return this
  }

  build(): Documentation {
    return this.documentation
  }
}
