import getNextConfig from 'next/config'

type PublicRuntimeConfig = {
  GOOGLE_MAPS_API_KEY: string
  GOOGLE_MAPS_MAP_STYLE_ID: string
  GA_TRACKING_ID: string
  FACEBOOK_PIXEL_ID: string
  API_ENDPOINT: string
  HUBSPOT_ACCESS_TOKEN: string
  instagramUrl: string
  twitterUrl: string
  facebookUrl: string
  environment: string
}

export function getPublicRuntimeConfig(): PublicRuntimeConfig {
  const nextConfig = getNextConfig()

  if (!nextConfig) {
    return {
      GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY || '',
      GOOGLE_MAPS_MAP_STYLE_ID: 'bf41528c8e1eb3ca',
      GA_TRACKING_ID: process.env.GA_TRACKING_ID || 'UA-147651752-2',
      FACEBOOK_PIXEL_ID: process.env.FACEBOOK_PIXEL_ID || '368953204313621',
      API_ENDPOINT: process.env.API_ENDPOINT || 'https://api-dev.libeen.com/v1',
      HUBSPOT_ACCESS_TOKEN: process.env.HUBSPOT_ACCESS_TOKEN || '',
      instagramUrl: 'https://www.instagram.com/libeen.smarthousing/',
      twitterUrl: 'https://twitter.com/LibeenSmart',
      facebookUrl: 'https://www.facebook.com/Libeen.smarthousing/',
      environment: process.env.NODE_ENV || 'development',
    }
  }

  return nextConfig.publicRuntimeConfig
}

type ServerRuntimeConfig = {
  GEOCODE_API_KEY: string
  jwtPublicKey: string
  BASIC_AUTH_USERNAME: string
  BASIC_AUTH_PASSWORD: string
  BASIC_AUTH_ENABLED: boolean | string
}

export function getServerSideConfig(): ServerRuntimeConfig {
  const nextConfig = getNextConfig()

  return nextConfig.serverRuntimeConfig
}
