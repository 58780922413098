import { UpdatePropertyDraftImages } from '../domain/UpdatePropertyDraftImages'
import { ImagePath } from '../../model/ImagePath'

export class UpdatePropertyDraftImagesFake implements UpdatePropertyDraftImages {
  execute(slug: string, draftPropertyImages: File[]): Promise<ImagePath[]> {
    return Promise.resolve(
      draftPropertyImages.map((draftPropertyImage) => {
        return { path: draftPropertyImage.name }
      })
    )
  }
}
