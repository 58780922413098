import { PurchaseStatus } from '../../model/PurchaseStatus'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { GetAdminPurchases } from '../domain/GetAdminPurchases'
import { DealflowAdminIndexResponseDto } from './swagger/schema'

export class GetAdminPurchasesHttp implements GetAdminPurchases {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(
    search?: string,
    paginate?: { offset: number; limit: number },
    statusFilter?: PurchaseStatus,
    orderBy?: string
  ): Promise<DealflowAdminIndexResponseDto> {
    const paramsSearch = search ? { search } : {}
    const paramsPaginate = paginate || {}
    const paramsStatus = statusFilter ? { status: statusFilter } : {}
    const paramsOrderBy = orderBy ? { orderBy } : {}

    const response = await this.httpClient.get<DealflowAdminIndexResponseDto>(`${getPublicRuntimeConfig().API_ENDPOINT}/admin/dealflows`, {
      headers: {},
      params: {
        ...paramsSearch,
        ...paramsPaginate,
        ...paramsStatus,
        ...paramsOrderBy,
      },
    })

    return response
  }
}
