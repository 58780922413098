import { DocumentationEmployment } from 'model/Documentation'
import { FC } from 'react'
import { getDocumentUrl, getLabelFromEmploymentDocumentation } from 'utils/dealflowDetailUtils'
import { Text } from 'components/Text/Text'
import { Button } from 'components/Button/Button'
import { IconName } from 'components/DataDisplay/Icon/Icon'
import { LabeledText } from '../LabeledText/LabeledText'
import { useTranslation as useTranslationDeprecated } from '../../../../hooks/useTranslationDeprecated'
import styles from './EmploymentDocumentation.module.scss'

export const EmploymentDocumentation: FC<{
  employmentDocumentation: DocumentationEmployment
  purchaseSlug: string
  withoutTitle?: boolean
  workSituation?: string
}> = ({ employmentDocumentation, purchaseSlug, withoutTitle, workSituation }) => {
  const { t } = useTranslationDeprecated()

  let employmentTypeLabel = ''

  switch (employmentDocumentation.type.toLowerCase()) {
    case 'indefinite_employment_contract':
      employmentTypeLabel = t.purchase.stepOne.employmentInfo.salaried
      break
    case 'temporal_employment_contract':
      employmentTypeLabel = t.purchase.stepOne.employmentInfo.temporal
      break
    case 'freelance':
      employmentTypeLabel = t.purchase.stepOne.employmentInfo.freelancer
      break

    default:
      break
  }

  return (
    <div className={styles.container}>
      <Text type="headline" weight="bold" className={styles.ownerDataTitle}>
        Employment status
      </Text>

      {!withoutTitle && workSituation && <LabeledText field="Employment status" value={employmentTypeLabel} />}
      {workSituation}

      <LabeledText field="Payrolls" value={employmentTypeLabel} />

      {employmentDocumentation.documents.map((document, index: number) => (
        <div key={document.id}>
          <div className={styles.documentButtonsContainer} key={document.id}>
            <Button
              icon={IconName.FILE_LIST}
              variant="tertiary"
              size="small"
              onClick={() => {
                return window.open(getDocumentUrl(purchaseSlug, document.id), '_blank')
              }}
            >
              {getLabelFromEmploymentDocumentation(index, t, employmentDocumentation.type)}
            </Button>
          </div>
        </div>
      ))}
    </div>
  )
}
