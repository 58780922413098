// @ts-nocheck
import Cookies from 'js-cookie'
import { merge } from 'lodash'
import { GenericObject } from 'types/GenericObject'
import * as Sentry from '@sentry/nextjs'
import { objectToQueryString } from './objectToQueryString'

export type FetchOptions = {
  headers?: Headers
  data?: any
  responseType?: 'text' | 'json' | 'stream' | 'blob' | 'arrayBuffer' | 'formData'
  params?: GenericObject
}

type Headers = {
  [name: string]: string
}

type Response<T> = {
  status: number
  statusText: string
  config: Options
  data: T
  headers: Headers
  redirect: boolean
  url: string
  type: ResponseType
  body: ReadableStream<Uint8Array> | null
  bodyUsed: boolean
}

type CustomPromise<T> = Promise<T> & {
  cancel: () => void
}

export function createApiClient(baseURL: string) {
  function client<T>(endpoint: string, config: Options = {}, method: string): CustomPromise<T> {
    const response = { config } as Response<T>
    const customHeaders: Headers = {}
    let url = `${baseURL}${endpoint}`
    let { data } = config

    if (typeof window !== 'undefined' && !config.headers?.Authorization) {
      const jwt = Cookies.get('accessToken')
      if (jwt) {
        customHeaders.Authorization = `Bearer ${jwt}`
      }
    }

    if (data && typeof data === 'object' && typeof data.append !== 'function') {
      data = JSON.stringify(data)
      customHeaders['content-type'] = 'application/json'
    }

    if (config.params) {
      url += objectToQueryString(config.params)
    }

    const promise = fetch(url, {
      method,
      credentials: 'include',
      body: data as any,
      headers: merge(config.headers, customHeaders),
    }).then((res: any) => {
      for (const i in res) {
        if (typeof res[i] !== 'function') response[i] = res[i]
      }

      return res[config.responseType || 'text']()
        .then((data: any) => {
          response.data = data
          // its okay if this fails: response.data will be the unparsed value:
          response.data = JSON.parse(data)
        })
        .catch(Object)
        .then(() => {
          if (res.ok) {
            return response.data
          }

          Sentry.captureException(new Error(response.data.error))

          return Promise.reject(response.data)
        })
    }) as CustomPromise<T>

    return promise
  }

  client.get = <T,>(url: string, config?: Options) => client<T>(url, config, 'GET')
  client.delete = <T,>(url: string, config?: Options) => client<T>(url, config, 'DELETE')
  client.post = <T,>(url: string, config?: Options) => client<T>(url, config, 'POST')
  client.put = <T,>(url: string, config?: Options) => client<T>(url, config, 'PUT')
  client.patch = <T,>(url: string, config?: Options) => client<T>(url, config, 'PATCH')

  return client
}
