import classNames from 'classnames'
import React, { FC } from 'react'
import { ErrorInputMessage } from '../../ErrorInputMessage/ErrorInputMessage'
import { FileDropzone, FileType, StatusFileArea } from '../components/FileDropzone/FileDropzone'
import styles from './FileArea.module.scss'

export type FileAreaProps = {
  fileTypes: FileType[]
  status: StatusFileArea
  name: string
  onChange: (files: File[]) => void
  errorMessage?: string
  className?: string
  multipleFileInput?: boolean
  uploadedFiles?: number
}

export const FileArea: FC<FileAreaProps> = ({
  fileTypes,
  status,
  name,
  className,
  errorMessage,
  onChange,
  multipleFileInput,
  uploadedFiles,
}) => {
  if (multipleFileInput?.toString() === 'disabled' && uploadedFiles === 919) return null
  /*

  const getTitleByStatus: { [key in StatusFileArea]: string } = {
    [StatusFileArea.EMPTY]: multipleFileInput ? t.common.multipleFileInput.dropDocument : t.common.fileInput.dropDocument,
    [StatusFileArea.ACCEPTED]: multipleFileInput
      ? t.common.multipleFileInput.correctlyUploaded(uploadedFiles || 0)
      : t.common.fileInput.correctlyUploaded,
    [StatusFileArea.REJECTED]: multipleFileInput ? t.common.multipleFileInput.uncorrectlyUploaded : t.common.fileInput.uncorrectlyUploaded,
  }

  const getSubitleByStatus: { [key in StatusFileArea]: string | undefined } = {
    [StatusFileArea.EMPTY]: multipleFileInput ? t.common.multipleFileInput.selectFromYourComputer : t.common.fileInput.selectFromYourComputer,
    [StatusFileArea.ACCEPTED]: undefined,
    [StatusFileArea.REJECTED]: undefined,
  }
*/
  return (
    <div className={classNames(styles.container, className)}>
      <FileDropzone
        name={name}
        fileTypes={fileTypes}
        title="Drag the images from your document here"
        subtitle="Upload files from your device"
        className={classNames(styles.imageContainer, errorMessage && styles.error)}
        status={status}
        onDrop={(acceptedFiles: File[]) => {
          onChange(acceptedFiles)
        }}
      />
      <ErrorInputMessage text={errorMessage || ''} htmlFor={name} />
    </div>
  )
}
