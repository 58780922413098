import classNames from 'classnames'
import React, { CSSProperties, FC, ReactHTML } from 'react'
import sanitize from 'sanitize-html'
import styles from './Typography.module.scss'

type TypographyProps = {
  typographyType?: TypographyType
  className?: string
  style?: CSSProperties
  htmlFor?: string
  href?: string
  type?: keyof ReactHTML
  onClick?: (event: React.MouseEvent) => void
  /**
   * @deprecated
   */
  dangerouslySetInnerHTML?: {
    __html: string
  }
  hasRichText?: boolean
  center?: boolean
  id?: string
}

export enum TypographyType {
  LABEL = 'label',
  H1_TITLE = 'h1Title',
  H2_TITLE = 'h2Title',
  H3_TITLE = 'h3Title',
  PARAGRAPH_LEAD = 'paragraphLead',
  PARAGRAPH = 'paragraph',
  HEADLINE = 'headline',
  SMALL_PARAGRAPH = 'smallParagraph',
  FOOTNOTE = 'footNote',
}

const typographyTypeToElement: { [key in TypographyType]: keyof ReactHTML } = {
  [TypographyType.LABEL]: 'label',
  [TypographyType.H1_TITLE]: 'h1',
  [TypographyType.H2_TITLE]: 'h2',
  [TypographyType.H3_TITLE]: 'h3',
  [TypographyType.PARAGRAPH_LEAD]: 'p',
  [TypographyType.PARAGRAPH]: 'p',
  [TypographyType.HEADLINE]: 'p',
  [TypographyType.SMALL_PARAGRAPH]: 'p',
  [TypographyType.FOOTNOTE]: 'p',
}

/**
 *
 * @deprecated Use Text component instead.
 */
export const Typography: FC<TypographyProps> = ({
  children,
  typographyType = TypographyType.PARAGRAPH,
  className,
  dangerouslySetInnerHTML,
  style,
  type,
  hasRichText = false,
  center = false,
  ...props
}) => {
  // TODO: Change variable name when dangerouslySetInnerHTML is not longer used
  const realHasRichText = Boolean(hasRichText || dangerouslySetInnerHTML?.__html)
  const content = (children as string) || dangerouslySetInnerHTML?.__html || ''

  return React.createElement(type || typographyTypeToElement[typographyType], {
    className: classNames(styles.common, styles[typographyType], center && styles.center, className),
    style,
    ...(realHasRichText
      ? {
          dangerouslySetInnerHTML: {
            __html: sanitize(content, {
              allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel'],
            }),
          },
        }
      : { children }),
    ...props,
  })
}
