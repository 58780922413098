import React, { FC, ReactNode } from 'react'
import * as Dialog from '@radix-ui/react-dialog'

import { Icon, IconName } from 'components/DataDisplay/Icon/Icon'
import styles from './Drawer.module.scss'

type DrawerProps = {
  children: ReactNode
  isOpen: boolean
  onOpenChange: (open: boolean) => void
}

export const Drawer: FC<DrawerProps> = ({ children, isOpen = false, onOpenChange }) => {
  return (
    <Dialog.Root onOpenChange={() => onOpenChange(!isOpen)} open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.overlay} />
        <Dialog.Content
          className={`${styles.content} ${isOpen ? styles.active : styles.inactive}`}
          onInteractOutside={() => onOpenChange(!isOpen)}
        >
          {children}
          <Dialog.Close aria-label="close drawer" className={styles.closeButton}>
            <Icon size={24} name={IconName.CLOSE} />
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
