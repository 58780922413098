import { formatWithSeparators } from '../../utils/formatWithSeparators'
import { formatToSpanishCurrency } from '../../utils/fotmatToEuropeanCurrency'

export const spanish = {
  common: {
    email: 'Email',
    password: 'Contraseña',
    required: 'Campo requerido',
    invalid: 'Campo inválido',
    tooLong: '¡Demasiado largo!',
    tooShort: '¡Demasiado corto!',
    requiredNumberOfFiles: (number: number) => `Debes subir ${number} fichero${number === 1 ? '' : 's'}`,
    dateBefore: (date: string) => `La fecha debe ser anterior a ${date}`,
    dateAfter: (date: string) => `La fecha debe ser posterior a ${date}`,
    selectOption: 'Selecciona una opción',
    search: 'Buscar',
    next: 'Siguiente',
    back: 'Anterior',
    goToMyAccount: 'Ir a mi cuenta',
    errors: {
      unknown: 'Se ha poducido un error inesperado, póngase en contacto con nosotros para solucionarlo lo antes posible.',
      mustAcceptConditions: 'Debe aceptar los términos de uso y política de privacidad para continuar.',
    },
    propertyStatus: {
      available: 'Disponible',
      booked: 'Reservado',
      canceled: 'Cancelado',
      sold: 'Vendido',
      draft: 'Borrador',
    },
    purchaseStatus: {
      doing: 'En curso',
      pending: 'Pendiente',
      review: 'Revisar',
      step1: 'Paso 1',
      step2: 'Paso 2',
      await1: 'Validar 1',
      await2: 'Validar 2',
      completed: 'Completado',
      completedAll: 'Completado',
      cancelled: 'Cancelado',
      cancelledAll: 'Cancelado',
    },
    stepStatus: {
      validated: 'Validado',
      review: 'Revisar',
      rejected: 'Rechazado',
      pending: 'Pendiente',
      cancelled: 'Cancelado',
    },
    fileInput: {
      dropDocument: 'Arrastra y suelta tu documento aqui o',
      selectFromYourComputer: 'haz clic para seleccionarlo en tu ordenador',
      correctlyUploaded: 'Tu archivo se ha subido correctamente',
      uncorrectlyUploaded: 'Tus archivos no se han podido subir correctamente',
    },
    multipleFileInput: {
      dropDocument: 'Arrastra y suelta tus documentos de una vez aqui o',
      selectFromYourComputer: 'haz clic para seleccionarlos en tu ordenador',
      correctlyUploaded: (n: number) => `Archivos subidos correctamente: ${n}`,
      uncorrectlyUploaded: 'Tus archivos no se han podido subir correctamente',
    },
    propertyType: {
      house: 'Casa',
      flat: 'Piso',
      duplex: 'Duplex',
    },
    propertyOrientation: {
      north: 'Norte',
      east: 'Este',
      west: 'Oeste',
      south: 'Sur',
    },
    propertyPosition: {
      interior: 'Interior',
      exterior: 'Exterior',
    },
    propertyEnergeticCertification: {
      aaaa: 'A+++',
      aaa: 'A++',
      aa: 'A+',
      a: 'A',
      b: 'B',
      c: 'C',
      d: 'D',
      e: 'E',
    },
    neighborhoodOption: {
      chamberi: 'Chamberí',
      malasania: 'Malasaña',
      retiro: 'Retiro',
      madrid: 'Madrid',
      salamanca: 'Salamanca',
      moncloa: 'Moncloa',
      tetuan: 'Tetuán',
      chamartin: 'Chamartín',
      centro: 'Centro',
      arganzuela: 'Arganzuela',
      fuencarral: 'Fuencarral - El Pardo',
      latina: 'Latina',
      ciudadLineal: 'Ciudad Lineal',
      hortaleza: 'Hortaleza',
      canillejas: 'San Blas - Canillejas',
      barajas: 'Barajas',
      zonaNoroeste: 'Zona Noroeste',
      zonaNorte: 'Zona Norte',
    },
    city: {
      madrid: 'Madrid',
      barcelona: 'Barcelona',
    },
    region: {
      madrid: 'Madrid',
      barcelona: 'Barcelona',
      valencia: 'Valencia',
    },
    vendorType: {
      libeen: 'Libeen',
      external: 'External',
    },
    vendor: {
      libeen: 'Libeen',
      idealista: 'Ideaista',
      fotocasa: 'Fotocasa',
    },
    imageInput: {
      dropImagesHereOr: 'Arrastra y suelta tus imágenes aqui o',
      selectThemFromYourComputer: 'haz clic para seleccionar las imágenes en tu ordenador',
    },
    pagination: {
      showing: (showing: string | number, total: string | number) => `Mostrando <em>${showing}</em> elementos de <em>${total}</em> en total`,
      resultsPerPage: 'Resultados por página',
      goToPage: 'Saltar a página',
    },
    placeholders: {
      cityExample: 'eg: Málaga',
      nameExample: 'eg: Javier',
      surnameExample: 'eg: Martinez de Vallehermoso',
      emailExample: 'eg: email@dominio.com',
      phoneExample: 'eg: 600700100',
      dateExample: 'eg: Día/Mes/Año',
      addressExample: 'eg: Calle Fuencarral, 28004 Madrid',
      documentExample: 'eg: 08967005K',
    },
    step: (step: number) => {
      return `Paso ${step}`
    },
    signUp: {
      title: 'Únete a la revolución del #SmartHousing',
      buttonText: 'Crear mi cuenta',
    },
  },
  header: {
    index: 'Índice',
    howItWorks: 'Cómo funciona',
    frequentAskedQuestions: 'FAQs',
    imAnOwner: 'Publica tu casa gratis',
    properties: 'Viviendas',
    aboutUs: 'Sobre nosotros',

    helloCustomer: (name: string) => `Hola ${name}`,
    login: 'Login',
    menu: {
      summary: 'Resumen',
      myData: 'Mis Datos',
      myContracts: 'Mis contratos',
      myFavorites: 'Mis favoritos',
      maintenance: 'Mantenimiento',
      settings: 'Ajustes',
      logout: 'Cerrar sesión',
    },
  },
  footer: {
    copyright: 'Todos los derechos reservados',
    legalTitle: 'Legal',
    followUs: 'Siguenos',
    privacy: 'Política de privacidad',
    aboutUs: 'Sobre nosotros',
    workWithUs: 'Trabaja con nosotros',
    legal: 'Aviso legal',
    cookies: 'Política de cookies',
    blog: 'Blog',
    facebook: 'Facebook',
    instagram: 'Instagram',
    twitter: 'Twitter',
  },
  filters: {
    min: 'Mínimo',
    max: 'Máximo',
    bedrooms: {
      name: 'Número de habitaciones',
      indiferent: 'Me es indiferente',
      value: (numberOfBedrooms: number) => String(numberOfBedrooms),
    },
    bathrooms: {
      name: 'Número de baños',
      indiferent: 'Me es indiferente',
      value: (numberOfBedrooms: number) => String(numberOfBedrooms),
    },
    city: {
      name: 'Ciudad',
      question: '¿Dónde quieres vivir?',
      soon: '(Pronto disponible)',
      indiferent: 'Me es indiferente',
    },
    neighborhood: {
      name: 'Zona',
    },
    space: {
      question: '¿Cuánto espacio buscas?',
      upTo: (meters: number) => `Menos de ${formatWithSeparators(meters)}m2`,
      between: (min: number, max: number) => `Entre ${formatWithSeparators(min)}m2 - ${formatWithSeparators(max)}m2`,
      atLeast: (meters: number) => `Más de ${formatWithSeparators(meters)}m2`,
      indiferent: 'Me es indiferente',
    },
    renting: {
      noFilter: 'Me es indiferente',
      question: '¿Cuánto quieres pagar de alquiler?',
      upTo: (price: number) => `Menos de ${formatWithSeparators(price)}€ al mes`,
      between: (min: number, max: number) => `Entre ${formatWithSeparators(min)}€ - ${formatWithSeparators(max)}€ al mes`,
      atLeast: (price: number) => `Más de ${formatWithSeparators(price)}€ al mes`,
    },
    downpayment: {
      noFilter: 'Me es indiferente',
      question: '¿Cuánto quieres pagar de entrada para tu nueva casa?',
      upTo: (price: number) => `Menos de ${formatWithSeparators(price)}€`,
      between: (min: number, max: number) => `Entre ${formatWithSeparators(min)}€ - ${formatWithSeparators(max)}€`,
      atLeast: (price: number) => `Más de ${formatWithSeparators(price)}€`,
    },
  },
  tags: {
    sunny: 'Soleado',
    petsAllowed: 'Pet friendly',
    animalLover: 'Animal Lover', // Should we only keep one?
    shoppingArea: 'Shopping area',
    shops: 'Tiendas', // Should we only keep one?
    restaurantsNear: 'Restaurantes & bares',
    publicTransport: 'Transporte público',
    transport: 'Transporte', // Should we only keep one?
    forBikers: 'Bike friendly',
    forFamilies: 'Área familiar',
    parksNear: 'Parques',
  },
  features: {
    elevator: 'Ascensor',
    ac: 'Aire Acondicionado',
    areaway: 'Areaway',
    centralHeating: 'Calefacción',
    gym: 'Gimnasio',
    janitor: 'Concerje',
    parking: 'Parking',
    pool: 'Piscina',
  },
  properties: {
    heading: 'Usa tu alquiler para comprar tu casa. ¡Bienvenido al SmartHousing!',
    month: 'mes',
    downPayment: 'Entrada de',
    banner: {
      booked: 'Reservado',
      sold: 'Vendido',
      tryBeforeYouBuyIt: 'try before you buy it',
      wholeRentalForBuying: '100% del alquiler a la compra',
    },
    emptyState: {
      propertiesWhereNotFound: '¿No encuentras lo que buscas?',
      contactUs:
        'Ponte en contacto con nuestro equipo en el 627 065 603 o mandándonos un email a <a target="_blank" rel="noopener noreferrer" href="mailto:ayuda@libeen.com">ayuda@libeen.com</a> y te ayudaremos a encontrar tu próximo hogar.',
    },
    slingularYearPlan: 'Plan 1 año',
    pluralYearPlan: (n: number) => `Plan ${n} años`,
    seeFilters: 'Filtros',
    filters: 'Filtros',
  },
  details: {
    bedrooms: 'Habitaciones',
    bathrooms: 'Baños',
    seeMore: 'Ver más',
    seeLess: 'Ver menos',
    location: 'La ubicación',
    neighborhood: 'La zona',
    related: 'Otros pisos similares en',
    buy: 'Quiero comprarla ahora',
    visit: 'Me gustaría visitarla primero',
    moreInfo: 'O si prefieres te llamamos para resolver tus dudas',
    smartHousingProcess: 'Te recordamos el proceso del SmartHousing',
    form: {
      notFoundWhatYou: '¿No encuentras lo que buscas?',
      contactWithUs: 'Contacta con nosotros y te ayudamos a encontrar tu vivienda ideal',
      name: 'Nombre',
      namePlaceholder: 'ej: Paula Hernandez',
      email: 'Email',
      emailPlaceholder: 'nombre@dominio.com',
      phone: 'Teléfono',
      phonePlaceholder: 'ej: 660 123 123',
      submit: 'contactar con libeen',
    },
  },
  priceDetails: {
    title: 'Compra con Libeen',
    subtitle: 'Usa tu alquiler para comprar tu casa.',
    renting: 'Alquiler',
    regularity: 'al mes',
    downPayment: 'Entrada',
    descriptionSavings1: 'Gracias al SmartHousing habrás ahorrado ',
    descriptionSavings2: '.',
    descriptionCost: 'Importe de compraventa de la propiedad: ',
    buy: 'Quiero comprarla ahora',
    visit: 'Me gustaría visitarla primero',
  },
  neighborhood: {
    tag: {
      parks: 'Parques',
      restaurants: 'Restaurantes',
      mall: 'Centros Comerciales',
      nightLife: 'Vida norcturna',
    },
  },
  detailsHeader: {
    goBackToSeeker: 'Volver al buscador',
    lookPhotos: 'Ver fotos',
  },
  propertyFeatures: {
    ac: 'Aire acondicionado',
    areaway: 'Patio',
    centralHeating: 'Calefacción central',
    elevator: 'Ascensor',
    gym: 'Gimnasio',
    janitor: 'Conserje',
    parking: 'Parking',
    pool: 'Piscina',
  },
  savingsExplanation: {
    smarthousingProcess: 'Te recordamos el proceso del Smarhousing',
    saves20percent: 'Ahorra sin darte cuenta',
    withOurPlans: 'Con nuestros planes podrás ahorrar ese 20% de entrada que cualquier banco exige a la hora de comprar una vivienda.',
    smallAmount: 'Aportando una pequeña entrada y junto con las cuotas mensuales, como si fuese un alquiler, empezarás a comprar tu casa.',
    howComplicated:
      'Sabemos lo complicado que es empezar a comprar tu propia vivienda. Es por esto, que hemos inventado el SmartHousing. El precio de tu vivienda quedará fijado desde el principio.',
  },
  yearSteps: {
    title: 'Te recordamos el proceso de #SmartHousing',
    year: 'Año',
    year0: {
      heading: 'Múdate a tu nueva casa con solo una pequeña entrada y empieza a pagar tu alquiler como siempre.',
      text: 'Ni las cuotas ni el precio final aumentan con el paso del tiempo.',
    },
    year3: {
      headingA: 'Opción A - ¡Quieres comprar tu casa!',
      textA: 'Aporta otro 5% y sigue tan happy como siempre.',
      headingB: 'Opción B - Decides no seguir adelante',
      textB: 'Te devolvemos el 80% de tu aportación inicial (vamos, casi la totalidad de tu entrada) pero... ¡sería una lástima perderte!',
    },
    year5: {
      heading: '¡Eres dueño de tu casa!',
      text: '¡Spoiler! Ya tienes ahorrado el 20% por lo que pasarás a ser el propietario y pagar tu propia hipoteca.',
    },
  },
  whyUs: {
    title: '¿Por qué debería comprar mi casa con Libeen?',
    point1: 'Pagando este alquiler valor mercado, pasarás a ser el propietario.',
    point2: 'El precio no aumentará. Queda fijado desde el momento que entras a vivir.',
    point3: 'Sólo tienes que aportar una pequeña entrada para poder comprar tu casa.',
    point4: 'Mientras pagas tu alquiler, inviertes en tu futuro.',
    point5: 'Puedes estar tranquilo, nuestro team experto en el sector inmobiliario siempre estudia que sea una buena inversión',
  },
  user: {
    loginMessage: {
      openYourEmail: 'Abre tu email',
      anEmailWasSentTo: 'Te hemos enviado un link a',
      clickInTheLinkToLogIn: 'Haz click en el link para iniciar tu sesión. No te hace falta ninguna contraseña.',
    },
    loginLoading: {
      loginLoadingTitle: 'Iniciando sesión….',
      inFewSecondsYouLogIn: 'En unos segundos estarás dentro',
      linkExpired: 'El enlace de inicio ha caducado',
      linkIsOnlyAvariableForAnHour: 'El link está disponible una hora y solo se puede usar una vez.',
      clickThisLink: 'Haz click en el siguiente enlace y te enviaremos un link de nuevo a tu email.',
      sendLink: 'Solicitar enlace',
    },
    login: {
      pageTitle: 'Portal del cliente',
      title: 'Iniciar Sesión',
      dontHaveAnAccountRegisterHere: '¿No tienes cuenta? Registrate aquí',
      loginAction: 'Iniciar Sesión',
      notRegisteredError: 'No encontamos esta cuenta. Registrate en el enlace de arriba, te tomará solo unos segundos.',
    },
    purchasingTimeline: {
      continue: 'Continuar con la compra de mi casa',
      beSmartHouser: 'Ya queda menos para que seas un',
      smartHouser: '<em>#SmartHouser</em>',
      error: (stepNumber: number) => `Hay un error en la información introducida en el paso ${stepNumber}`,
      verifying: (stepNumber: number) =>
        `Estamos verificando tu información introducida en el paso ${stepNumber}. Pronto nos pondremos en contacto contigo.`,
    },
    dashboard: {
      subtitle:
        'Solo queremos recordarte que sigues tirando tu dinero en un simple alquiler... Únete ya al <em>#SmartHousing</em> y aquí podrás visualizar y gestionar tu casa y tus finanzas.',
      cheerup: 'Anímate a ser un <em>#SmartHouser</em>',
      documentationDeliveryAndBookPayment: 'Envio de documentacion y pago de reserva',
      contractSignAndDownpayment: 'Firma de contrato y pago de entrada',
      keyDelivery: 'Entrega de llaves',
      iWantToSeeWhichHousesDoYouHave: 'Si, Quiero ver que casas que tenéis para mi',
    },
    savings: {
      title: 'Llevas',
      saved: 'Ahorrado',
      firstYear: 'Primer año',
      thirdYear: 'Tercer año',
      fifthYear: 'Quinto año',
      welcome: '¡Bienvenido al #SmartHousing!',
      notifyAboutPurchase:
        'Ahora toca elegir si quieres seguir adelante con la compra (Recuerda que tienes que avisarnos con 3 meses de antelación)',
      congratulations: '¡Enhorabuena! Pasarás a ser el propietario de tu casa :)',
      percentage: (percentage: number) => `${percentage}%`,
      totalSaved: (total: number) => `${total.toLocaleString('es').concat('€')}`,
      footerText: 'ahorrados para la compra de tu casa',
    },
    savingProgress: {
      subtitle: '¡Qué maravilla! ¡Cómo vas avanzando! Cada mes que pasa, vas comprando un poquito más de tu casa.',
    },
    common: {
      title: (name: string) => `Aloha ${name}`,
      myInformation: 'Mis datos',
      myContracts: 'Mis contratos',
      myFavorites: 'Mis favoritos',
      maintenance: 'Mantenimiento',
      setup: 'Ajustes',
    },
  },
  admin: {
    header: {
      dashboard: 'Dashboard',
      dealflow: 'Dealflow',
      clients: 'Clientes',
      properties: 'Propiedades',
    },
    login: {
      title: 'Iniciar Sesión',
      emailPlaceholder: 'eg: ejemplo@ejemplo.com',
      passwordPlaceholder: 'Al menos 8 caracteres',
      ifYouForgotThePaswordContactYourAdmin: 'Si olvidaste tu contraseña tienes que ponterte en contacto con el administrador de sistemas.',
      wrongPasswordOrEmail: 'Email o contraseña no validos. Intentalo otra vez.',
      loginAction: 'Iniciar Sesión',
    },
    contractLinkModal: {
      title: 'Introducir contrato',
      subtitle: 'Introduce el enlace generado del contrato electrónico de VidSigner en el campo debajo y dale a guardar.',
      textFieldLabel: 'Enlace del contrato de Vidsigner',
      placeholder: 'eg: https://vidsigner.com/9732h9fe',
      button: 'Guardar contrato Vidsigner',
    },
    propertiesManager: {
      actions: {
        addProperty: 'Añadir casa',
        searchPlaceholder: 'Buscar por referencia, título, etc.',
        uploadProperties: 'Importar casas',
      },
      filters: {
        all: 'Todos',
        available: 'Disponibles',
        booked: 'Reservados',
        sold: 'Vendidos',
        draft: 'Borrador',
      },
      tableHead: {
        ref: 'Ref.',
        title: 'Título',
        city: 'Ciudad',
        neighborhood: 'Zona',
        surface: 'M2',
        tags: 'Etiquetas',
        vendor: 'Fuente',
        renting: 'Alquiler',
        downPayment: 'Entrada',
        status: 'Estado',
        createdAt: 'Creado',
        updatedAt: 'Editado',
      },
      errors: {
        uploadProperties: 'Error importando casas desde un CSV',
      },
    },
    propertyDetail: {
      new: 'Nueva propiedad',
      reference: (id: string) => `Ref:${id}`,
      backButton: 'Volver a listado de propiedades',
      publishAction: 'Cambiar a publicado',
      turnIntoDraftAction: 'Cambiar a borrador',
      actionDeleteDescription: 'Borrar la propiedad completamente',
      actionReviewDescription: 'Previsualziar la propiedad',
      actionSaveDescription: 'Guardar la propiedad',
      informationForm: {
        title: 'Información del inmueble',
        labels: {
          title: 'Título descriptivo',
          slug: 'Título para la URL',
          propertyType: 'Tipo de inmueble',
          builtSurface: 'M2 construidos',
          utilSurface: 'M2 útiles',
          bedroomsAmount: 'Dormitorios',
          bathroomsAmount: 'Baños',
          orientation: 'Orientation',
          propertyPosition: 'Posición',
          energeticCertification: 'Certificación energética',
          tryBeforeYouBuyIt: 'Try before you buy it',
          wholeRentalForBuying: 'Alquiler 100% a la compra',
          yearPlan: 'Plan de años',
          specialOffer: 'Oferta especial',
          lifestyle: 'Lifestyle',
          features: 'Características',
          completeDescription: 'Descripción completa',
        },
        placeholders: {
          title: 'eg: Fantástico duplex en Chamberí',
          slug: 'Fantástico-duplex-en-chamberi',
          builtSurface: 'eg: 56',
          utilSurface: 'eg: 42',
          bedroomsAmount: 'eg: 3',
          bathroomsAmount: 'eg: 2',
          yearPlan: 'eg: 5',
          specialOffer: 'ej: LIBENSMART',
          completeDescription: 'eg: Este piso esta ubicado en ...',
        },
      },
      locationForm: {
        title: 'Ubicación del inmueble',
        error: 'Debe incluir la localización del inmueble',
        labels: {
          city: 'Ciudad',
          region: 'Provincia',
          neighborhood: 'Zona',
          subNeighborhood: 'Barrio',
          address: 'Dirección',
          floor: 'Piso',
          postalCode: 'Código postal',
          location: 'Ubicación',
        },
        placeholders: {
          subNeighborhood: 'eg: Vallehermoso',
          address: 'eg: Este piso está ubicado en ...',
          floor: 'eg: Ático 3',
          postalCode: 'eg: 26790',
          location: 'Haz click en el mapa para colocar el pin de ubicación.',
        },
      },
      imagesForm: {
        title: 'Imágenes del inmueble',
        instructions:
          'Puedes arrastrar las imagenes para organizar su orden y elegir la imagen de portada.\nPuedes añadir una descripcion a cada imagen haciendo clic en el icono del lapiz.',
        error: 'Debe de añadir al menos una imagen a la propiedad.',
      },
      ownerForm: {
        title: 'Información propietario',
        labels: {
          vendorType: 'Tipo de propietario',
          name: 'Nombre propietario o persona de contacto',
          phone: 'Teléfono',
          vendor: 'Fuente',
          referenceId: 'Número de referencia fuente',
          referenceLink: 'Enlace',
        },
        placeholders: {
          name: 'eg: Henry Ford',
          phone: 'eg: 900600400',
          email: 'eg: steve@apple.com',
          referenceId: 'eg: ID3563',
          referenceLink: 'eg: https://fotopiso.com/vivienda/1',
        },
      },
      financialForm: {
        title: 'Datos económicos',
        labels: {
          priceOfCost: 'Precio venta propietario',
          priceToPay: 'Prencio de venta libeen',
          pricePerMonth: 'Coste alquiler mensual',
          entryPayment: 'Coste entrada',
          savings: 'Ahorros',
          monthlySavings: 'Ahorro mensual',
          communityMonthlyCost: 'Gastos comunidad mensual',
          IBIYearlyCost: 'Gasto IBI anual',
        },
        warnings: {
          pricePerMonth: '(calculado automáticamente)',
          entryPayment: '(calculado autmáticamente 5,5%)',
        },
        placeholders: {
          priceOfCost: 'eg: 330,000',
          priceToPay: 'eg: 360,000',
          pricePerMonth: 'eg: 1,300',
          entryPayment: 'eg: 13,300',
          savings: 'eg: 1,000',
          monthlySavings: 'eg: 600',
          communityMonthlyCost: 'eg: 80',
          IBIYearlyCost: 'eg: 400',
        },
      },
      adminForm: {
        title: 'Información de administración',
        labels: {
          admin: 'Adminstrador',
          email: 'Email del administrador',
          phone: 'Teléfono del administrador',
        },
        placeholders: {
          admin: 'eg: Pedro Alonso',
          email: 'eg: pedro@admin.com',
          phone: 'eg: 600700500',
        },
      },
      communityForm: {
        title: 'Información de la comunidad',
        labels: {
          president: 'Presidente de la comunidad',
          email: 'Email del Presidente de la comunidad',
          phone: 'Teléfono del Presidente de la comunidad',
        },
        placeholders: {
          president: 'eg: Alonso Rodriguez',
          email: 'eg: alonso@presidente.com',
          phone: 'eg: 633744855',
        },
      },
      optional: '(opcional)',
      saveAction: 'Guardar cambios',
    },

    dealflowDetail: {
      backToEngagementList: 'Volver a listado de contrataciones',
      contractId: (id: string) => `Contratación ${id}`,
      step: (number: number) => `Paso ${number}`,
      propertyId: 'ID Propiedad',
      title: 'Título',
      rentFee: 'Cuota alquiler',
      deposit: 'Entrada del 5%',
      buyer: 'Comprador',
      email: 'Email',
      phone: 'Teléfono',
      occupationDate: 'Fecha ocupación deseada',
      validate: 'Validar',
      reject: 'Rechazar',
      bookingDownpayment: 'Pago reserva',
      bookingDeposit: 'Cuota reserva',
      transferCopy: 'Fotocopia recibo transferencia',
      transferCopyLabel: 'reciboTransferencia.pdf',
      downpayment: 'Pago entrada',
      libeenContract: 'Contrato Libeen',
      copyOfTheContract: 'Copia del contrato firmado',
      addLink: 'Añadir enlace de contrato',
      ownerData: (number: number) => `Datos titular ${number}`,
      dataHasBeenRejected: 'Validación de datos rechazada',
      cancel: 'Cancelar',
      dataHasBeenCanceled: 'Compra cancelada',
      canceledDescription: 'La compra ha sido cancelada y el usuario ya no podrá continuar con ella.',
      toCurrency: (number: number) => `${formatToSpanishCurrency(number)}`,
      propertyIsDraft: 'La vivienda relacionada con la compra está en estado DRAFT',
      propertyIsDraftDescription:
        'La vivienda relacionada con esta compra ya no está disponible en el marketplace, el cliente no puede ver la vivienda por lo que solo tiene acceso a la información de la compra.',

      ownerLabels: {
        name: 'Nombre',
        surnames: 'Apellidos',
        dniNie: 'DNI/NIE',
        birthDate: 'Fecha nacimiento',
        address: 'Dirección residente',
        documentationType: 'Tipo de documentación',
        frontCopy: 'Fotocopia identidad frontal',
        undersideCopy: 'Fotocopia identidad trasera',
        passport: 'Fotocopia pasaporte',
        driver_license: 'Fotocopia carnet conducir',
        typeOfEmployee: 'Tipo de empleo',
        email: 'Correo',

        paysheet: (number: number) => `Últimas nomina ${number}`,
        socialSecutiry: (number: number) => `Doc seguridad social ${number}`,
        taxes: 'Doc impuestos sobre la renta',
        seniorityDoc: 'Doc. Antigüedad empresa',
        workTrajectory: 'Vida laboral',
        incomeCertificate: 'Doc certificado ingresos',
        restOfTheDownpayment: 'Cuota restante entrada',
        totalDownpayment: 'Cuota total entrada',
        copyOfTheTransfer: 'Fotocopia recibo transferencia',
        copySignedContract: 'Copia de contrato firmado',
      },
      validateModal: {
        validateStep: (stepNumber: number) => `Validar paso ${stepNumber}`,
        validatedSubtitle: 'Puedes añadir un comentario customizado para comunicarle al cliente que este paso ha sido aprobado.',
        validatedLabelTextArea: 'Mensaje de validación de paso',
        validatedPlaceHolder: 'Ya casi estamos. Ahora solo nos queda recibir el pago de la entrada final y firmar el contrato. Enhorabuena :)',
        validateAndCreateContract: 'Validar y crear contrato',
      },
      rejectModal: {
        rejectStep: (stepNumber: number) => `Rechazar paso ${stepNumber}`,
        rejectedSubtitle:
          'Deja un comentario para notificar al cliente de la razon por la cual se ha rechazado los datos entregados. Cuanto más claro sea el mensaje más fácil podrá rectificarlo.',
        rejectedLabelTextArea: 'Mensaje de rechazo de validación',
        rejectedPlaceHolder:
          '- El número de identidad introducido del segundo titular no coincide con el del documento aportado. \n- El número de identidad introducido del segundo titular no coincide con el del documento aportado. \n-El documento de identidad del tercer titular se ve borroso.',
        rejectValidation: ' Rechazar validación',
      },
      cancelModal: {
        cancelStep: 'Cancelar contratación',
        cancelSubtitle: 'Esta acción cancela permanmente este proceso de compra.',
        cancelValidation: ' Cancelar compra',
      },
    },
    dealflowsManager: {
      actions: {
        searchPlaceholder: 'Buscar por nombre, referencia, etc.',
      },
      filters: {
        all: 'Todos',
        pendingUserData: 'Pendientes usuario',
        checkingUserData: 'Checkeando datos usuario',
        makingOfferToOwner: 'Oferta al propietario',
        cancelled: 'Cancelado',
      },
      tableHead: {
        id: 'Ref.',
        propertySlug: 'Propiedad',
        userName: 'Comprador',
        createdAt: 'Creado',
        checkinDate: 'Check in',
        entryPayment: 'Entrada',
        pricePerMonth: 'Alquiler',
        status: 'Estado',
      },
    },
  },
  wizard: {
    titles: {
      default: '¿En qué ciudad quieres vivir?',
      defaultLastStep: 'Estás a un paso de encontrar la casa que buscas',
      noProperties: () => `Muy pronto estaremos en tu ciudad`,
      plannedCity: (city: string) => `Pronto tendremos casas disponibles en ${city}`,
      unknownCity: 'Cada día estamos presentes en más ciudades y pronto tendremos una casa para ti también',
      closeToFindTheHouseThatYouAreaLookingFor: 'Estás más cerca de encontrar la casa que buscas',
    },
    citySelection: {
      other: 'Quiero vivir en otra ciudad',
    },
    detailsSelection: {
      bedroomsLabel: '¿Cuántas habitaciones quieres tener en tu nueva casa? ',
      bedrooms: (beedrooms: number) => {
        return beedrooms === 1 ? '1 Habitación' : `${beedrooms} Habitaciones`
      },
      neighborhoodsLabel: (city: string) => `¿En que zonas de ${city} te gustaria vivir?`,
      zonesLabel: '¿En qué zonas te gustaría tener tu nueva casa?',
    },
    signUp: {
      pricePerMonthLabel: '¿Cuánto quieres pagar de alquiler al mes?',
      downPaymentLabel: '¿Cuánto podrías pagar de entrada para tu nueva casa?',
      userNameLabel: 'Déjanos tu nombre',
      userEmailLabel: 'Y un email para crear tu cuenta',
      userPhoneLabel: 'Y si quieres, déjanos tu teléfono también para poder ayudarte con la búsqueda',
      termsAndConditions:
        'Acepto los <a href="/legal" target="_blank" rel="noopener noreferrer">Términos de uso</a> y <a href="/privacy" target="_blank" rel="noopener noreferrer">Política de privacidad</a>', // TODO: change links to correct URLs
      newsletter: 'Acepto recibir ofertas de viviendas molonas',
      showHouses: 'Mostrarme casas',
      userAlreadyExistError: 'Esta cuenta ya existe.',
      linkToLogIn: 'Inicia sesión',
    },
    noProperties: {
      description: (city: any) => `Te avisaremos en cuanto lleguemos a ${city}. ¡Síguenos en nuestras redes sociales para no perderte nada!`,
      thanksForYourTime: (name: string) => `Gracias por tu tiempo ${name.split(' ')[0]}`,
      seeAllProperties: 'Ver todos los pisos que tenemos',
    },
  },
  purchase: {
    common: {
      helpAssist:
        ' Si tienes alguna duda puedes contactarnos por telefono o whatsapp en el +34 627 065 603 o enviándonos un email a <a target="_blank" rel="noopener noreferrer" href="mailto:ayuda@libeen.com">ayuda@libeen.com</a>',
      bankTransferAmount: (amount: number) =>
        `Haz una transferencia de <strong>${formatWithSeparators(amount)}€</strong> a la siguiente cuenta.`,
      bankTransferConcept: 'Incluye este código como concepto de la transferencia',
      bankTransferReceipt: 'Una vez hayas realizado la transferencia sube aquí una copia del resguardo de la misma.',
      endProcess: 'Finalizar compraventa',
    },
    boards: {
      welcome: '¡Bienvenido al #SMARTHOUSING!',
      stepOne: {
        bookYourHome: 'Reserva tu casa',
        stepOneExplanation:
          'En este step vamos a verificar todos tus datos y documentos personales. Los contrastaremos con nuestro seguro y en máximo 48hrs desde que hayamos recibido correctamente toda tu información, procederemos al proceso de compra. Por lo que necesitamos que tengas a mano:',
        personalData: 'Tus datos personales',
        idRequest: 'Fotocopia de tu documento de identidad',
        incomeDocuments: 'Tus documentos para verificar ingresos:',
        salariedWorkerDocuments: 'Si eres trabajador por cuenta ajena: 3 ultimas nóminas y la acreditación de la antigüedad en la empresa.',
        freelancerDocuments:
          'Si eres autónomo: la última declaración del impuesto sobre la renta (IRPF) y los 2 últimos recibos de la Seguridad Social o pago fraccionado del IVA.',
        pensionerDocuments: 'Si eres pensionista: necesitamos el certificado de ingresos actualizado.',
        temporalWorkerDocuments: 'Para cualquier tipo de contrato temporal: necesitamos tu Vida Laboral.',
        requestProof: 'El justificante y la transferencia de 2.000€ para reservar',
        moneyRefund:
          'Ten en cuenta que hasta que no tengamos la transferencia hecha, no podemos proceder a reservarte el piso. En caso de que no podamos seguir adelante con la reserva, esta cantidad te será devuelta íntegra.',
      },

      stepTwo: {
        buyYourHome: 'Compra tu casa',
        buyTheHouse: 'Una vez verifiquemos tus datos anteriores procederemos al este último step. ¡Comprar tu casa!',
        signContract: 'Firma del contrato de #SmartHousing.',
        remainingAmount: 'Transferencia de la cantidad restante de la entrada.',
        deposit: 'Es decir, la entrada menos los 2.000€ que ya has aportado para reservarlo.',
      },
    },

    stepOne: {
      intro: {
        title: '¡Enhorabuena! This is the smart way to buy your home',
        subtitle:
          'Debajo te detallamos los pasos a seguir para comprar tu casa. Verás que todo lo podrás hacer online de una forma transparente friendly y segura.',
        start: 'Inicia la compra de tu casa',
      },
      home: {
        title: 'Verifica e introduce tus datos',
        titleAdditionalOwnersInfo: (currentOwner: number) =>
          `Introduce los datos del ${currentOwner === 2 ? 'segundo' : currentOwner === 3 ? 'tercer' : 'cuarto'} titular del contrato`,
        subtitle: 'Asegúrate que estos datos son correctos y válidos',
        legalName: 'Nombre legal',
        legalSurname: 'Apellido legal',
        email: 'Email',
        phoneNumber: 'Número de teléfono',
        birthDate: 'Fecha de nacimiento',
        address: 'Dirección de residencia',
        checkinDate: 'Fecha deseada de entrada',
        totalOwners: '¿Quieres añadir titulares adicionales?',
        owners: {
          onlyOne: 'No, Solo yo seré el titular',
          twoOwners: 'Si, 1 titular adicional',
          threeOwners: 'Si, 2 titulares adicionales',
          fourOwners: 'Si, 3 titulares adicionales',
        },
        helpAssist:
          ' Si tienes alguna duda puedes contactarnos por telefono o whatsapp en el +34 627 065 603 o enviándonos un email a <a target="_blank" rel="noopener noreferrer" href="mailto:ayuda@libeen.com">ayuda@libeen.com</a>',
      },
      documentation: {
        title: (currentOwner: number) =>
          `Envíanos la documentación del ${
            currentOwner === 1 ? 'primer' : currentOwner === 2 ? 'segundo' : currentOwner === 3 ? 'tercer' : 'cuarto'
          } titular del contrato`,
        subtitle: 'Selecciona el tipo de documento de identidad que prefieres en el desplegable y sube una copia.',
        documentType: 'Tipo de documento de identidad',
        documentFront: (documentType: string, backNeeded = true) =>
          backNeeded ? `Sube una copia del frontal de tu ${documentType}` : `Sube una copia de tu ${documentType}`,
        documentBack: (documentType: string) => `Sube una copia de la parte trasera de tu ${documentType}`,

        numberOfDocumentation: (documentType?: string) => `Número de ${documentType || 'identificación'}`,
        type: 'Tipo de documento de identidad',
        dni: 'DNI',
        nie: 'NIE',
        passport: 'Pasaporte',
        driverLicense: 'Carnet de conducir',
      },
      reservationPayment: {
        title: 'Realiza la reserva',
        description: (reservationFee: number) =>
          `Para que podamos reservarte tu casa y dejar de comercializarla, necesitamos que la señalices con un importe de ${formatWithSeparators(
            reservationFee
          )}€. Recuerda que si por algún motivo no podemos seguir adelante, esta cantidad se te devolverá íntegra.`,
      },
      employmentInfo: {
        title: (currentOwner: number) =>
          `Verifica el estado laboral del ${
            currentOwner === 1 ? 'primer' : currentOwner === 2 ? 'segundo' : currentOwner === 3 ? 'tercer' : 'cuarto'
          } titular del contrato`,
        description:
          'Es de vital importancia que conozcamos tu situación laboral. Toda la información que nos proporciones la trataremos con total confidencialidad. Por favor, rellena los siguientes datos',
        whatAreYou: '¿Como desenpeñas tu trabajo laboral?',
        uploadPayroll: 'Sube tus tres últimas nominas',
        seniority: 'Acreditación de antigüedad laboral en la empresa',
        taxInspector: 'Sube tu última declaración del impuesto sobre la renta',
        socialInsurance: 'Sube los 2 últimos recibos de la Seguridad Social',
        laboralLife: 'Sube tu documento de vida laboral',
        incomes: 'Sube tu certificado de ingresos actualizado',
        salaried: 'Cuenta Ajena',
        temporal: 'Contrato temporal',
        freelancer: 'Autónomo',
        pensioner: 'Pensionista',
      },
      status: {
        verifying: {
          title: '¡Fantástico! Ya queda muy poquito para que estrenes casa',
          description:
            'Vamos a revisar y verificar tus documentos y la transferencia. En un máximo de 48 horas laborables recibirás noticias nuestras sobre tu scoring y check financiero. Te notificaremos en cuanto tengamos todo validado para seguir con tu proceso de compra y firmar el contrato. Recuerda que tendrás 10 días desde que recibes el contrato para efectuar la firma del mismo.',
          buttonText: 'Ir a mi cuenta',
        },
        validated: {
          title: '¡Buenas noticias! ¡Ya tienes reservada tu casa!',
          description:
            'Todos tu datos y el pago de reserva han sido recibidos exitosamente. Tu casa está reservada. Ya te queda muy poco para poder hacer las maletas y mudarte.',
          buttonText: 'Continuar con la compra',
        },
        documentError: {
          title: 'Oops! Hay un error con algún dato o documento que subiste',
          description:
            'En el siguiente link puedes acceder directamente a verificar y revisar los documentos y datos que no hemos recibido correctamente.',
          buttonText: 'Revisar datos',
        },
        transferError: {
          title: 'Oops! No hemos recibido la transferencia correctamente',
          description:
            'Consulta con tu banco y asegúrate de que usaste los datos bancarios correctos. Pronto nos pondremos en contacto contigo para solucionarlo.',
          buttonText: 'Revisar datos bancarios',
        },
      },
    },
    stepTwo: {
      downPayment: {
        step: 'Paso 2',
        title: 'Y por último que nos pagues el resto de la entrada y firmes el contrato de tu casa',
        description: (downpayment: number, reservationFee: number) =>
          `Para finalizar el proceso de compra necesitamos que pagues el importe de la entrada que son ${formatWithSeparators(
            downpayment
          )}€ menos los ${formatWithSeparators(reservationFee)}€ que ya pagaste en concepto de reserva. Sería un total de ${formatWithSeparators(
            downpayment - reservationFee
          )}€ y ya podrás entrar en tu nueva casa.`,
        youWillReceiveAnEmail:
          'En breve recibirás un correo con el contrato, lee y firma tu contrato. Si sois varios titulares os llegará el contrato de forma simultánea. Tienes que subir una copia del resguardo de la transferencia a la hora de firmar tu contrato.',
      },
      contract: {
        step: 'Paso 2',
        title: 'Y por último, que firmes el contrato de tu casa',
        description1:
          'Una vez el contrato esté firmado vamos a revisar y verificar que el contrato este firmado correctamente y que hemos recibido la transferencia. Te notificaremos por email en cuanto tengamos todo validado.',
        description2:
          'Este ya es el ultimo paso, luego gestionaremos la entrega de llaves y tendrás acceso al portal de Libeen para gestionar el resto de pagos de tu vivienda.',
      },
      status: {
        verifying: {
          title: '¡Perfecto! Solo queda confirmar que todo esté correcto',
          description:
            'Vamos a revisar y verificar que el contrato esté firmado correctamente y que hemos recibido la transferencia. Te notificaremos por email en cuanto tengamos todo validado y podamos quedar para entregarte las llaves.',
          buttonText: 'Ir a mi cuenta',
        },
        owner: {
          title: '¡Bienvenido/a a la familia de #SmartHousers! Estamos muy orgullosos de ti :)',
          description:
            'Ya eres uno/a más de la familia Libeen, pronto te contactaremos para entregarte las llaves de tu casa y toda la información que necesitas.',
          buttonText: 'Ir a mi panel de administración',
        },
      },
    },
    cancelled: {
      title: 'Vaya! Tu proceso de compra ha sido cancelado.',
      description:
        'No te preocupes, te devolveremos el importe íntegro de la reserva realizada, así como cualquier pago que nos hayas enviado. Para más tranquilidad, puedes contactarnos por teléfono o email.',
      buttonText: 'Ir a mi cuenta',
    },
  },
  landing: {
    title: 'Usa tu alquiler para comprar tu casa',
    subtitle: 'Descubre el #SmartHousing: el nuevo alquiler con opción a compra',
    howItWorks: 'Cómo funciona el Smarthousing',
    investInYourNewHome: 'Empieza a invertir en tu futuro hogar, mientras dejas de malgastar en un simple alquiler',
    buttonFindHouse: 'Quiero encontrar mi casa',
    buttonLookOurHouses: 'Ver todas nuestras casas',
    features: {
      firstFeature: {
        title: 'Sin ataduras',
        subtitle: '¡Somos flexibles! Cuéntanos cual es tu situación y te ayudaremos a encontrar el plan que mejor se adapte a tus necesidades.',
      },
      secondFeature: {
        title: 'Rápido y Sencillo',
        subtitle:
          'Todo el proceso de compra lo haces online de una forma rápida y sencilla, sin necesidad de bancos, tasadores, notarios o papeles.',
      },
      thirdFeature: {
        title: 'Alquiler Smart',
        subtitle: 'Tienes los beneficios del alquiler y la ventaja de comprar. Olvídate de impuestos y seguros, nosotros nos ocupamos',
      },
      title: 'Descubre la revolución del Smarthousing',
      subtitle: 'Cuando dejas de tirar tu dinero en el alquiler y lo empiezas a usar para comprarte tu casa.',
    },
    yourFuture: {
      startsHere: 'Tu gran historia empieza aquí.',
      investInYourFuture: 'Empieza a invertir en tu futuro gracias al alquiler con opción a compra.',
      chooseYourNewHome: 'Elige cómo será tu nuevo hogar',
      tellUsWeWillHelpYou:
        'Cuéntanos cómo y dónde te gustaría vivir. Nosotros te ayudaremos a encontrar el lugar ideal para ti en base a tus gustos y necesidades.',
      chooseTheRent: 'Decide cuánto quieres pagar de alquiler',
      yourNewLife: ' Es tu nueva vida, tu decides como será. Mientras pagas alquiler inviertes en tu nueva casa.',
      weHaveThePerfectHouse: 'Tenemos la casa ideal para ti',
      chooseWhatYouWant:
        'En base a tus necesidades te mostramos las casas que mejor se adapten a ti. Solo tienes que elegir la que más te guste.',
    },
    description: {
      title: 'Ahorra sin darte cuenta',
      subtitle: 'Con nuestros planes podrás ahorrar ese 20% que cualquier banco exige a la hora de comprar una vivienda.',
      weKnow: 'Aportando una pequeña entrada y junto con las cuotas mensuales, como si fuese un alquiler, empezarás a comprar tu casa.',
      smallAmount:
        'Sabemos lo complicado que es empezar a comprar tu propia vivienda. Es por esto, que hemos inventado el SmartHousing. El precio de tu vivienda quedará fijado desde el principio.',
      weAreFlexible:
        'Y, por cierto: ¡somos flexibles! Cuéntanos cual es tu situación y te ayudaremos a encontrar el plan que mejor se adapte a tus necesidades.',
      button: 'Quiero saber más',
    },
    seeAllOurHouses: 'Ver todas nuestras casas',
    ourHouses: {
      title: 'Si has cambiado la TV por Netflix, cambia el alquiler por el SmartHousing',
      subtitle: 'Tenemos muchas casas para que puedas elegir, solo tienes que dar el primer paso...',
      actionButton: 'Ver todas nuestras casas',
    },
    perks: {
      title: 'Hemos inventado el Smarthousing, la nueva forma de comprar tu casa',
      subtitle:
        'Nuestra plataforma te facilita ver lo que tienes ahorrado para tu casa, tus pagos, tu contrato etc... Porque recuerda que disfrutas de los beneficios del alquiler, con la ventaja de comprar. Todo con la última tecnología para hacer tu vida más fácil.',
      onlineManagement: {
        title: 'Gestión online',
        description: 'Gestiona los pagos, mantenimiento y ten todo en orden y al día con nuestra sencilla aplicación.',
      },
      multiPlatform: {
        title: 'Multiplataforma',
        description: 'Accede a nuestra plataforma desde cualquier dispositivo, tu tablet u ordenador. Siempre nos tendrás a mano.',
      },
      onlineSupport: {
        title: 'Soporte Online',
        description:
          'Estamos siempre a tu disposición, puedes llamarnos por teléfono o contactarnos vía WhatsApp o email. Estamos siempre pendientes.',
      },
    },
    whatOurClientSays: {
      title: 'Lo que dicen nuestros clientes.',
      subtitle: 'Si es que aún te queda alguna duda...<br/>descubre lo que piensan nuestros queridos clientes',
    },
    testimonial: {
      one: {
        name: 'Jennifer Fernández Vicente',
        review: 'Si buscas casas y estás harto de sentir que tiras el dinero alquilando sin duda ellos son tus personas!!!',
        information: {
          city: 'Ciudad',
          age: 'Edad',
          job: 'Ocupación',
        },
      },
      two: {
        name: 'Juan Carlos Martínez',
        review:
          'La experiencia ha sido formidable. Se han preocupado de todo en todo el proceso cuidando el más mínimo detalle. Son muy serviciales y profesionales. Además, son proactivos a la hora de ofrecerte nuevas oportunidades y te asesoran muy bien.',
        information: {
          city: 'Ciudad',
          age: 'Edad',
          job: 'Ocupación',
        },
      },
      three: {
        name: 'Álvaro Ortiz Moya',
        review:
          'Un trato maravilloso por parte de todos ellos, muy atentos y profesionales en todo momento. Ha sido una buena experiencia y es una manera perfecta de comprarte una casa hoy en día.',
        information: {
          city: 'Ciudad',
          age: 'Edad',
          job: 'Ocupación',
        },
      },
      four: {
        name: 'Lucía Delgado',
        review: 'Muy profesionales, trato personalizado y gran dominio del mercado. Muy recomendables.',
        information: {
          city: 'Ciudad',
          age: 'Edad',
          job: 'Ocupación',
        },
      },
      five: {
        name: 'Gonzálo de Andrés Blanco',
        review:
          'Solo puedo decir cosas buenas de libeen, sobre todo desde el punto de vista a la hora de vender mi piso con ellos ya que tardaron tan sólo 5 días en realizar la operación y siendo rápidos ,con calidad y claridad en la venta. Creo que esta fórmula tanto a la hora de comprar como de vender tu vivienda es perfecta y más en los tiempos que vivimos. Muchas gracias.',
        information: {
          city: 'Ciudad',
          age: 'Edad',
          job: 'Ocupación',
        },
      },
      livingPreferences: 'Preferencias de vivienda',
    },
    ready: {
      title: 'Ready?',
      subtitle: 'Descubre el nuevo alquiler con opción a compra',
      iWantToBeASmartHouser: 'Quiero ser SmartHouser',
    },
    partners: {
      ourFriends: '¡Nuestros amigos!',
    },
    contactWithUs: {
      title: 'Contacta con nosotros',
      subtitle: 'Si te quedan más dudas ponte en contacto con nosotros',
      phone: '<a target="_blank" rel="noopener noreferrer" href="https://wa.me/34627065603">+34 627 06 56 03</a>',
      mail: '<a target="_blank" rel="noopener noreferrer" href="mailto:ayuda@libeen.com">ayuda@libeen.com</a>',
    },
  },
  owners: {
    title: '¿Estás pensando en vender tu casa?',
    subtitle: 'Hemos inventado el Smart Housing para que te centres en tu vida, no en vender tu casa.',
    howItWorks: 'Tú decides cuando vender',
    ourModels: '¡Somos flexibles! Nos adaptamos a tus necesidades y tenemos varios planes de venta.',
    buttonFindHouse: 'Publica tu casa gratis',
    buttonLookOurHouses: 'Ver todas nuestras casas',
    features: {
      firstFeature: {
        title: 'Flexibilidad',
        subtitle:
          '¡Somos flexibles! Puedes seguir comercializando tu vivienda por la vía tradicional. Nosotros somos una opción más que te abre un amplio abanico de nuevos compradores.',
      },
      secondFeature: {
        title: 'Seguridad',
        subtitle:
          'Gracias al SmartHousing fijas tu precio y así evitarás las bajadas de precio que pueda haber en los próximos meses dada la incertidumbre de mercado actual.',
      },
      thirdFeature: {
        title: 'Venta rápida y smart',
        subtitle: ' En menos de 1 mes tendrás a tu SmartHouser comprando tu vivienda. ¿No te lo crees? Te animamos a comprobarlo ;)',
      },
      title: 'Vende tu casa con Smarthousing',
      subtitle: 'Accede a más de 17.000 personas que quieren comprar con nosotros, y dale salida rápida a tu vivienda.',
    },
    description: {
      title: 'Libertad absoluta',
      subtitle: 'Gracias a la variedad de planes que tenemos disponibles, podrás vender tu casa con total flexibilidad y cuando tú quieras.',
      evenInOneYear: 'Evita las incómodas negociaciones agresivas del mercado actual y cierra tu precio con nosotros.',
      youCanContact: 'Contacta con nuestro equipo para que puedas recibir una propuesta personalizada para vender tu casa.',
      whenTheProccessFinishes:
        'Al finalizar el período que tú hayas elegido, tu comprador ejecutará la compra. Si no compra, te quedarás con lo que haya aportado.',
      appointment: 'Agendar una cita',
      appointmentCall: 'Agendar una llamada',
    },
    workingExplanation: {
      title: '¿Quieres vender tu casa ya?',
      subtitle: 'Nuestro modelo de alquiler con opción a compra se adapta a todas las necesidades:',
      weHelpYou: 'Te acompañamos durante el proceso',
      startToInvest: 'Nosotros siempre os acompañaremos a ti y tu comprador para ayudar en la gestión.',
      step1: '1. Valoramos tu vivienda',
      step1explainer:
        'Te ofrecemos asesoramiento personalizado para fijar el mejor precio y anunciamos tu vivienda en nuestro marketplace. Nos encargamos de gestionar las visitas y si lo necesitas, realizamos las fotografías.',
      step2: '2. Análisis de solvencia del potencial comprador',
      step2explainer: 'Aseguramos compradores solventes, de esta manera, no habrá ningún imprevisto económico durante la duración del contrato.',
      step3: '3. Trabajamos a éxito',
      step3explainer:
        'Obtenemos beneficio si tú obtienes beneficio. Nuestra comisión es completamente a éxito. Además, recuerda que esta opción es totalmente complementaria a una comercialización de venta directa.',
      step4: '4. Siempre a tu disposición',
      step4explainer:
        'Mantenemos el contacto con el comprador y contigo hasta que se ejecute el contrato de compra. Siempre os vamos a acompañar.',
    },
    form: {
      title: '¿Nos llamas o te llamamos?',
      subtitle:
        'Puedes contactar con nosotros directamente al <a target="_blank" rel="noopener noreferrer" href="https://wa.me/34627065603">+34 627 06 56 03</a> o nosotros te llamamos y nos ponemos manos a la obra para darle una salida ágil a tu vivienda.',
      name: {
        title: 'Nombre',
        placeholder: 'ej: Paula Hernández',
      },
      email: {
        title: 'Email',
        placeholder: 'ej: nombre@dominio.com',
      },
      phone: {
        title: 'Teléfono',
        placeholder: 'ej: 660 123 123',
      },
      exitPrice: {
        title: 'Precio de salida',
        placeholder: 'Elige un precio de salida',
      },
      city: {
        title: 'Ciudad',
        placeholder: 'Elige una ciudad',
      },
    },
    whatOurClientSays: {
      title: 'Lo que dicen nuestros clientes.',
      subtitle: 'Si es que aún te queda alguna duda... descubre lo que piensan nuestros queridos clientes',
    },
    ourHouses: {
      title: 'Cambia el alquiler por el SmartHousing',
      subtitle: 'Tenemos más de 200 listas para estrenar, solo tienes que dar el primer paso.',
    },
    testimonial: {
      name: 'Patricia Hart',
      review: `Un día después de ponerme en contacto, vinisteis a tomar las fotos y, esa misma tarde, la propiedad ya estaba publicada. En cuestión de días la vivienda ya estaba vendida. <br/>Gracias.`,
      information: {
        city: 'Ciudad',
        age: 'Edad',
        job: 'Ocupación',
      },
      livingPreferences: 'Características de la vivienda:',
    },
    ready: {
      title: 'Ready?',
      subtitle: 'Vende tu casa rápido y despreocúpate de los problemas',
      iWantToBeASmartHouser: 'Quiero vender mi casa',
    },
    partners: {
      ourFriends: '¡Nuestros amigos!',
    },
    contactWithUs: {
      title: 'Contacta con nosotros',
      subtitle: 'Si te quedan más dudas ponte en contacto con nosotros',
      phone: '<a target="_blank" rel="noopener noreferrer" href="tel://34911983336">+34 911 983 336</a>',
      mobile: '<a target="_blank" rel="noopener noreferrer" href="https://wa.me/34627065603">+34 627 065 603</a>',
      mail: '<a target="_blank" rel="noopener noreferrer" href="mailto:propietarios@libeen.com">propietarios@libeen.com</a>',
    },
  },
  legal: {
    title: 'Aviso legal',
    subtitle: ' Aviso legal y condiciones generales de uso del sitio web',
    website: 'www.libeen.com',
    generalInfo: {
      title: 'Información General',
      paragraphOne:
        'En cumplimiento con el deber de información dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Información y el Comercio Electrónico (LSSI-CE) de 11 de julio, se facilitan a continuación los siguientes datos de información general de este sitio web:',
      paragraphTwo: 'La titularidad de este sitio web, www.libeen.com, (en adelante Sitio Web) la ostenta:',
      paragraphThree:
        'Libeen Smart Housing, S.L. (en adelante Libeen), provista de NIF: B-88469036 e inscrita en: Registro Mercanitl de Madrid; y sus datos registrales son: Tomo 33587, Folio 11, Sección 8, Hoja M-604515 y cuyos datos de contacto son:',
      street: 'Calle Piamonte 23',
      postalCode: 'CP 28004 Madrid',
      telephone: 'Tlf 610413747',
      email: 'hello@libeen.com',
    },
    termsAndConditions: {
      title: 'Términos y condiciones generales de uso. El objeto de las condiciones: el sitio web',
      paragraphOne:
        'El objeto de las presentes Condiciones Generales de Uso (en adelante Condiciones) es regular el acceso y la utilización del Sitio Web. A los efectos de las presentes Condiciones se entenderá como Sitio Web:',
      paragraphTwo:
        'La apariencia externa de los interfaces de pantalla, tanto de forma estática como de forma dinámica, es decir, el árbol de navegación; y todos los elementos integrados tanto en los interfaces de pantalla como en el árbol de navegación (en adelante Contenidos) y todos aquellos servicios o recursos en línea que en su caso ofrezca a los Usuarios (en adelante Servicios).',
      paragraphThree:
        'Libeen se reserva la facultad de modificar, en cualquier momento, y sin aviso previo, la presentación y configuración del Sitio Web y de los Contenidos y Servicios que en él pudieran estar incorporados. El Usuario reconoce y acepta que en cualquier momento Libeen pueda interrumpir, desactivar y/o cancelar cualquiera de estos elementos que se integran en el Sitio Web o el acceso a los mismos.',
      paragraphFour:
        'El acceso al Sitio Web por el Usuario tiene carácter libre y, por regla general, es gratuito sin que el Usuario tenga que proporcionar una contraprestación para poder disfrutar de ello, salvo en lo relativo al coste de conexión a través de la red de telecomunicaciones suministrada por el proveedor de acceso que hubiere contratado el Usuario.',
      paragraphFive:
        'La utilización de alguno de los Contenidos o Servicios del Sitio Web podrá hacerse mediante la suscripción o registro previo del Usuario.',
    },
    user: {
      title: 'El usuario',
      paragraphOne:
        'El acceso, la navegación y uso del Sitio Web, confiere la condición de Usuario, por lo que se aceptan, desde que se inicia la navegación por el Sitio Web, todas las Condiciones aquí establecidas, así como sus ulteriores modificaciones, sin perjuicio de la aplicación de la correspondiente normativa legal de obligado cumplimiento según el caso. Dada la relevancia de lo anterior, se recomienda al Usuario leerlas cada vez que visite el Sitio Web.',
      paragraphTwo:
        'El Sitio Web de Libeen proporciona gran diversidad de información, servicios y datos. El Usuario asume su responsabilidad para realizar un uso correcto del Sitio Web. Esta responsabilidad se extenderá a:',
      paragraphThree:
        'Un uso de la información, Contenidos y/o Servicios y datos ofrecidos por Libeen sin que sea contrario a lo dispuesto por las presentes Condiciones, la Ley, la moral o el orden público, o que de cualquier otro modo puedan suponer lesión de los derechos de terceros o del mismo funcionamiento del Sitio Web.',
      paragraphFour:
        'La veracidad y licitud de las informaciones aportadas por el Usuario en los formularios extendidos por Libeen para el acceso a ciertos Contenidos o Servicios ofrecidos por el Sitio Web. En todo caso, el Usuario notificará de forma inmediata a Libeen acerca de cualquier hecho que permita el uso indebido de la información registrada en dichos formularios, tales como, pero no sólo, el robo, extravío, o el acceso no autorizado a identificadores y/o contraseñas, con el  fin de proceder a su inmediata cancelación.',
      paragraphFive:
        'El mero acceso a este Sitio Web no supone entablar ningún tipo de relación de carácter comercial entre Libeen y el Usuario.',
      paragraphSix:
        'El Usuario declara ser mayor de edad y disponer de la capacidad jurídica suficiente para vincularse por las presentes Condiciones. Por lo tanto, este Sitio Web de Libeen no se dirige a menores de edad. Libeen declina cualquier responsabilidad por el incumplimiento de este requisito.',
      paragraphSeven:
        'El Sitio Web está dirigido principalmente a Usuarios residentes en España. Libeen no asegura que el Sitio Web cumpla con legislaciones de otros países, ya sea total o parcialmente. Si el Usuario reside o tiene su domiciliado en otro lugar y decide acceder y/o navegar en el Sitio Web lo hará bajo su propia responsabilidad, deberá asegurarse de que tal acceso y navegación cumple con la legislación local que le es aplicable, no asumiendo Libeen responsabilidad alguna que se pueda derivar de dicho acceso.',
    },
    guaranteesAndResponsibility: {
      title: 'Acceso y navegación en el sitio web: Exclusión de Garantías y Responsabilidad ',
      paragraphOne:
        'Libeen no garantiza la continuidad, disponibilidad y utilidad del Sitio Web, ni de los Contenidos o Servicios. Libeen hará todo lo posible por el buen funcionamiento del Sitio Web, sin embargo, no se responsabiliza ni garantiza que el acceso a este Sitio Web no vaya a ser ininterrumpido o que esté libre de error.',
      paragraphTwo:
        'Tampoco se responsabiliza o garantiza que el contenido o software al que pueda accederse a través de este Sitio Web, esté libre de error o cause un daño al sistema informático (software y hardware) del Usuario. En ningún caso Libeen será responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan por el acceso, navegación y el uso del Sitio Web, incluyéndose, pero no limitándose, a los ocasionados a los sistemas informáticos o los provocados por la introducción de virus.',
      paragraphThree:
        'Libeen tampoco se hace responsable de los daños que pudiesen ocasionarse a los usuarios por un uso inadecuado de este Sitio Web. En particular, no se hace responsable en modo alguno de las caídas, interrupciones, falta o defecto de las telecomunicaciones que pudieran ocurrir.',
    },
    linksPolicy: {
      title: 'Política de enlaces',
      paragraphOne:
        'El Usuario o tercero que realice un hipervínculo desde una página web de otro, distinto, sitio web al Sitio Web de Libeen deberá saber que:',
      paragraphTwo:
        'No se permite la reproducción —total o parcialmente— de ninguno de los Contenidos y/o Servicios del Sitio Web sin autorización expresa de Libeen.',
      paragraphThree:
        'No se permite tampoco ninguna manifestación falsa, inexacta o incorrecta sobre el Sitio Web de Libeen, ni sobre los Contenidos y/o Servicios del mismo.',
      paragraphFour:
        'A excepción del hipervínculo, el sitio web en el que se establezca dicho hiperenlace no contendrá ningún elemento, de este Sitio Web, protegido como propiedad intelectual por el ordenamiento jurídico español, salvo autorización expresa de Libeen.',
      paragraphFive:
        'El establecimiento del hipervínculo no implicará la existencia de relaciones entre Libeen y el titular del sitio web desde el cual se realice, ni el conocimiento y aceptación de Libeen de los contenidos, servicios y/o actividades ofrecidos en dicho sitio web, y viceversa.',
    },
    intelectualProperty: {
      title: 'Propiedad Intelectual e Industrial',
      paragraphOne:
        'Libeen por sí o como parte cesionaria, es titular de todos los derechos de propiedad intelectual e industrial del Sitio Web, así como de los elementos contenidos en el mismo (a título enunciativo y no exhaustivo, imágenes, sonido, audio, vídeo, software o textos, marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.). Serán, por consiguiente, obras protegidas como propiedad intelectual por el ordenamiento jurídico español, siéndoles aplicables tanto la normativa española y comunitaria en este campo, como los tratados internacionales relativos a la materia y suscritos por España.',
      paragraphTwo:
        'Todos los derechos reservados. En virtud de lo dispuesto en la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de Libeen.',
      paragraphThree:
        'El Usuario se compromete a respetar los derechos de propiedad intelectual e industrial de Libeen. Podrá visualizar los elementos del Sitio Web o incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, exclusivamente, para su uso personal. El Usuario, sin embargo, no podrá suprimir, alterar, o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en el Sitio Web.',
      paragraphFour:
        'En caso de que el Usuario o tercero considere que cualquiera de los Contenidos del Sitio Web suponga una violación de los derechos de protección de la propiedad intelectual, deberá comunicarlo inmediatamente a Libeen a través de los datos de contacto del apartado de INFORMACIÓN GENERAL de este Aviso Legal y Condiciones Generales de Uso.',
    },
    legalActions: {
      title: 'Acciones legales, Legislación aplicable y Jurisdicción',
      paragraphOne:
        'Libeen se reserva la facultad de presentar las acciones civiles o penales que considere necesarias por la utilización indebida del Sitio Web y Contenidos, o por el incumplimiento de las presentes Condiciones.',
      paragraphTwo:
        'La relación entre el Usuario y Libeen se regirá por la normativa vigente y de aplicación en el territorio español. De surgir cualquier controversia en relación a la interpretación y/o a la aplicación de estas Condiciones las partes someterán sus conflictos a la jurisdicción ordinaria sometiéndose a los jueces y tribunales que correspondan conforme a derecho.',
    },
  },
  privacyPolicy: {
    title: 'Política de Privacidad',
    subtitle:
      'La presente Política de Privacidad solo es aplicable a los siguientes sitios web: www.libeen.com. Quedan fuera del alcance de esta Política de Privacidad otros sitios web relacionados directa o indirectamente con este Sitio web.',
    description: {
      title: '1. Breve descripción de la Protección de Datos',
      paragraphOne:
        'Esta sección le ofrece un breve resumen sobre la recopilación, el tratamiento y el uso de sus datos personales cuando visita y/o interactúa con este sitio web, sus contenidos y servicios. Los detalles de nuestras prácticas y sus derechos al respecto se analizan con más detalle en esta política de privacidad.',
      paragraphTwo: 'Con el fin de proporcionarle este sitio web, recopilamos, procesamos y utilizamos los siguientes datos.',
      paragraphThree:
        'Datos de acceso: Con respecto a cada una de sus visitas a este sitio web, nuestros servidores web registran información técnica que su navegador transmite automáticamente. Esto incluye la dirección de protocolo de Internet (IP) utilizada para conectar su ordenador o dispositivo móvil a Internet, el tipo de navegador y la versión que utiliza, el sistema operativo y la fecha y hora de su acceso. Datos personales que usted nos facilita: Recopilamos información sobre usted que usted mismo nos proporciona activamente mediante el uso de funciones especiales de este sitio web, como nuestros formularios de contacto. Estos datos incluyen su dirección de correo electrónico y otros datos necesarios para la prestación y uso de los respectivos servicios. Se aplica lo mismo en caso de que se suscriba a nuestro boletín de noticias por correo electrónico.',
      paragraphFour:
        'Datos personales que usted nos facilita: Recopilamos información sobre usted que usted mismo nos proporciona activamente mediante el uso de funciones especiales de este sitio web, como nuestros formularios de contacto. Estos datos incluyen su dirección de correo electrónico y otros datos necesarios para la prestación y uso de los respectivos servicios. Se aplica lo mismo en caso de que se suscriba a nuestro boletín de noticias por correo electrónico.',
      paragraphFive:
        'Datos de uso: Además, recopilamos activamente más información sobre su visita a este sitio web y el uso de sus servicios y contenidos. Esa información incluye el flujo de clics de los localizadores de recursos uniformes completos (URL) hacia, a través y desde este sitio web (incluyendo fecha y hora), subpáginas visitadas, información que usted buscó, tiempos de respuesta, duración de las visitas a ciertas páginas, aperturas de correo electrónico y en qué banners o enlaces en el sitio web o en un correo electrónico se hizo clic. Utilizamos tales datos con diferentes fines. Por ejemplo, utilizamos esta información para comprender mejor sus necesidades y preferencias, de modo que podamos mejorar este sitio web y sus funciones y proporcionarle contenidos más relevantes y basados en sus intereses.',
    },
    WhoWeAre: {
      title: '2. Quiénes somos y qué hacemos',
      paragraphOne:
        'Este sitio web ("Sitio web") le ofrece información respecto a la actividad empresarial de LIBEEN Smart Housing SL e información relativa al Smart Housing, alquileres con opción a compra y diferentes viviendas. Asimismo el usuario puede facilitarnos información personal respecto a su propia búsqueda de apartamentos.',
      paragraphTwo:
        'El operador de este Sitio web es Libeen Smart Housing, S.L. (en adelante “Libeen”), provista de NIF: B-88469036 e inscrita en: Registro Mercanitl de Madrid; y sus datos registrales son: tomo 33587, Folio 11, Sección 8, Hoja M-604515 y cuyos datos de contacto son:',
      street: 'Calle Piamonte 23',
      city: 'Madrid',
      postalCode: 'CP 28004.',
      paragraphThree:
        'Recopilamos y procesamos sus datos personales de acuerdo con las leyes de protección de datos aplicables, en particular con el Reglamento General de Protección de Datos, Reglamento (UE) 2016/679 ("RGPD"). En la medida en que obtengamos su consentimiento para el tratamiento de sus datos personales (por ejemplo, al suscribirse a nuestro boletín de noticias), el art. 6(1), letra a y el art. 6(1), letra c del RGPD constituyen la base jurídica a tal efecto.  En lo que respecta a cualquier otra actividad de tratamiento según se describe en esta política de privacidad, el tratamiento se basa en el art. 6(1), letra f.1 del RGPD, en virtud del cual se permite el tratamiento de datos personales, si este es necesario para los fines de los intereses legítimos perseguidos por nuestra empresa o un tercero, excepto cuando dichos intereses queden anulados por intereses o derechos y libertades fundamentales de nuestros usuarios que requieren protección de datos personales.',
    },
    accessWebsite: {
      title: '3. Acceso y uso del sitio web',
      paragraphOne: 'Datos de acceso (archivos de registro)',
      paragraphTwo:
        'Con cada carga de este Sitio web, nuestros servidores almacenan automáticamente los datos de acceso general sobre este proceso en los archivos de registro del servidor.',
      paragraphThree: {
        text: 'Estos datos incluyen:',
        bulletPointOne: 'el Sitio web cargado',
        bulletPointTwo: 'la dirección IP del sistema solicitante',
        bulletPointThree: 'el tipo de navegador y la versión utilizada',
        bulletPointFour: 'el sistema operativo utilizado',
      },
      paragraphFour:
        'No agregamos dichos datos con otras fuentes de datos. El almacenamiento temporal de la dirección IP en archivos de registro es necesario por razones técnicas y para garantizar la seguridad de nuestro sistema, en particular en relación con el reconocimiento del uso indebido. Este fin constituye también nuestro legítimo interés de acuerdo con el art. 6, párr. 1, letra f del RGPD. Únicamente conservamos dichos datos durante el tiempo que sea necesario para los fines indicados.',
      paragraphFive: 'Las direcciones IP se borran o enmascaran en un plazo máximo de 14 días.',
    },
    functions: {
      title: '4. Funciones especiales del sitio web',
      paragraphOne: 'Contacto',
      paragraphTwo:
        'Recopilamos los datos personales que usted nos revela voluntariamente cuando se pone en contacto con nosotros por correo electrónico o a través de un formulario de contacto en el sitio web para consultas generales o específicas.',
      paragraphThree: {
        text: 'El formulario de contacto puede solicitar la cumplimentación de los siguientes datos personales:',
        bulletPointOne: 'nombre',
        bulletPointTwo: 'dirección de correo electrónico',
        bulletPointThree: 'mensaje de texto',
        bulletPointFour: 'más detalles para consultas o informes específicos, si procede',
      },
      paragraphFour:
        'Además, su dirección IP y la fecha y hora de contacto se almacenarán también automáticamente en nuestros servidores. La recopilación de estos datos es necesaria para comprender el (posible) uso indebido de la dirección de correo electrónico en una fecha posterior y, por lo tanto, nos sirve para demostrar que nuestro proceso es acorde con los requisitos legales.',
      paragraphFive:
        'Su solicitud/consulta será transmitida y gestionada por un empleado de Libeen, que es responsable de la gestión de los temas tratados en su solicitud/consulta.  Sus datos se utilizarán únicamente para gestionar su solicitud o consulta, por ejemplo, para proporcionarle la información de servicio que ha solicitado, y no se utilizarán para ningún otro fin, a menos que la legislación aplicable lo permita o exija expresamente.',
      paragraphSix:
        'Conservamos los datos personales que usted nos proporciona cuando se pone en contacto con nosotros para la duración de la conversación en cuestión, pero no más de 6 meses después de que la conversación haya concluido, a menos que las leyes aplicables requieran períodos de retención más largos en casos individuales. La conversación se considerará cerrada si las circunstancias indican que se han aclarado todos los hechos relevantes o si usted nos indica que eliminemos sus datos. En este caso, todos los datos personales almacenados en el marco de su conversación con nosotros se borrarán inmediatamente.',
    },
    subscription: {
      title: '5. Suscripción al boletín de noticias',
      paragraphOne:
        'Puede suscribirse gratuitamente a nuestra Newsletter. La Newsletter le informa regularmente sobre las ofertas más recientes, códigos de descuento u otras oportunidades. Recibirá estos correos electrónicos de nosotros, Libeen. Su dirección de correo electrónico solo se transmitirá a terceros, si usted ha dado su consentimiento expreso para hacerlo con el fin de recibir boletines de noticias sobre determinados productos o servicios de estos terceros.',
    },
    termsOfConversation: {
      title: '6. Plazo de conservación y derecho de oposición',
      paragraphOne:
        'Conservaremos sus datos personales para los fines indicados siempre que su suscripción a nuestro boletín informativo esté activa. Usted puede cancelar su suscripción al boletín de noticias y retirar su consentimiento al respecto en cualquier momento, ya sea a través del enlace proporcionado al final de cada boletín de noticias o enviando un correo electrónico a:',
      email: 'hello@libeen.com',
      paragraphTwo:
        'Si simplemente desea optar por no participar en el seguimiento de Newsletter, puede configurar su programa de correo electrónico de manera que los correos electrónicos se presenten en formato de texto en lugar de en formato HTML. De esta manera las balizas web no podrán funcionar. También puede configurar su navegador de forma que no se almacenen cookies en su ordenador. Por lo general, las balizas web funcionan solo en combinación con cookies. Sin embargo, en estos casos el boletín no se le mostrará en su totalidad y es posible que no pueda utilizar todas las funciones.',
    },
    cookies: {
      title: '7. Cookies',
      paragraphOneOne:
        'Cuando visite por primera vez este sitio web, le informaremos sobre el uso de cookies y tecnologías similares en este sitio web. Al continuar utilizando este sitio web, usted expresa su consentimiento para dicho uso de cookies. El texto de la declaración de Consentimiento de Cookies puede verse',
      here: 'aqui.',
      paragraphOneTwo: 'También puede cambiar sus preferencias de cookies en cualquier momento con efecto para el futuro.',
      paragraphTwo:
        'Los tipos específicos de cookies instalados a través de este sitio web, su objeto y sus opciones para administrarlos se describen con más detalle en nuestra.',
    },
    socialNetworks: {
      title: '8. Integración de redes sociales y similares',
      paragraphOne:
        'Podemos implementar los llamados plug-ins sociales ("Plugins") de redes sociales y plataformas similares (en lo sucesivo, "Redes Sociales") en ciertas páginas de este sitio web, como los botones "Compartir" o "Me gusta". Utilizamos estos Plugins para darle la posibilidad de compartir o recomendar ofertas interesantes en Redes Sociales y promocionar nuestros servicios. Consideramos estos fines en interés legítimo nuestro en el sentido del art. 6, párr. 1, letra f del RGPD.',
      paragraphTwo:
        'Los Plugins pueden identificarse claramente ya que presentan el logotipo de la red social asociada (por ejemplo, una "f" blanca sobre fondo azul para Facebook).',
      paragraphThree:
        'La integración de los Plugins permite normalmente a las Redes Sociales recopilar datos acerca de usted cuando accede a un sitio web, incluso si no interactúa con los Plugins. En estos casos, no se le da la opción de impedir este intercambio de datos. Para salvaguardar la privacidad de sus datos, hemos incorporado una precaución técnica apropiada. Su navegador solo hará una conexión directa a los servidores de una Red Social, si usted interactúa con el Plugin asociado, como por ejemplo haciendo clic en un botón "Me gusta" o "Compartir". Solo entonces las Redes Sociales recibirán la información de que su navegador ha visitado este Sitio web.  Si interactúa con los Plugins mientras está conectado a la Red Social en cuestión, dicha red podrá correlacionar su perfil en los medios sociales con información sobre su visita a este Sitio web (incluyendo su dirección IP). Esta información se transmite directamente a los servidores de las Redes Sociales. Además, la información que pretendía compartir con la Red Social será publicada y mostrada a sus contactos. Para más información, consulte la política de privacidad de las respectivas Redes Sociales.',
      paragraphFacebook: 'Política de protección de datos de Facebook:',
      facebookLink: 'http://www.facebook.com/policy.php',
      paragraphInstagram: 'Política de protección de datos de Instagram:',
      instagramLink: 'https://help.instagram.com/155833707900388/',
      paragraphGoogle: 'Avisos de privacidad de datos de Google: ',
      googleLink: 'https://www.google.de/intl/de/policies/privacy/',
      paragraphYoutube: 'Avisos de privacidad de datos de YouTube:',
      youtubeLink: 'https://www.google.de/intl/de/policies/privacy/',
      paragraphTwitter: 'Política de protección de datos de Twitter:',
      twitterLink: 'https://twitter.com/de/privacy',
    },
    thirdParty: {
      title: '9. Uso de herramientas de terceros',
      paragraphOne:
        'Permitimos que ciertos terceros utilicen cookies o tecnologías similares en este sitio web. Estos terceros incluyen análisis, redes de publicidad y otros proveedores de servicios que nos ayudan a proporcionar, evaluar y mejorar este Sitio web y nuestros servicios, y a ofrecer publicidad dirigida basada en sus actividades e intereses de navegación.',
      paragraphTwo:
        'Estos terceros pueden utilizar cookies o tecnologías similares y recopilar datos sobre su uso de este Sitio web y su interacción con su contenido y funciones, incluyendo el número total de visitantes al Sitio, los sitios web de los que proceden nuestros visitantes, las páginas que visita, fecha, hora y duración de su visita, frecuencia, banners/enlaces en los que ha hecho clic, así como información sobre el tipo de dispositivo, la versión del navegador, la configuración del idioma y la ubicación geográfica (incluyendo país, ciudad, región). También pueden recopilar identificadores en línea (por ejemplo, ID de usuario) y/o direcciones IP. No tenemos acceso a estas cookies ni a ningún dato personal identificable recopilado con estas cookies que nos permita identificar a usuarios concretos. Sin embargo, es posible que utilicemos información que surja de las cookies proporcionadas por estos terceros. Todos los datos recogidos se ponen a nuestra disposición solo de forma anónima y estadística. No tenemos acceso a ningún dato que nos permita identificarle.',
      paragraphThree:
        'Algunos de los proveedores de servicios tienen su sede en un país no perteneciente a la UE o al EEE. Sin embargo, trabajamos exclusivamente con empresas que ofrecen un nivel de protección de datos adecuado de acuerdo con las estipulaciones del RGPD. Para más información, consulte la sección 10.4.',
    },
    personalData: {
      title: '10. Divulgación de sus datos personales',
      paragraphOne:
        'Solo revelamos sus datos personales a terceros si es necesario con el fin de ofrecer este Sitio web y servicios, estamos obligados o autorizados por contrato o por ley o usted ha concedido su consentimiento explícito.',
      subtitleOne: 'Provedores de servicios',
      paragraphTwo:
        'Nosotros Libeen trabajamos también con proveedores de servicios técnicos, que pueden tener acceso a sus datos personales, pero solo en la medida necesaria para prestar sus respectivos servicios. Por ejemplo, contratamos a proveedores de servicios técnicos para que gestionen nuestro boletín. Estos proveedores de servicios no pueden revelar sus datos personales a terceros ni utilizarlos para ningún otro fin que no sea el indicado.',
      subtitleTwo: 'Transmisión de datos fuera de la UE',
      paragraphThree:
        'Algunos de estos proveedores de servicios tienen su sede en un país no perteneciente a la UE o al EEE. Sin embargo, trabajamos exclusivamente con empresas que ofrecen un nivel de protección de datos adecuado de acuerdo con las estipulaciones del RGPD. También tenemos convenios apropiados para garantizar que los proveedores de servicios adoptarán todas las medidas necesarias para proteger sus datos personales de acuerdo con los requisitos aplicables.',
      paragraphFourOne: 'La transmisión de datos a los proveedores de servicios en los EE. UU. se lleva a cabo sobre la base del llamado',
      link: '"Escudo de la Privacidad UE-EE. UU."',
      paragraphFourTwo:
        'o las llamadas "cláusulas contractuales tipo" de la Comisión Europea. El Escudo de la Privacidad UE-EE. UU. es un acuerdo entre la Unión Europea y los Estados Unidos que garantiza el cumplimiento de las normas europeas de protección de datos en los Estados Unidos. Las cláusulas contractuales tipo de la Comisión Europea se refieren a un contrato en el que el proveedor de servicios está obligado a proteger los datos personales, de conformidad con la normativa contractual, a tratarlos por encargo nuestro y, en particular, a no transmitirlos a terceros. Se le proporcionará una copia gratuita de las cláusulas contractuales tipo si así lo solicita. Para ello, póngase en contacto con nosotros y envíe un sobre franqueado a la dirección indicada en la Sección 12.',
    },
    yourRights: {
      title: '11. Sus derechos',
      paragraphOne: 'En lo que respecta a nuestro tratamiento de sus datos personales, usted tiene derecho a los siguientes derechos gratuitos.',
      subtitleOne: 'Derecho a retirar el consentimiento de conformidad con el art. 7 del RGPD',
      paragraphTwo:
        'Si usted nos ha dado su consentimiento para procesar sus datos pero cambia de opinión posteriormente, tiene derecho a revocar su consentimiento en cualquier momento enviando un correo electrónico o una carta a nuestra dirección de contacto indicada a continuación. Cesaremos el tratamiento de sus datos en consecuencia.',
      subtitleTwo: 'Derecho de acceso conforme al Art. 15 del RGPD',
      paragraphThree:
        'Usted tiene derecho a que le confirmemos si sus datos personales están siendo procesados o no por nosotros y, dado el caso, tendrá derecho a acceder a su información de conformidad con el art. 15 del RGPD.  Sin embargo, con respecto a los perfiles de uso seudonimizados creados por nosotros, no podemos asignarlos a personas concretas. En consecuencia, no podemos proporcionar ninguna información relacionada con estos datos, a menos que usted nos proporcione información que nos permita asignar dichos datos a su persona. Se lo indicaremos en caso de consulta.',
      subtitleThree: 'Derecho de rectificación conforme al Art. 16',
      paragraphFour:
        'Usted tiene derecho a solicitarnos que rectifiquemos cualquier dato personal inexacto o incompleto que tengamos sobre usted.',
      subtitleFour: 'Derecho de supresión conforme al Art. 17 del RGPD',
      paragraphFive:
        'Usted tiene el derecho a la eliminación de sus datos personales, si no tenemos una razón legal para continuar procesándolos y conservándolos.',
      subtitleFive: 'Derecho a restringir el tratamiento conforme al Art. 18 del RGPD',
      paragraphSix:
        'Usted tiene derecho a solicitarnos que restrinjamos la forma en que procesamos sus datos con sujeción al art. 18 del RGPD. En este caso se nos permitirá almacenar los datos, pero no continuar procesándolos.',
      subtitleSix: 'Derecho a la portabilidad de datos conforme al Art. 20 del RGPD',
      paragraphSeven:
        'Usted tiene derecho a recibir los datos personales que nos haya facilitado con su consentimiento en un formato estructurado, de uso común y legible mecánicamente o, si es técnicamente factible, a exigir que los transfiramos a un tercero.',
      subtitleSeven: 'Derecho de oposición al tratamiento CONFORME al Art. 21 del RGPD',
      paragraphEight:
        'En virtud de los requisitos del art. 21 del RGPD, usted tiene derecho a oponerse al tratamiento de sus datos personales en cualquier momento por motivos relacionados con su situación particular (por ejemplo, un posible riesgo para la vida o la salud). El mencionado derecho general de oposición se aplica a todas las finalidades de tratamiento descritas en esta política de privacidad, que se basan en la base legal del interés legítimo. En el caso de que usted haga un uso legítimo de este derecho, dejaremos de procesar sus datos personales, a menos que demostremos que existen motivos legítimos para el tratamiento, que prevalecen sobre sus intereses, derechos y libertades o para el establecimiento, ejercicio o defensa de reclamaciones legales.',
      paragraphNine:
        'En la medida en que sus datos personales se procesen con fines de marketing directo, podrá oponerse a ello en cualquier momento sin necesidad de indicar los motivos. Para oponerse a que nuestros socios publicitarios muestren publicidad personalizada.  Además, puede impedir el almacenamiento de las cookies utilizadas para tales fines ajustando la configuración de las cookies en el software de su navegador.',
      subtitleEight: 'Derecho a presentar una reclamación ante una Autoridad Supervisora',
      paragraphTen:
        'Usted tiene derecho a presentar una reclamación ante la autoridad supervisora competente si considera que el tratamiento de sus datos se lleva a cabo por nuestra parte infringiendo la legislación aplicable. Puede ponerse en contacto con la autoridad supervisora competente en su lugar de residencia o con nuestra autoridad supervisora.',
    },
    dataProtection: {
      title: '12. Contacto y responsable de la Protección de Datos',
      paragraphOneOne:
        'Si tiene alguna pregunta relacionada con esta declaración de privacidad, si desea ejercer alguno de sus derechos o si tiene alguna otra duda con respecto al tratamiento de sus datos personales por nuestra parte, le rogamos que se ponga en contacto con nosotros por correo electrónico a',
      email: 'hello@libeen.com',
      paragraphOneTwo:
        'y nos facilite los detalles de su solicitud, por ejemplo, especificando los datos personales a los que desea acceder. También puede ponerse en contacto con nosotros a través de los siguientes datos de contacto:',
      companyName: 'Libeen Smart Housing, S.L.',
      dataProtection: 'Data protection',
      street: 'calle Piamonte nº 23',
      postalCode: '28004 Madrid ',
      telephone: 'Teléfono: 610413747',
    },
    changes: {
      title: '13. Cambios a esta Política de Privacidad',
      paragraphOne:
        'Podemos cambiar esta política de privacidad en cualquier momento con efecto para el futuro. La versión actual de esta política estará disponible en el Sitio web. Aunque no tenemos intención de hacer cambios a esta política muy a menudo, por favor, consulte siempre nuestra política más actualizada. La fecha de la versión más reciente se encuentra en la parte inferior de esta política.',
    },
  },
  notFoundPage: {
    property: 'Propiedad no disponible',
    propertyDescription: 'Esta vivienda ya ha sido reservada y no sigue disponible.',
    somethingWentWrong: 'Parece que algo salió mal',
    description: 'Este en un error 404. La página a la que intentas acceder ya no está disponible. Porfavor revisa la url o vuelve al incio.',
    backToHome: 'Volver al inicio',
  },
  internalErrorPage: {
    somethingWentWrong: 'Error interno del servidor',
    description: 'La página a la que intentas acceder no se puede mostrar. Intenta volver a cargar la página o ve al inicio.',
    backToHome: 'Volver al inicio',
  },
  cookiesPolicy: {
    title: 'Política de Uso de Cookies',
    policy: {
      title: '1. Primera Capa (en la homepage en forma de pop up):',
      paragraphOne:
        'En esta web utilizamos cookies analíticas, propias y de terceros, que nos informan sobre sus hábitos de navegación para mejorar la calidad de nuestros servicios y su experiencia de navegación. Si está de acuerdo pulse ACEPTAR o siga navegando.',
      paragraphTwo: 'Para más información visite el siguiente link (segunda capa).',
    },
    introduction: {
      title: 'Introducción',
      paragraphOne: 'En esta Web empleamos cookies.',
      paragraphTwo:
        'Las cookies son fragmentos de información que se comparten entre su explorador de Internet y una Web.  El uso de cookies permite al usuario una navegación más rápida y sencilla. La única información personal que contiene una cookie es la que usted facilite. Éstas no pueden leer datos del disco duro de su ordenador.',
    },
    type: {
      title: 'Tipos de cookies',
      paragraphOne: 'Existen dos tipos de cookies dependiendo del tiempo de permanencia:',
      pointOne:
        'Cookies de sesión: solo se almacenan en su ordenador mientras dure su navegación en Internet. Se eliminan automáticamente cuando se cierra explorador. Normalmente almacenan un ID de sesión anónimo que le permite explorar una Web sin necesidad de iniciar sesión en cada página. No recopilan ninguna información de su ordenador.',
      pointTwo:
        'Cookies persistentes: se almacenan como un archivo en su ordenador y permanecen allí cuando cierra su explorador de Internet. Esta cookie puede ser leída por la Web que la creó cuando el usuario vuelve a visitar esta Web. Se utilizan para almacenar información entre las diferentes visitas a un sitio web y, en consecuencia, no se borran al finalizar la sesión de navegación (de ahí su nombre).',
      paragraphTwo: 'Para mayor información sobre todas estas categorías de cookies y sobre cookies en general, consulte la web:',
    },
    objectives: {
      title: 'Finalidades',
      paragraphOne: 'En esta web utilizamos cookies con las siguientes finalidades:',
      pointOne: {
        heading: 'Accesibilidad',
        paragraph:
          'Para permitir al usuario desplazarse por la Web y utilizar funciones, como acceder a las áreas seguras. Sin las cookies de accesibilidad, los servicios que ha solicitado no serían posibles.',
      },
      pointTwo: {
        heading: 'Rendimiento',
        paragraph:
          'Mediante las cookies de rendimiento recogemos información sobre cómo los usuarios utilizan la Web, los servicios que contratan con mayor frecuencia y si reciben mensajes de error. También nos permiten registrar y contabilizar el número de visitantes a la Web, pudiendo así comprobar cómo se está utilizando y mejorar su funcionamiento. Las cookies utilizadas con tales fines no recogen información que identifique al usuario, ya que dicha información es anónima y sólo se utiliza para mejorar el funcionamiento de la Web.',
      },
      pointThree: {
        heading: 'Funcionalidad',
        paragraph:
          'Las cookies de funcionalidad nos permiten recordar las elecciones que realiza el usuario (nombre de usuario, idioma o la región en la que se encuentra) y proporcionar funciones mejoradas. Por ejemplo, es posible que la Web recuerde sus datos de inicio de sesión para que no tenga que iniciar sesión en su cuenta siempre que utilice el mismo dispositivo para acceder a nuestra Web.',
      },
      paragraphTwo:
        'La información que recogen estas cookies se guarda normalmente de forma anónima. No recopilan ninguna información sobre el usuario que pueda utilizarse con fines publicitarios ni recuerdan qué lugares de Internet ha visitado.',
    },
    installed: {
      title: 'Cookies que están instaladas en nuestra Web',
      pointOne:
        '_ga: Cookie de Google Analytics. Se usa para distinguir a los usuarios únicos y tiene una duración de 2 años desde el inicio de sesión.',
      pointTwo:
        '_gat: Cookie de Google Analytics. Se usa para limitar el porcentaje de solicitudes y tiene una duración de 10 minutos desde el inicio de sesión.',
    },
    delete: {
      title: 'Desactivar y bloquear las cookies',
      paragraphOne:
        'Le informamos de que dado que las cookies no son necesarias para el uso de nuestra Web, puede bloquearlas activando la configuración de su navegador que le permite rechazar la instalación de todas las cookies o de algunas de ellas.',
      paragraphTwo:
        'La mayoría de los navegadores permiten advertir de la presencia de cookies o rechazarlas automáticamente. Si las rechaza podrá seguir usando nuestra Web, aunque el uso de algunos de sus servicios podrá ser limitado y por tanto su experiencia de navegación podría resultar menos satisfactoria.',
      paragraphThree:
        'Si desea eliminar las cookies almacenadas en su equipo (ordenador o dispositivo móvil), puede hacerlo a través de los ajustes y configuraciones de su navegador de Internet.',
      paragraphFour:
        'Salvo que haya ajustado la configuración de su navegador, nuestro sistema instalará cookies en cuanto visite nuestro Sitio Web. Todos los navegadores de Internet permiten el cambio de dicha configuración.',
      paragraphFive:
        'Para más información sobre la forma de eliminar o ajustar sus configuraciones de cookies en los distintos navegadores, le remitimos al enlace siguiente:',
    },
    consent: {
      title: 'Consentimiento',
      paragraph:
        'Al navegar y continuar en nuestro Sitio Web estará consintiendo el uso de las cookies antes enunciadas, por los plazos señalados y en las condiciones contenidas en la presente Política de uso de Cookies.',
    },
    security: {
      title: 'Seguridad',
      paragraph:
        'Le informamos de que hemos adoptado las medidas técnicas y organizativas necesarias para garantizar la seguridad de los datos personales que recopilamos, usamos y mantenemos, y para prevenir su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta la situación técnica, la naturaleza de los datos almacenados y los riesgos a los que se exponen en virtud de la acción humana o del entorno natural o físico. No obstante, le pedimos que colabore manteniendo en secreto las contraseñas del ordenador que utilice para acceder a Internet o a esta Web. Le recordamos que nos debe informar de inmediato de cualquier vulnerabilidad que pueda encontrar en nuestra Web o en cualquier aplicación disponible en ésta.',
    },
    contact: {
      title: 'Información de contacto',
      paragraph:
        'Cualquier pregunta sobre esta política de uso de cookies nos la puede hacer llegar por escrito a la siguiente dirección de email:',
    },
  },
  aboutUs: {
    title: ' Sobre Nosotros',
    subtitle: 'Te ayudamos a comprarte una casa, a escribir tu propia historia',
    weBelieve:
      'Creemos que todo el mundo debería poder acceder a comprarse una casa sin necesidad de sacrificar su propia economía, ni de tener que renunciar a lo que más le gusta o cambiar de vida.',
    joseAndSofiaOne:
      'Tras un tiempo viviendo juntos y cansados de desperdiciar una cantidad considerable de dinero en un alquiler tradicional, empezamos a pensar y mirar opciones para comprar. Nos encontramos, posiblemente, con el mismo problema que tú. Aun teniendo ingresos decentes, nos era imposible comprar un piso en Madrid. Viendo esta situación, y con todo nuestro espíritu emprendedor, decidimos buscar una solución a este problema de acceso a la vivienda que sufre toda nuestra generación.',
    joseAndSofiaTwo:
      'Cartes (como le llaman sus amigos) llevaba más de 8 años trabajando en el sector inmobiliario y con previa experiencia en startups digitales, y por otro lado Sofía viene del mundo del Hospitality Management y del Marketing. Empezamos a darle vueltas a cómo reinventar el acceso a la vivienda. Nos preguntábamos una y otra vez: ¿Cómo podemos comprarnos una casa de una forma fácil y flexible?',
    joseAndSofiaThree:
      'Empezamos a poner sobre papel todos nuestros sueños e ideas y todo empezó a cobrar sentido. De nuestros primeros leads pasamos a nuestros primeros clientes y desde aquel momento, supimos que no habíamos hecho más que empezar. Fue el comienzo de nuestra comunidad de #SmartHousers. Tenemos tantas ideas, tantos sueños y tantas ganas de ayudar a nuestra generación, que seguimos trabajando con la misma ilusión que tenemos desde el primer día.',
    joseAndSofiaFour: 'Y en esas seguimos peleando duro cada día para ayudarte a construir el futuro que sueñas.',
    joseAndSofiaSignature: 'Sofia & Cartes.',
    libeenTeam: 'Libeen Team',
    founderCeo: 'Founder & CEO - Board Member',
    cto: 'Chief Technical Officer',
    cio: 'Chief Investment Officer',
    bdm: 'Business Development Manager',
    sm: 'Sales Manager',
    chiefSalesOfficer: 'Cofounder & COO',
    productAdvisor: 'Chief Product Officer',
    boardOfDirectors: 'Board & Advisors',
    founderInvestorboardOfDirectors: 'Founder Investor & Board of Directors',
    logoTextOne:
      'Premio a la Fintech Nº1 de España en los Premios Expansión StartUp 2020. Somos parte de la nueva generación de empresas de Lanzadera en la Marina de Empresas. Aceleradora del gran empresario valenciano Juan Roig; conocido por levantar el imperio de supermercados Mercadona.',
    logoTextTwo:
      'Seleccionados entre las TOP 5 StartUps seleccionadas de Europa para el programa de aceleración de Finance Forward Europe 2020, dirigido por Village Capital, The MetLife Foundation y Paypal.',
    logoTextThree:
      'También en junio de 2020, con menos de un añito de vida, quedamos semifinalistas a nivel europeo en el concurso de The StartUp & ScaleUp Proptech Award Competition, que fue promovido por la Comisión Europea.',
  },
  faq: {
    title: 'Preguntas Frecuentes',
    imTenant: 'Soy inquilino',
    imOwner: 'Soy propietario',
    about: {
      title: 'Sobre Libeen',
      questionOne: '¿Puedes aplicar al Smart Housing?',
      answerOne: {
        answerOne:
          'Si estás buscando comprarte una casa, o simplemente quieres dejar de gastar en el alquiler todos los meses y no quieres preocuparte por la entrada o por tus ahorros, contacta con nosotros y buscaremos juntos la casa que más te encaje. ¡Así de fácil!',
      },
      questionTwo: '¿Qué es el Smart Housing?',
      answerTwo: {
        answerOne: 'El #SmartHousing significa dejar de gastar dinero en un simple alquiler y empezar a usarlo para comprar TU casa con Libeen.',
      },
      questionThree: '¿Por qué queremos ayudarte?',
      answerThree: {
        answerOne: 'Porque simplemente queremos ayudarte a crear tu historia, a conseguir la casa de tus sueños.',
        answerTwo:
          'Las grandes exigencias financieras para poder acceder a una hipoteca y la inestabilidad laboral nos expulsan a la gran mayoría del sueño de comprarnos nuestra propia casa. Como consecuencia es algo que sólo muy pocos se pueden permitir. Al no poder comprar nos toca alquilar, lo que provoca que el precio de los alquileres se haya disparado y los trámites se hayan vuelto más complejos.',
        answerThree:
          'Es por eso por lo que Libeen existe, para darte la facilidad de acceder a tu propio hogar sin la necesidad de una entrada ni de tener los ahorros necesarios que demandan los bancos. Y así, poder dejar de gastar todos los meses en un alquiler sin sentido.',
      },
      questionFour: '¿Quiénes somos?',
      answerFour: {
        answerOne: 'Libeen nace con una sola misión: ayudar a los jóvenes a comprarse una casa, a escribir su propia historia.',
        answerTwo:
          'Creemos que todo el mundo debería poder acceder a comprarse una casa sin necesidad de sacrificar su propia economía, ni de tener que renunciar a lo que más le gusta o cambiar de vida.',
        answerThree:
          'Con todo esto dicho queremos que sepas que no somos ni prestamistas, ni un banco, ni nada que se le parezca. Simplemente somos como ¨tu abuela¨ (esa que te echa una mano cuando lo necesitas y te apoya…).',
      },
    },
    howWorks: {
      title: '¿Cómo funciona Libeen?',
      questionOne: '¿Cuándo se ejecuta la compra y me convierto en el propietario de mi hogar?',
      answerOne: {
        answerOne:
          'En un período de tiempo de entre 3 o 5 años (depende de la vivienda), tendrás ahorrado el 20% del valor de la casa, se ejecuta compra de la vivienda y ya eres propietario de la casa de tus sueños. ¡Y sin darte cuenta!',
      },
      questionTwo: '¿Qué documentos necesitamos de ti?',
      answerTwo: {
        answerOne:
          'Por un lado, necesitaremos tu documento de identificación (DNI, NIE o Pasaporte). Además, vamos a necesitar que nos facilites información sobre tu situación laboral:',
        answerTwo: '1. Si eres empleado por cuenta ajena necesitaremos que nos facilites las 2 últimas nóminas.',
        answerThree:
          '2. Si eres autónomo necesitaremos tu última declaración del impuesto sobre la renta (IRPF) y los 2 últimos recibos de la Seguridad Social o pago fraccionado del IVA.',
        answerFour: '3. Si eres pensionista necesitaremos el certificado de ingresos actualizado.',
        answerFive: '4. Para cualquier tipo de contrato temporal, vamos a necesitar que nos facilites tu Vida Laboral.',
      },
      questionThree: '¿Qué requisitos necesito cumplir para unirme a la revolución del Smart Housing?',
      answerThree: {
        answerOne: '1. Que el alquiler no supere el 45% de tus o vuestros ingresos netos.',
        answerTwo:
          '2. Que tengas un contrato laboral fijo superado el periodo de prueba o con una antigüedad superior a los 6 meses. También admitimos cualquier otro tipo de contrato laboral con una antigüedad superior a 1 año. En caso de que lleves menos de 1 año de contrato, te pediremos la vida laboral.',
        answerThree:
          '3. No puede haber referencia en el fichero ASNEF de morosidad. Por supuesto no tendremos en cuenta pequeñas deudas de telefonía de hasta 500€ o de otro tipo hasta 300€, pero sí que tendremos en cuenta la reincidencia.',
      },
      questionFour: '¿Puedo reformar o modificar la vivienda?',
      answerFour: {
        answerOne:
          'Puedes ajustarla siempre a tus necesidades, al fin y al cabo, queremos que estés a gusto en tu propio hogar. Pero, de todas formas, si vas a hacer alguna reforma o vas a modificar la vivienda, siempre necesitamos que nos lo comentes antes de comenzar.',
      },
      questionFive: '¿Quién es el responsable del mantenimiento de la vivienda?',
      answerFive: {
        answerOne: 'Todas las viviendas tienen Seguro de Hogar para cualquier problema que pueda surgir.',
      },
      questionSix: '¿Están incluidos los impuestos y gastos de compraventa de la vivienda?',
      answerSix: {
        answerOne: 'Tanto los impuestos por compra de inmueble, como el cambio de titularidad a tu favor lo tienes que cubrir personalmente.',
      },
      questionSeven: '¿Hay algún coste extra de Libeen en la compraventa?',
      answerSeven: {
        answerOne:
          'Nosotros no te vamos a cobrar nada de nada en la compraventa. Como mencionamos, nuestra misión es que puedas comprarte la casa de tus sueños.',
      },
      questionEight: '¿Libeen me ayudará a conseguir la hipoteca?',
      answerEight: {
        answerOne:
          'Siempre vamos a hacer todo lo posible por ayudarte a conseguir las mejores condiciones de hipoteca. Actualmente contamos con el gran apoyo de varias entidades españolas.',
      },
      questionNine: '¿Puedo subarrendar mi vivienda?',
      answerNine: {
        answerOne: 'Si, pero siempre con nuestra previa autorización del subinquilino.',
      },
      questionTen: 'En 3 sencillos pasos te explicamos cómo funciona el Smart Housing:',
      answerTen: {
        answerOne:
          '1. Eliges la vivienda que más te guste y más se ajuste a tus necesidades dentro de nuestro marketplace. El precio que ves se quedará fijado, no se irá aumentando.',
        answerTwo:
          '2. Te mudas a tu casa tras firmar el contrato de #SmartHousing y aportar una entrada mínima del 5% del valor total del inmueble. Mes a mes irás comprando tu casa con el alquiler.',
        answerThree:
          '3. Llegados al último año pasas a ser el propietario, es decir, compras tu casa. En este momento tendrás ahorrado el 20% para que te concedan la hipoteca. ¡Y sin darte cuenta! Nosotros además te apoyaremos en la búsqueda de la mejor hipoteca.',
      },
    },
    landlords: {
      titile: 'Propiedades Libeen',
      questionOne: '¿Dónde puedo encontrar una vivienda Libeen?',
      answerOne: {
        answerOne: 'Nuestras ubicaciones son en zonas de alta demanda, de esta manera siempre garantizamos la buena inversión para todos.',
        answerTwo:
          'Estamos creciendo y esperamos dentro de poco poder desvelarte las nuevas ubicaciones a nivel nacional e internacional. Por ahora estamos en Madrid y Valencia.',
      },
      questionTwo: '¿Son todas las viviendas amuebladas o reformadas?',
      answerTwo: {
        answerOne:
          'No. No todas las viviendas son amuebladas o recién reformadas. Entendemos que hay gente que prefiere amueblar o reformar su piso con su propio estilo y otros que quieren entrar a una casa ya con muebles y en buen estado. Es por esto, que tenemos de todo en nuestro marketplace. En algunas ocasiones sacamos viviendas reformadas y amuebladas por nuestro equipo interno de Libeen, ¡pero estas viviendas vuelan! Por lo que si te interesa, te recomendamos que se lo comentes lo antes posible a tu persona de confianza de Libeen para que te apunte en la lista de espera y te diga la oferta que puede haber acorde con tus tiempos y necesidades. Puedes enviar un correo a: hello@libeen.com',
      },
    },
    owners: {
      questionOne: '¿Qué es el Smart Housing?',
      answerOne: {
        answerOne:
          'El Smart Housing es la nueva forma para vender y comprar viviendas de una manera sencilla, digital, transparente y adaptada a las nuevas generaciones.',
      },
      questionTwo: '¿Cómo vender mi casa con el SmartHousing?',
      answerTwo: {
        answerOne:
          'Para empezar a vender tu casa valoramos tu vivienda estableciendo contigo una entrada, un alquiler y un precio final. Una vez hecho esto, subimos el anuncio a nuestro Marketplace para que nuestros más de 17.000 clientes la puedan ver.',

        answerTwo:
          'Según vayan saliendo visitas, nosotros nos encargamos de hablar contigo y gestionarlas para que tu no te tengas que preocupar por nada.',
        answerThree:
          'Cuando haya un interesado en la compra, nosotros realizamos un estudio financiero y de solvencia para asegurarnos de que pueda afrontar los pagos.',
        answerFour: 'Finalmente, nosotros te acompañaremos a lo largo del contrato de Smart Housing hasta que se ejecute la compra.',
      },
      questionThree: '¿Cómo es la venta de mi casa?',
      answerThree: {
        answerOne:
          'El potencial comprador, aportará la entrada que hayamos acordado antes de empezar a vivir en la casa. Una vez ahí, pagará cuotas mensuales durante el periodo del contrato (3 o 5 años) hasta ejecutar la compra.',
      },
      questionFour: '¿Qué gana Libeen?',
      answerFour: {
        answerOne:
          'Nosotros solo ganamos, si tú ganas. Nuestra comisión es un 2% del valor total del inmueble en el momento de la firma del contrato de Smart Housing.',
      },
      questionFive: '¿Cuánto tiempo tardo en vender mi casa?',
      answerFive: {
        answerOne: 'Normalmente el tiempo medio de venta es de aproximadamente 30 días. Si no te lo crees, compruébalo.',
      },
      questionSix: '¿Cuáles son los beneficios de vender mi casa con Libeen?',
      answerSix: {
        answerOne:
          'En primer lugar, respetamos tu precio. Sabemos que las negociaciones del mercado son agresivas y más hoy en día. Es más, según el I.N.E. las operaciones de venta directa tradicional suelen cerrarse hasta un 15% menos del precio inicial establecido por el propietario.',
        answerTwo:
          'Además, nosotros no tenemos exclusividad, no interferimos en la venta que tu puedas gestionar por tu cuenta. Es simplemente una vía más para ampliar el abanico de posibles compradores.',
        answerThree: 'Gestionamos tanto las visitas como todo el papeleo a la hora de ejecutar el contrato de Smart Housing.',
        answerFour:
          'Nosotros nos aseguramos de que el potencial comprador sea una persona con capacidad de pago. Hacemos esto realizando un estudio financiero y de solvencia previo a la firma del contrato.',
      },
    },
  },
}
