import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { DownloadPurchaseDocumentation } from '../domain/DownloadPurchaseDocumentation'

export class DownloadPurchaseDocumentationHttp implements DownloadPurchaseDocumentation {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  async execute(purchaseSlug: string, documentSlug: string): Promise<void> {
    const blob = await this.httpClient.getFile(
      `${getPublicRuntimeConfig().API_ENDPOINT}/admin/dealflows/${purchaseSlug}/files/${documentSlug}`,
      {
        headers: {},
        params: {},
      }
    )
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob)
    } else {
      const objectUrl = URL.createObjectURL(blob)
      window.open(objectUrl) // TODO: This should be extracted into a service or something
    }
  }
}
