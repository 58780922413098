import { NeighborhoodTag } from '../../model/NeighborhoodTag'
import { MarketplacePropertyDetails } from '../../model/PropertyDetails'
import { PropertyFeature } from '../../model/PropertyFeature'
import { PropertyStatus } from '../../model/PropertyStatus'
import { PropertyTag } from '../../model/PropertyTag'
import { Vendor } from '../../model/Vendor'
import { getPublicRuntimeConfig } from '../../services/config/publicRuntimeConfig'
import { HttpClient } from '../../utils/http-client/HttpClient'
import { GetPropertyDetails } from '../domain/GetPropertyDetails'
import { PropertyShowResponseDto } from './swagger/schema'

type PropertyDetailsResponse = PropertyShowResponseDto

export class GetPropertyDetailsHttp implements GetPropertyDetails {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  private mapResponseToModel(response: PropertyDetailsResponse): MarketplacePropertyDetails {
    return {
      id: response.id,
      slug: response.slug,
      title: response.title,
      status: response.status as PropertyStatus, // TODO: Check that backend/frontend enums matches
      address: {
        city: response.address.city,
        neighborhood: response.address.neighborhood,
      },
      neighborhood: {
        uuid: response.neighborhood.uuid,
        name: response.neighborhood.name,
        description: response.neighborhood.description,
        image: response.neighborhood.image,
        tags: response.neighborhood.tags as NeighborhoodTag[], // TODO: Check that backend/frontend enums matches
      },
      images: response.images,
      pricePerMonth: response.pricePerMonth,
      monthlySavings: response.monthlySavings,
      downPayment: response.entryPayment,
      entryPayment: response.entryPayment,
      isFavorite: response.isFavourite,
      surface: response.surface,
      bedroomsAmount: response.bedroomsAmount,
      bathroomsAmount: response.bathroomsAmount,
      tags: response.tags as PropertyTag[], // TODO: Check that backend/frontend enums matches
      features: response.features as PropertyFeature[], // TODO: Check that backend/frontend enums matches
      description: response.description,
      location: {
        latitude: response.location.latitude,
        longitude: response.location.longitude,
      },
      savings: response.savings,
      totalBuyCost: response.priceToPay,
      vendor: response.vendor as Vendor, // TODO: Check that backend/frontend enums matches
      tryBeforeYouBuyIt: response.tryBeforeYouBuyIt,
      wholeRentalForBuying: response.wholeRentalForBuying,
      yearPlan: response.yearPlan,
      specialOffer: response.specialOffer,
      latitude: 0,
      longitude: 0,
    }
  }

  async execute(propertyId: string): Promise<MarketplacePropertyDetails> {
    const response = await this.httpClient.get<PropertyDetailsResponse>(`${getPublicRuntimeConfig().API_ENDPOINT}/properties/${propertyId}`)

    return this.mapResponseToModel(response)
  }
}
