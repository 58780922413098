import querystring from 'querystring'
import { RepositoryError } from '../../repositories/domain/RepositoryError'
import { log } from '../log'
import { HttpClient, Options } from './HttpClient'

const logger = log('HTTP_CLIENT')

export type FormDataObject = {
  name: string
  value: string | File
}

export class HttpClientNode implements HttpClient {
  private readonly jwt?: string

  constructor(jwt?: string) {
    this.jwt = jwt
  }

  get<T = unknown>(url: string, options?: Options): Promise<T> {
    const queryParams = options ? `?${querystring.stringify(options.params)}` : ''

    // TODO: Check transformers to allow the ?. operator
    const headers = options && options.headers ? options.headers : {}

    return fetch(`${url}${queryParams}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Cookie: `ACCESS_TOKEN=${this.jwt};STAGING_ACCESS_TOKEN=${this.jwt}`,
        ...headers,
      },
    }).then(async (response) => {
      if (response.status >= 400) {
        return response.json().then((data) => {
          if (process.env.NODE_ENV !== 'production') {
            logger('ERROR')
            logger(`url: ${url}${queryParams}`)
            logger(`error response: ${JSON.stringify(data, null, 2)}`)
          }
          return Promise.reject(new Error(data.message))
        })
      }
      const txt = await response.text()
      if (txt) {
        return JSON.parse(txt)
      }
      return txt
    })
  }

  getFile(url: string, options?: Options): Promise<Blob> {
    const queryParams = options ? `?${querystring.stringify(options.params)}` : ''

    // TODO: Check transformers to allow the ?. operator
    const headers = options && options.headers ? options.headers : {}

    return fetch(`${url}${queryParams}`, {
      method: 'GET',
      headers,
      credentials: 'include',
    }).then((response) => {
      if (response.status >= 400) {
        return response.json().then((data) => {
          if (process.env.NODE_ENV !== 'production') {
            logger('ERROR')
            logger(`url: ${url}${queryParams}`)
            logger(`error response: ${JSON.stringify(data, null, 2)}`)
          }
          return Promise.reject(new Error(data.mesasge))
        })
      }

      return response.blob() as Promise<Blob>
    })
  }

  post<T = unknown>(url: string, body: any, options?: Options): Promise<T> {
    const queryParams = options ? `?${querystring.stringify(options.params)}` : ''

    // TODO: Check transformers to allow the ?. operator
    const headers = options && options.headers ? options.headers : undefined

    return fetch(`${url}${queryParams}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        ...headers,
      },
      credentials: 'include',
      body: JSON.stringify(body),
    }).then(async (response) => {
      if (response.status >= 400) {
        return response.json().then((data) => {
          return Promise.reject(new RepositoryError(data.code, data.message, data.sources))
        })
      }
      const txt = await response.text()
      if (txt) {
        return JSON.parse(txt)
      }
      return txt
    })
  }

  postFiles<T = unknown>(url: string, formDataObjects: FormDataObject[]): Promise<T> {
    const formData = new FormData()
    formDataObjects.forEach((formDataObject) => formData.append(formDataObject.name, formDataObject.value))

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: formData as any,
    }).then((response) => {
      if (response.status >= 400) {
        return response.json().then((data) => {
          return Promise.reject(new RepositoryError(data.code, data.message, data.sources))
        })
      }
      return response.json()
    })
  }

  put<T = unknown>(url: string, body: any, options?: Options): Promise<T> {
    const queryParams = options ? `?${querystring.stringify(options.params)}` : ''

    // TODO: Check transformers to allow the ?. operator
    const headers = options && options.headers ? options.headers : undefined
    const contentType =
      options && Object.keys(options.headers).includes('content-type')
        ? String(options.headers['content-type' as keyof HeadersInit])
        : 'application/json'

    return fetch(`${url}${queryParams}`, {
      method: 'PUT',
      headers: {
        'content-type': contentType,
        ...headers,
      },
      credentials: 'include',
      body: JSON.stringify(body),
    }).then(async (response) => {
      if (response.status >= 400) {
        return response.json().then((data) => {
          return Promise.reject(new RepositoryError(data.code, data.message, data.sources))
        })
      }
      const txt = await response.text()
      if (txt) {
        return JSON.parse(txt)
      }
      return txt
    })
  }

  patch<T = unknown>(url: string, body: any, options?: Options): Promise<T> {
    const queryParams = options ? `?${querystring.stringify(options.params)}` : ''

    // TODO: Check transformers to allow the ?. operator
    const headers = options && options.headers ? options.headers : undefined
    const contentType =
      options && Object.keys(options.headers).includes('content-type')
        ? String(options.headers['content-type' as keyof HeadersInit])
        : 'application/json'

    return fetch(`${url}${queryParams}`, {
      method: 'PATCH',
      headers: {
        'content-type': contentType,
        ...headers,
      },
      credentials: 'include',
      body: JSON.stringify(body),
    }).then(async (response) => {
      if (response.status >= 400) {
        return response.json().then((data) => {
          return Promise.reject(new RepositoryError(data.code, data.message, data.sources))
        })
      }
      const txt = await response.text()
      if (txt) {
        return JSON.parse(txt)
      }
      return txt
    })
  }

  putFiles<T = unknown>(url: string, formDataObjects: FormDataObject[]): Promise<T> {
    const formData = new FormData()
    formDataObjects.forEach((formDataObject) => formData.append(formDataObject.name, formDataObject.value))

    return fetch(url, {
      method: 'PUT',
      credentials: 'include',
      body: formData as any,
    }).then((response) => {
      if (response.status >= 400) {
        return response.json().then((data) => {
          return Promise.reject(new RepositoryError(data.code, data.message, data.sources))
        })
      }
      return response.json()
    })
  }

  delete<T = unknown>(url: string, options?: Options): Promise<T> {
    const queryParams = options ? `?${querystring.stringify(options.params)}` : ''

    // TODO: Check transformers to allow the ?. operator
    const headers = options && options.headers ? options.headers : undefined

    return fetch(`${url}${queryParams}`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        ...headers,
      },
      credentials: 'include',
    }).then(async (response) => {
      if (response.status >= 400) {
        return response.json().then((data) => {
          return Promise.reject(new RepositoryError(data.code, data.message))
        })
      }
      const txt = await response.text()
      if (txt) {
        return JSON.parse(txt)
      }
      return txt
    })
  }
}
