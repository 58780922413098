import { CreatePurchase } from '../domain/CreatePurchase'

export class CreatePurchaseFake implements CreatePurchase {
  private readonly purchaseSlug: string

  constructor(purchaseSlug = 'new-purchase-slug') {
    this.purchaseSlug = purchaseSlug
  }

  async execute(): Promise<{ purchaseSlug: string }> {
    return Promise.resolve({ purchaseSlug: this.purchaseSlug })
  }
}
